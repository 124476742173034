import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(24),
  },

  subtitle: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(18),
  },

  productInfo: {
    marginTop: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
  },

  wrapperInfo: {
    display: 'grid',
    gridTemplateColumns: '80px 1fr',
    columnGap: theme.spacing(3),
  },

  image: {
    borderRadius: theme.typography.pxToRem(4),
    objectFit: 'cover',
  },

  grayBox: {
    fontSize: theme.typography.pxToRem(15),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.darkGrey[2],
    borderRadius: theme.typography.pxToRem(4),
    color: theme.palette.darkGrey.main,
  },

  bold: {
    '&>strong': {
      fontWeight: 500,
    },
  },

  descriptionModifiers: {
    color: theme.palette.darkGrey.main,
    fontSize: theme.typography.pxToRem(14),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  errorDuplicateSku: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(12),
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;
