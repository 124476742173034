import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';
import { pageSizeTypes } from 'utils/pagination';
import { cleanFilter } from 'utils/invoicing/filters';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getGlobalInvoicesRequest(
  { invoicingCode, invoicesUuids, startDate, endDate, invoicingConfig, brands, page, action, source },
  options = {},
) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const params = {
    store_uuid: storeUuid,
    invoicing_code: invoicingCode,
    invoices_uuids: invoicesUuids,
    start_date: startDate,
    end_date: endDate,
    invoicing_config: invoicingConfig,
    brand: brands,
    page_size: pageSizeTypes.PAGE_SIZE_DEFAULT,
    page,
    action,
    source,
  };
  const query = queryString.stringify(cleanFilter(params));
  const config = {};
  if (action && action === 'download') {
    config.responseType = 'blob';
  }

  return request(`${API_BASE_URL}/v1/invoicing/global-invoices/?${query}`, {
    method: 'GET',
    config,
    ...options,
  });
}

export function getCustomerInvoicesRequest(
  { invoicingCode, invoicesUuids, startDate, endDate, brands, page, status, action, origin },
  options = {},
) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const params = {
    store_uuid: storeUuid,
    invoicing_code: invoicingCode,
    invoices_uuids: invoicesUuids,
    start_date: startDate,
    end_date: endDate,
    brand: brands,
    page_size: pageSizeTypes.PAGE_SIZE_DEFAULT,
    page,
    status,
    action,
    invoice_types: origin,
  };
  const query = queryString.stringify(cleanFilter(params));
  const config = {};
  if (action && action === 'download') {
    config.responseType = 'blob';
  }

  return request(`${API_BASE_URL}/v1/invoicing/customer-invoices/?${query}`, {
    method: 'GET',
    config,
    ...options,
  });
}

export function cancelInvoicingGeneratedRequest({ invoicingUuid, reason, reasonType }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/invoicing/generated-invoices/${invoicingUuid}/?${query}`, {
    method: 'DELETE',
    body: JSON.stringify({
      reason,
      reasonType,
    }),
    ...options,
  });
}

export function downloadInvoicesRequest(invoicesUuids, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/invoicing/generated-invoices/download/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      invoices_uuids: invoicesUuids,
    }),
    config: {
      responseType: 'blob',
    },
    ...options,
  });
}
