import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { getUser, getLicenseState, clearLicenseStateChannel } from './actions';

function useAuth() {
  const dispatch = useDispatch();

  const handleGetUser = useCallback(() => {
    dispatch(getUser());
  }, [dispatch]);

  const handleResetUser = useCallback(() => {
    dispatch(getUser.reset());
  }, [dispatch]);

  const handleLoadLicenseState = useCallback(() => {
    dispatch(getLicenseState());
  }, [dispatch]);

  const handleResetLicenseState = useCallback(() => {
    dispatch(getLicenseState.reset());
  }, [dispatch]);

  const handleClearLicenseStateChannel = useCallback(() => {
    dispatch(clearLicenseStateChannel());
  }, [dispatch]);

  return {
    loadGetUser: handleGetUser,
    resetGetUser: handleResetUser,
    loadLicenseState: handleLoadLicenseState,
    resetLicenseState: handleResetLicenseState,
    clearLicenseStateChannel: handleClearLicenseStateChannel,
  };
}

export default useAuth;
