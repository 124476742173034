/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import MenuMui from '@material-ui/core/Menu';
import MenuItemMui from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import useIconParrot from 'hooks/useIconParrot';
import useUserStore from 'hooks/useUserStore';
import useMenuMakerDrawerState from 'hooks/useMenuMakerDrawerState';

import { getCloseDrawerFunction } from 'components/DrawerMenuMaker/MainView';

const Menu = withStyles((theme) => ({
  paper: {
    background: theme.palette.background.default,
    boxShadow: `0px 8px 40px  ${theme.palette.darkGrey[5]}`,
    borderRadius: '4px',
    padding: theme.spacing(1),
  },
}))((props) => (
  <MenuMui
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    elevation={0}
    getContentAnchorEl={null}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const MenuItem = withStyles((theme) => ({
  root: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}))(MenuItemMui);

function ActionsMenuMaker({
  isLoadingActionDelete = false,
  open,
  onDuplicate,
  onCopy,
  onDelete,
  onClose,
  onPreview,
  ...others
}) {
  const actionRef = useRef();
  const { t } = useTranslation();
  const {
    storeState: { selected: storeSelected },
  } = useUserStore();
  const { IconDeleteRed, IconDuplicateOutline, IconCopyOutline, IconPreviewOutline } = useIconParrot({
    icons: ['deleteRed', 'duplicateOutline', 'copyOutline', 'previewOutline'],
  });
  const { keepOpen, setKeepOpen } = useMenuMakerDrawerState();

  useEffect(() => {
    if (!keepOpen && actionRef.current) {
      actionRef.current();
    }

    setKeepOpen(undefined);
    actionRef.current = null;
  }, [keepOpen]);

  const handleAction = (action) => {
    setKeepOpen(undefined);
    const closeMenuMakerDrawer = getCloseDrawerFunction();

    if (closeMenuMakerDrawer) {
      actionRef.current = action;
      closeMenuMakerDrawer();
    } else {
      action();
    }
  };

  return (
    <Menu data-testid="actionsMenuMaker" keepMounted onClose={onClose} open={open} {...others}>
      {onDuplicate && (
        <MenuItem data-testid="duplicateItemButton" onClick={() => handleAction(onDuplicate)}>
          <ListItemIcon>
            <IconDuplicateOutline />
          </ListItemIcon>
          <ListItemText primary={t('common:actions.duplicate')} />
        </MenuItem>
      )}

      {storeSelected?.isDraft && onCopy && (
        <MenuItem data-testid="copyItemButton" onClick={() => handleAction(onCopy)}>
          <ListItemIcon>
            <IconCopyOutline />
          </ListItemIcon>
          <ListItemText primary={t('common:actions.copyAnotherBrand')} />
        </MenuItem>
      )}

      {onPreview && (
        <MenuItem data-testid="previewItemButton" onClick={() => handleAction(onPreview)}>
          <ListItemIcon>
            <IconPreviewOutline />
          </ListItemIcon>
          <ListItemText primary={t('common:actions.preview')} />
        </MenuItem>
      )}

      {onDelete && (
        <MenuItem data-testid="deleteItemButton" onClick={() => handleAction(onDelete)}>
          <ListItemIcon>
            {isLoadingActionDelete ? (
              <CircularProgress color="inherit" size={24} style={{ color: '#DF130E' }} />
            ) : (
              <IconDeleteRed />
            )}
          </ListItemIcon>
          <ListItemText>
            <Box color="error.main" component="span">
              {t('common:actions.delete')}
            </Box>
          </ListItemText>
        </MenuItem>
      )}
    </Menu>
  );
}

ActionsMenuMaker.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onDuplicate: PropTypes.func,
  onCopy: PropTypes.func,
  onDelete: PropTypes.func,
  onPublish: PropTypes.func,
  onPreview: PropTypes.func,
  t: PropTypes.func,
};

export default ActionsMenuMaker;
