import PropTypes from 'prop-types';
import clsx from 'clsx';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Select from '@design-system/Select';

import { modifierTypesList } from 'utils/modifierGroups';

import useStyles from './styles';

function SelectModifierTypeField({ isEdition, modifierType, onChangeType, t, ...others }) {
  const classes = useStyles();

  const handleRenderSelection = (itemSelected) => {
    const nameSelected = itemSelected
      ? t(`menuMaker:modifierGroupForm.modifierType.options.${itemSelected?.toLowerCase()}.name`)
      : t('menuMaker:modifierGroupForm.modifierType.filterFieldPlaceholder');

    return <span className={clsx({ [classes.placeholder]: !itemSelected })}>{nameSelected}</span>;
  };

  const handleChange = ({ target: { value } }) => {
    onChangeType(value);
  };

  return (
    <Box className={clsx({ [classes.disabledField]: isEdition })}>
      <Select
        data-testid="selectModifierTypeField"
        disabled={isEdition}
        displayEmpty
        fullWidth
        hideLabelHelperText
        label={t('menuMaker:headerForm.modifierType')}
        onChange={handleChange}
        renderValue={(selected) => handleRenderSelection(selected)}
        value={modifierType}
        {...others}
      >
        {modifierTypesList.map((type) => (
          <MenuItem key={type} data-testid={type} value={type}>
            <Box justifyContent="columns" py={0.5}>
              <Typography>
                {t(`menuMaker:modifierGroupForm.modifierType.options.${type.toLowerCase()}.name`)}
              </Typography>
              <Typography className={classes.modifierTypeDesc}>
                {t(`menuMaker:modifierGroupForm.modifierType.options.${type.toLowerCase()}.description`)}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}

SelectModifierTypeField.propTypes = {
  modifierType: PropTypes.string,
  onChangeType: PropTypes.func,
  t: PropTypes.func,
};

export default SelectModifierTypeField;
