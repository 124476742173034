import React, { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

const SearchOutline = forwardRef(function SearchOutline(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#000', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        clipRule="evenodd"
        d="M2.4 10.8C2.4 6.16081 6.16081 2.4 10.8 2.4C15.4392 2.4 19.2 6.16081 19.2 10.8C19.2 13.0632 18.305 15.1173 16.8496 16.6277C16.8086 16.6593 16.7691 16.6939 16.7315 16.7315C16.6939 16.7691 16.6593 16.8085 16.6278 16.8496C15.1173 18.305 13.0632 19.2 10.8 19.2C6.16081 19.2 2.4 15.4392 2.4 10.8ZM17.5412 19.2383C15.6936 20.7162 13.35 21.6 10.8 21.6C4.83533 21.6 0 16.7647 0 10.8C0 4.83533 4.83533 0 10.8 0C16.7647 0 21.6 4.83533 21.6 10.8C21.6 13.35 20.7162 15.6936 19.2383 17.5412L23.6486 21.9515C24.1172 22.4201 24.1172 23.1799 23.6486 23.6485C23.1799 24.1172 22.4201 24.1172 21.9515 23.6485L17.5412 19.2383Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

export default SearchOutline;
