/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import camelCase from 'lodash/camelCase';
import map from 'lodash/map';
import { useHistory } from 'react-router-dom';
import { stringify } from 'query-string';

import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Collapse from '@material-ui/core/Collapse';
import Link from '@material-ui/core/Link';

import ChevronDown from '@experimental-components/IconsComponents/ChevronDown';
import ButtonPlus from '@experimental-components/ButtonPlus';
import CustomDropdown from '@experimental-components/CustomDropdown';

import { useElementEdited } from 'containers/MenuMakerResume/ProviderEdition';

import ChanelItem from 'components/ChanelItem';

import { drawerActions, drawerEntities } from 'utils/drawerMenuMaker';
import { getScheduleFormatted } from 'utils/menus/schedule';
import { typesAddingCategories } from 'utils/categories';

import RowCategory from './RowCategory';
import HelperSkeleton from './HelperSkeleton';
import useStyles from './styles';

function RowMenu({ menu, details, onLoadDetails, openDrawer, openDefault = false }) {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation(['menuMaker', 'categories']);
  const { elementEdited, setElementEdited } = useElementEdited();
  const [openRow, setOpenRow] = useState(openDefault);

  useEffect(() => {
    if (elementEdited) {
      setOpenRow(false);
      setElementEdited(false);
    }
  }, [elementEdited]);

  useEffect(() => {
    if (!elementEdited && !openRow && openDefault) {
      handleLoadDetails();
    }
  }, [elementEdited]);

  const handleEditMenu = (e) => {
    const query = { action: drawerActions.EDIT, entity: drawerEntities.MENU, uuid: menu?.uuid };

    history.replace({ search: stringify(query) });
    e.stopPropagation();
  };

  const handleLoadDetails = () => {
    setOpenRow((state) => !state);
    if (!details?.isLoaded) {
      onLoadDetails(menu?.uuid);
    }
  };

  const handleOpenCreateCategory = (value) => {
    let query;

    if (value === typesAddingCategories.NEW) {
      query = { action: drawerActions.CREATE, entity: drawerEntities.CATEGORY, menuOfCategory: menu?.uuid };
      history.replace({ search: stringify(query) });
    }

    if (value === typesAddingCategories.EXISTING) {
      query = { action: drawerActions.EDIT, entity: drawerEntities.MENU, uuid: menu?.uuid, focusInCategories: true };
      history.replace({ search: stringify(query) });
    }
  };

  function renderContentMenu() {
    if (details?.isLoading) {
      return <HelperSkeleton type="contentMenu" />;
    }

    return (
      <>
        <Box px={4} py={2}>
          <CustomDropdown
            onChange={handleOpenCreateCategory}
            renderButton={(openOptions) => (
              <ButtonPlus data-testid={`btnAddCategoryOfMenu_${menu?.uuid}`} onClick={openOptions}>
                {t('menuMaker:buttons.addCategory')}
              </ButtonPlus>
            )}
          >
            <CustomDropdown.Option value={typesAddingCategories.EXISTING}>
              {t('categories:buttons.addExistingCategory')}
            </CustomDropdown.Option>

            <CustomDropdown.Option value={typesAddingCategories.NEW}>
              {t('categories:buttons.addNewCategory')}
            </CustomDropdown.Option>
          </CustomDropdown>
        </Box>

        <Box>
          {map(details?.categories, (category) => (
            <RowCategory
              key={`${menu?.uuid}-${category?.uuid}`}
              category={category}
              hasProductOverrides={Boolean(menu?.hasProductOverrides)}
              menu={{ uuid: menu?.uuid, name: menu?.name }}
              onLoadDetails={onLoadDetails}
              openDefault={category?.openDefault}
              openDrawer={openDrawer}
            />
          ))}
        </Box>
      </>
    );
  }

  return (
    <Box className={classes.rootRow}>
      <Box
        aria-label="collapse-menu-row"
        className={clsx(classes.headerRow, classes.styleCursorPoint)}
        data-testid={`btnOpenMenu_${menu?.uuid}`}
        onClick={() => handleLoadDetails()}
      >
        <Box>
          <Link
            className={classes.labelRow}
            component="button"
            data-testid={menu?.uuid}
            onClick={handleEditMenu}
            underline="none"
            variant="body2"
          >
            {menu?.name}
          </Link>

          <Box className={classes.wrapperTimesRow}>
            {menu?.operationTimes?.map((time) => (
              <div key={time?.uuid} className={classes.styleCursorNormal}>
                <Chip className={classes.chipTimesRow} label={getScheduleFormatted(time, t)} size="small" />
                {time?.startHour} - {time?.endHour}
              </div>
            ))}
          </Box>
        </Box>

        <Box className={classes.contentLeftRow}>
          <Box className={clsx(classes.providersRow, classes.styleCursorNormal)}>
            {menu?.providers.map((provider) => (
              <ChanelItem
                key={camelCase(`${menu?.uuid}-${provider?.providerName || provider}`)}
                size={24}
                value={provider?.providerName || provider}
              />
            ))}
          </Box>

          <ChevronDown
            className={clsx(classes.iconInitialState, { [classes.iconRotate]: openRow })}
            color="#000"
            lighter
            size={24}
          />
        </Box>
      </Box>
      <Collapse in={openRow}>{renderContentMenu()}</Collapse>
    </Box>
  );
}
RowMenu.propTypes = {
  menu: PropTypes.object,
  details: PropTypes.object,
  onLoadDetails: PropTypes.func,
  openDefault: PropTypes.bool,
  openDrawer: PropTypes.bool,
};

export default RowMenu;
