/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Skeleton from '@material-ui/lab/Skeleton';

function PlaceholdersTable({ placeholderRows, placeholderCols, width }) {
  return (
    <>
      {[...Array(placeholderRows)].map((row, i) => (
        <TableRow key={`tr-${i}`}>
          {[...Array(placeholderCols)].map((col, j) => (
            <TableCell key={`tr-${i}-td-${j}`}>
              <Skeleton height={40} width={width} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
}

PlaceholdersTable.defaultProps = {
  placeholderRows: 3,
  placeholderCols: 3,
  width: '100%',
};

PlaceholdersTable.propTypes = {
  placeholderRows: PropTypes.number,
  placeholderCols: PropTypes.number,
  width: PropTypes.string,
};

export default PlaceholdersTable;
