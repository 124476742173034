import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import saga from './saga';
import reducer from './reducer';

export function SignInProvider({ children }) {
  useInjectSaga({ key: 'signInProvider', saga });
  useInjectReducer({ key: 'signInProvider', reducer });

  return React.Children.only(children);
}

SignInProvider.propTypes = {
  children: PropTypes.element,
};

const withConnect = connect();

export { default as withSignIn } from './withSignIn';
export default compose(withConnect, memo)(SignInProvider);
