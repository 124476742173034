/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { useListsOptions } from 'providers/root/ListsOptionsProvider';
import useMenuMakerDrawerState from 'hooks/useMenuMakerDrawerState';
import useSortingListsOptions from 'hooks/useSortingListsOptions';
import useNavigation from 'hooks/navigation/useNavigation';

import { useElementEdited } from 'containers/MenuMakerResume/ProviderEdition';

import DrawerMenuMaker from 'components/DrawerMenuMaker';
import ModifierGroupForm from 'components/ModifierGroupForm';
import SelectProducts from 'components/SelectProducts';
import ModalConfirm from 'components/ModalConfirm';
import SelectSKU from 'components/SelectSKU';
import ModalValidateUsedSku from 'components/ModalValidateUsedSku';

import { setSortingPosition } from 'utils/sorting';
import { getUuidProducts } from 'utils/listsOptions';
import {
  defaultModifierGroup,
  modifierGroupIsValid,
  modifierFormIsDirty,
  modifierTypes,
  defaultOptions,
} from 'utils/modifierGroups';
import { validateProductsField } from 'utils/categories';
import { generateSkuValue } from 'utils/menuMaker';

function ModifierGroupDrawer({
  actionState,
  isEdition,
  modifierGroupState,
  onLoadModifierGroupDetails,
  onResetModifierGroupDetails,
  onSubmit,
  openDrawer,
  uuidSelected,
}) {
  const [initModifierGroup, setInitModifierGroup] = useState(defaultModifierGroup);
  const [step, setStep] = useState(1);
  const [productsSelected, setProductsSelected] = useState([]);
  const [productsSelectedTmp, setProductsSelectedTmp] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [skuSelectedTmp, setSkuSelectedTmp] = useState(null);
  const [openValidateSku, setOpenValidateSku] = useState(false);
  const [openChangeTypeModifier, setOpenChangeTypeModifier] = useState(false);
  const [typeModifierBackup, setTypeModifierBackup] = useState(null);

  const unblockRef = useRef();
  const titleRef = useRef(null);

  const { setKeepOpen, setDrawerIsDirty } = useMenuMakerDrawerState();
  const { setReloadStoreOnClose, setChangeLocationState, setGoToMenuDraft } = useNavigation();
  const {
    getProductsAutocompleteFilter: { productsAutocompleteFilterValues },
  } = useSortingListsOptions();
  const {
    getListProductsAutocomplete,
    productsAutocompleteState,
    getListProductsAutocompleteReset,
    productsOptionsState,
  } = useListsOptions();
  const { setElementEdited } = useElementEdited();

  const { t } = useTranslation();
  const history = useHistory();
  const methods = useForm({
    mode: 'onChange',
    defaultValues: { modifierTypeField: '', modifierOptionsListField: [] },
  });
  const { fields, append, move, remove } = useFieldArray({
    control: methods.control,
    name: 'modifierOptionsListField',
    rules: { minLength: 1 },
  });

  const watchFields = methods.watch();
  const isFormValid = modifierGroupIsValid(methods.formState, watchFields?.modifierOptionsListField, fields);
  const isFormDirty = modifierFormIsDirty(initModifierGroup, { ...watchFields, products: productsSelected }, fields);
  const isProductsFieldValid = validateProductsField(productsSelectedTmp, productsSelected);

  useEffect(() => {
    if (openDrawer) {
      getListProductsAutocomplete({ ...productsAutocompleteFilterValues });
    } else {
      methods.reset({ ...defaultModifierGroup });
      setInitModifierGroup(defaultModifierGroup);
      getListProductsAutocomplete({ ...productsAutocompleteFilterValues, filterByText: '' });
    }

    return () => {
      getListProductsAutocompleteReset();
      onResetModifierGroupDetails();
    };
  }, [openDrawer]);

  useEffect(() => {
    onResetModifierGroupDetails();
    getListProductsAutocomplete({ ...productsAutocompleteFilterValues, filterByText: '' });
    titleRef.current?.scrollIntoView({ block: 'start' });

    if (isEdition) {
      onLoadModifierGroupDetails();
    } else {
      methods.reset({ ...defaultModifierGroup });
    }
  }, [uuidSelected]);

  useEffect(() => {
    setStep(1);
    const modifierGroupSKU = generateSkuValue();
    let modifierGroupValues = {
      ...defaultModifierGroup,
      modifierTypeField: watchFields.modifierTypeField,
      modifierOptionsListField: defaultOptions[watchFields.modifierTypeField],
      skuFieldMultiStore: modifierGroupSKU,
    };

    const fillForm = async () => {
      await methods.reset({ ...modifierGroupValues });
      if (isEdition) methods.trigger();
    };

    if (isEdition) {
      if (!isEmpty(modifierGroupState.data)) {
        const { data } = modifierGroupState;
        const skuValue = data?.sku?.slice(0, 3) === 'GM-' ? data?.sku.slice(3) : data?.sku;

        modifierGroupValues = {
          modifierTypeField: data?.modifierType,
          nameModifierGroupField: data?.name,
          noteModifierGroupField: data?.description ?? '',
          forceSelectionField: data?.selectionType === 'FORCE_PRODUCT_SELECTION',
          ruleOptionMin: data?.min,
          ruleOptionMax: data?.max,
          ruleOptionRepeated: data?.repeated,
          modifierOptionsListField: data?.options,
          skuFieldMultiStore: skuValue ?? '',
        };

        setProductsSelected(data?.appliedProducts);
        setInitModifierGroup({ ...modifierGroupValues, products: data?.appliedProducts });
        setSkuSelectedTmp(skuValue ?? '');

        fillForm();
      }
    } else if (watchFields.modifierTypeField && openDrawer) {
      setProductsSelected([]);

      if (watchFields.modifierTypeField === modifierTypes.CUSTOMIZABLE) {
        modifierGroupValues = {
          ...modifierGroupValues,
          ruleOptionMin: 0,
          forceSelectionField: false,
        };
      }

      setInitModifierGroup({ ...modifierGroupValues, products: [] });
      setSkuSelectedTmp(modifierGroupSKU);
      fillForm();
    }
  }, [watchFields.modifierTypeField, modifierGroupState.data]);

  useEffect(() => {
    setSkuSelectedTmp(watchFields?.skuFieldMultiStore);
  }, [watchFields?.skuFieldMultiStore]);

  useEffect(() => {
    setDrawerIsDirty(isFormDirty);
    if (!openDrawer) {
      setKeepOpen(false);
      setDrawerIsDirty(false);
      setReloadStoreOnClose(false);
    }

    if (openDrawer && isFormDirty) {
      const unblock = history.block((destination) => {
        setNextPage(destination);

        setOpenConfirmationModal(isFormDirty);
        if (!isFormDirty) setKeepOpen(false);

        return !isFormDirty;
      });
      unblockRef.current = unblock;

      return () => {
        unblock();
      };
    }
  }, [isFormDirty, openDrawer]);

  useEffect(() => {
    if (actionState.fetched) {
      if (setElementEdited) setElementEdited(true);
      unblockNavigation();
    }
  }, [actionState.fetched]);

  const handleCloseDrawer = () => {
    history.replace({ search: '' });
  };

  const handleAcceptProducts = () => {
    setProductsSelected(productsSelectedTmp);
    setStep(1);
  };

  const handleAcceptSKU = () => {
    methods.setValue('skuFieldMultiStore', skuSelectedTmp, { shouldValidate: true });
    setStep(1);
  };

  const handleGoBack = () => {
    setProductsSelectedTmp([]);
    setSkuSelectedTmp(watchFields?.skuFieldMultiStore);
    setStep(1);
  };

  const handleSelectProducts = (values) => {
    setProductsSelectedTmp(setSortingPosition(values));
  };

  const handleSelectSku = (value) => {
    setSkuSelectedTmp(value ?? '');
  };

  const unblockNavigation = () => {
    setKeepOpen(false);
    if (unblockRef.current) unblockRef.current();
    unblockRef.current = null;
    setOpenConfirmationModal(false);

    if (nextPage?.pathname === history.location?.pathname && nextPage?.search === history.location?.search) {
      setStep(1);
      setSkuSelectedTmp(initModifierGroup?.skuFieldMultiStore);
      methods.reset(initModifierGroup);
    }

    history.replace({ pathname: nextPage?.pathname, search: nextPage?.search });
    setReloadStoreOnClose(true);
  };

  const handleCancel = () => {
    setChangeLocationState(false);
    setReloadStoreOnClose(false);
    setGoToMenuDraft(false);
    setKeepOpen(true);
    setNextPage(null);
    setOpenConfirmationModal(false);
  };

  const handleSaveModifierGroup = () => {
    const data = watchFields;
    const modifierOptionsFields = fields.map((field, index) => ({
      ...field,
      ...data.modifierOptionsListField[index],
    }));

    onSubmit({
      name: data?.nameModifierGroupField,
      description: data?.noteModifierGroupField,
      modifierType: data?.modifierTypeField,
      products: getUuidProducts(productsSelected),
      min: data?.ruleOptionMin,
      max: data?.ruleOptionMax,
      repeated: data?.ruleOptionRepeated,
      selectionType: data?.forceSelectionField ? 'FORCE_PRODUCT_SELECTION' : 'NOT_RULE_DEFINED',
      requiredType: 'VALUE_IN_RANGE',
      options: modifierOptionsFields.map((option) => ({
        uuid: option?.product ? option?.uuid : undefined,
        product: option?.product || option?.uuid,
        name: option?.name,
        additionalPrice: option?.additionalPrice || 0,
      })),
      sku: `GM-${data?.skuFieldMultiStore}`,
    });
  };

  const handleValidateSku = () => {
    setOpenValidateSku(true);
  };

  const handleCloseValidateSku = () => {
    setOpenValidateSku(false);
  };

  const handleAcceptChangeTypeModifier = () => {
    methods.setValue('modifierTypeField', typeModifierBackup, { shouldValidate: true });
    setOpenChangeTypeModifier(false);
    setTypeModifierBackup(null);
  };

  const handleCancelChangeTypeModifier = () => {
    setOpenChangeTypeModifier(false);
    setTypeModifierBackup(null);
  };

  return (
    <>
      <FormProvider {...methods}>
        <DrawerMenuMaker currentStep={step} open={openDrawer}>
          <DrawerMenuMaker.MainView
            disabled={
              !(isFormDirty && isFormValid) ||
              productsAutocompleteState.fetching ||
              actionState.fetching ||
              openValidateSku
            }
            labelAction={t('common:buttons.save')}
            loading={actionState.fetching || openValidateSku}
            onAction={handleValidateSku}
            onClose={openDrawer ? handleCloseDrawer : undefined}
            step={1}
          >
            <ModifierGroupForm
              fieldArrayMethods={{ fields, append, remove, move }}
              isEdition={isEdition}
              isFormDirtyOnchangeTypeModifier={modifierFormIsDirty(
                initModifierGroup,
                { ...watchFields, products: productsSelected },
                fields,
                true,
              )}
              isLoaded={productsAutocompleteState.fetched && (isEdition ? modifierGroupState.fetched : true)}
              isLoading={productsAutocompleteState.fetching || modifierGroupState.fetching}
              onChangeProductsOrder={setProductsSelected}
              onSetOpenChangeTypeModifier={setOpenChangeTypeModifier}
              onSetStep={setStep}
              onSetTypeModifierBakcup={setTypeModifierBackup}
              productsSelected={productsSelected}
              titleRef={titleRef}
            />
          </DrawerMenuMaker.MainView>

          <DrawerMenuMaker.Step
            disabled={productsOptionsState.fetching || !isProductsFieldValid}
            labelAction={t('common:buttons.accept')}
            onAction={handleAcceptProducts}
            onGoBack={handleGoBack}
            step={2}
            t={t}
          >
            <SelectProducts
              description={t('common:addProducts.descriptionModifiersGroups')}
              onSelectProducts={handleSelectProducts}
              optionsSelected={productsSelected}
              t={t}
            />
          </DrawerMenuMaker.Step>

          <DrawerMenuMaker.Step
            disabled={watchFields?.skuFieldMultiStore === skuSelectedTmp}
            labelAction={t('common:buttons.accept')}
            onAction={handleAcceptSKU}
            onGoBack={handleGoBack}
            step={3}
            t={t}
          >
            <SelectSKU
              onSelectOption={handleSelectSku}
              optionSelected={watchFields?.skuFieldMultiStore}
              t={t}
              type="modifierGroup"
            />
          </DrawerMenuMaker.Step>
        </DrawerMenuMaker>
      </FormProvider>

      {openConfirmationModal && (
        <ModalConfirm
          labelAccept={t('common:buttons.accept')}
          labelCancel={t('common:buttons.cancel')}
          message={t('menuMaker:messages.exitNoSaveMessage')}
          onAccept={unblockNavigation}
          onCancel={handleCancel}
          open={openConfirmationModal}
          title={t('menuMaker:messages.exitNoSaveTitle')}
        />
      )}

      {openValidateSku && (
        <ModalValidateUsedSku
          actionState={actionState}
          itemUuid={uuidSelected}
          onAccept={handleSaveModifierGroup}
          onClose={handleCloseValidateSku}
          sku={`GM-${watchFields?.skuFieldMultiStore}`}
          t={t}
          type="modifierGroup"
        />
      )}

      {openChangeTypeModifier && (
        <ModalConfirm
          labelAccept={t('common:buttons.accept')}
          labelCancel={t('common:buttons.cancel')}
          message={t('menuMaker:messages.exitNoSaveMessage')}
          onAccept={handleAcceptChangeTypeModifier}
          onCancel={handleCancelChangeTypeModifier}
          open={openChangeTypeModifier}
          title={t('menuMaker:modifierGroupForm.messages.confirmChangeTypeModifier')}
        />
      )}
    </>
  );
}

ModifierGroupDrawer.propTypes = {
  actionState: PropTypes.object,
  isEdition: PropTypes.bool,
  modifierGroupState: PropTypes.object,
  onLoadModifierGroupDetails: PropTypes.func,
  onResetModifierGroupDetails: PropTypes.func,
  onSubmit: PropTypes.func,
  openDrawer: PropTypes.bool,
  uuidSelected: PropTypes.string,
};

export default ModifierGroupDrawer;
