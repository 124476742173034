import { useState } from 'react';
import PropTypes from 'prop-types';

import { useDebounce } from '@open-react-hooks/use-debounce';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';

import InputAdornmentFiled from '@design-system/InputAdornmentFiled';

import ButtonPlus from '@experimental-components/ButtonPlus';

import MenuBrands from 'components/MenuBrands';
import useIconParrot from 'hooks/useIconParrot';

import SelectModifierType from './SelectModifierType';

import useStyles from './styles';

function HeaderForm({
  addButtonLabel,
  isShowFilterBrands,
  isShowModifierTypeFilter,
  onAddButtonClick,
  onSearchBoxChange,
  onModifierTypeChange,
  searchLabel,
  openDrawer,
  ...others
}) {
  const filters = Number(isShowModifierTypeFilter ?? 0);
  const classes = useStyles({ openDrawer, filters });
  const { IconSearch } = useIconParrot({ icon: 'search' });
  const { debounce } = useDebounce({ delay: 300 });

  const [filtersLocals, setFiltersLocals] = useState(null);

  const handleChange = (event) => {
    const { value } = event.target;

    if (onSearchBoxChange) {
      onSearchBoxChange({ ...filtersLocals, filterByText: value });
      setFiltersLocals({ ...filtersLocals, filterByText: value });
    }
  };

  const handleChangeModifierType = (value) => {
    if (onModifierTypeChange) {
      onModifierTypeChange({ ...filtersLocals, filterByType: value });
      setFiltersLocals({ ...filtersLocals, filterByType: value });
    }
  };

  return (
    <Box className={classes.root} {...others}>
      <Box className={classes.wrapperFilters}>
        <Box>
          <InputAdornmentFiled
            fullWidth
            hideLabelHelperTex
            id="header-form-search-box"
            onChange={debounce(handleChange)}
            placeholder={searchLabel}
            startAdornment={
              <InputAdornment position="start">
                <IconSearch />
              </InputAdornment>
            }
          />
        </Box>

        {isShowModifierTypeFilter && <SelectModifierType onSelectModifierType={handleChangeModifierType} />}
      </Box>

      <Box className={classes.wrapperActions}>
        {onAddButtonClick && (
          <ButtonPlus className={classes.middlePrimaryButton} data-testid="btnAddNewElement" onClick={onAddButtonClick}>
            {!openDrawer ? addButtonLabel : ''}
          </ButtonPlus>
        )}

        {isShowFilterBrands && <MenuBrands />}
      </Box>
    </Box>
  );
}

HeaderForm.propTypes = {
  addButtonLabel: PropTypes.string,
  isShowFilterBrands: PropTypes.bool,
  onAddButtonClick: PropTypes.func,
  onSearchBoxChange: PropTypes.func,
  onModifierTypeChange: PropTypes.func,
  searchLabel: PropTypes.string,
  openDrawer: PropTypes.bool,
};

export default HeaderForm;
