import { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import Checkbox from '@design-system/Checkbox';
import IconButton from '@design-system/IconButton';

import Help from '@experimental-components/IconsComponents/Help';
import Find from '@experimental-components/IconsComponents/Find';
import SelectKitchenAreas from '@experimental-components/SelectKitchenAreas';

import { Controller, useWatch } from 'react-hook-form';

import arrayToObjectUuid from 'utils/arrayToObjectUuid';
import { getKitchenAreasDetail } from 'utils/kitchenAreas/makeKitchenAreasFields';

import ToolTip from './ToolTip';

import useStyles from './styles';

function KitchenAreasAsigned({
  register,
  canSelectOtherArea,
  control,
  kitchenAreas,
  loading,
  onChangeCanSelectAreas,
  cantPrintKA,
  setCantPrintKA,
}) {
  const classes = useStyles();
  const { t } = useTranslation('kitchenAreas');
  const [anchorEl, setAnchorEl] = useState(null);
  const [toolTipMessage, setToolTipMessage] = useState('');

  const kitchenAreasFields = useWatch({
    control,
    name: 'kitchenAreasFields',
  });

  const kitchenNames = getKitchenAreasDetail(kitchenAreasFields, kitchenAreas)
    .map((ka) => ka?.name)
    .join(', ');

  const labelText = canSelectOtherArea
    ? t('kitchenAreas:messages.kitchenAreasPrintingOverride', { kitchenAreas: kitchenNames })
    : t('kitchenAreas:messages.kitchenAreasPrinting', { kitchenAreas: kitchenNames });

  const handleSetAnchorEl = (msg) => (event) => {
    setAnchorEl(event.currentTarget);
    setToolTipMessage(msg);
  };

  function handleSetAnchorElClose() {
    setAnchorEl(null);
  }

  function renderKitchenAreasSelectItem(selectedState) {
    if (selectedState?.length > 0) {
      return selectedState
        ?.filter((uuid) => arrayToObjectUuid(kitchenAreas)[uuid]?.name)
        .map((uuid) => arrayToObjectUuid(kitchenAreas)[uuid]?.name)
        .join(', ');
    }

    return (
      <Box className={classes.contentSelected} component="span">
        {t('kitchenAreas:messages.selectArea')}
      </Box>
    );
  }

  return (
    <Box>
      <Box className={classes.subtitles}>{t('kitchenAreas:headerDetails.kitchenArea')}</Box>
      <Box className={classes.labelKitchen} mt={1}>
        {labelText}
      </Box>
      {kitchenAreas.length > 0 ? (
        <Box className={classes.twoCols} mt={4}>
          <Box className={classes.wrapperMultiSelect}>
            <Controller
              control={control}
              name="kitchenAreasFields"
              render={({ onChange, value, name }) => (
                <SelectKitchenAreas
                  data-testid="selectOverrideKitchedAreas"
                  displayEmpty
                  isDisabled={loading || cantPrintKA || !canSelectOtherArea}
                  isFullWidth
                  multiple
                  name={name}
                  onChange={onChange}
                  renderValue={(selected) => renderKitchenAreasSelectItem(selected)}
                  value={value}
                >
                  {kitchenAreas?.map((kitchenA) => (
                    <MenuItem key={kitchenA?.uuid} data-testid={`option-ka-${kitchenA.uuid}`} value={kitchenA?.uuid}>
                      <Checkbox checked={value?.indexOf(kitchenA?.uuid) > -1} />
                      <ListItemText primary={kitchenA?.name} />
                    </MenuItem>
                  ))}
                </SelectKitchenAreas>
              )}
              rules={{ required: true }}
            />
          </Box>
          <Box alignItems="center" display="flex">
            <Checkbox
              checked={canSelectOtherArea}
              data-testid="overridesKitchenAreas"
              disabled={loading}
              inputRef={register()}
              label={t('kitchenAreas:messages.selectOtherArea')}
              name="canSelectOtherAreaField"
              onChange={onChangeCanSelectAreas}
            />

            <Box>
              <IconButton
                data-testid="openTooltipOverrideKitchenAreas"
                onClick={handleSetAnchorEl('kitchenAreas:messages.selectOtherAreaToolTip')}
              >
                <Help />
              </IconButton>
            </Box>
          </Box>
          <Box alignItems="center" display="flex" mt={5}>
            <Checkbox
              checked={cantPrintKA}
              disabled={loading}
              inputRef={register()}
              label={t('kitchenAreas:messages.notPrint')}
              name="kitchenAreasFieldsCanPrint"
              onChange={({ target: { checked } }) => setCantPrintKA(checked)}
            />

            <Box>
              <IconButton onClick={handleSetAnchorEl('kitchenAreas:messages.notPrintToolTip')}>
                <Help />
              </IconButton>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box alignItems="left" display="flex" flexDirection="column" mb={8} mt={10}>
          <Box maxWidth={220} textAlign="center" width="100%">
            <Find />
            <Box className={classes.labelKitchen} mt={2} width="100%">
              {t('common:messages.emptyInformation')}
            </Box>
          </Box>
        </Box>
      )}
      <ToolTip
        anchorEl={anchorEl}
        id="modalTooltipKitchenAreas"
        message={toolTipMessage}
        onClose={handleSetAnchorElClose}
      />
    </Box>
  );
}

KitchenAreasAsigned.propTypes = {
  kitchenAreas: PropTypes.array,
  loading: PropTypes.bool,
  register: PropTypes.func,
  control: PropTypes.object,
  canSelectOtherArea: PropTypes.bool,
  onChangeCanSelectAreas: PropTypes.func,
  cantPrintKA: PropTypes.bool,
  setCantPrintKA: PropTypes.func,
};

export default KitchenAreasAsigned;
