import { SortableHandle } from 'react-sortable-hoc';

import { makeStyles } from '@material-ui/core/styles';
import TableCellMui, { TableCellProps } from '@material-ui/core/TableCell';

import IconButton from '@design-system/IconButton';
import DragHandleVertical from '@experimental-components/IconsComponents/DragHandleVertical';

interface RootStylesProps {
  isBorder?: boolean;
  isColorError?: boolean;
  withSideEdges?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2.4rem 2rem',
    borderLeft: ({ withSideEdges }: RootStylesProps) => (withSideEdges ? `1px solid ${theme.palette.darkGrey[5]}` : 0),
    borderBottom: 0,
    borderTop: ({ isBorder }: RootStylesProps) => (isBorder ? `1px solid ${theme.palette.darkGrey[5]}` : 0),
    color: ({ isColorError }: RootStylesProps) =>
      isColorError ? theme.palette.error.main : theme.palette.common.black,
  },
}));

interface Props extends TableCellProps {
  isBorder?: boolean;
  isColorError?: boolean;
  isDraggable?: boolean;
  withSideEdges?: boolean;
  children: React.ReactNode;
  draggableIcon?: React.ReactNode;
}

function TableCell({
  draggableIcon,
  isBorder = true,
  isColorError = false,
  isDraggable = false,
  withSideEdges = false,
  children,
  ...others
}: Props) {
  const classes = useStyles({ isBorder, isColorError, withSideEdges });

  const DragHandle = SortableHandle(() => (
    <IconButton aria-label="drag" size="small">
      {draggableIcon ?? <DragHandleVertical />}
    </IconButton>
  ));

  return (
    <TableCellMui classes={{ root: classes.root }} {...others}>
      {isDraggable && <DragHandle />}
      {children}
    </TableCellMui>
  );
}

export default TableCell;
