import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
}
const ChevronBase = forwardRef(function ChevronBase(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#000', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        clipRule="evenodd"
        d="M22 16.6667L20.6401 17.9415L12 8.7254L3.35987 17.9415L2 16.6667L12 6L22 16.6667Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

export default ChevronBase;
