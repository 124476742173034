import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Information from '@experimental-components/IconsComponents/Indicators/Information';

const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    border: '1px solid #44AEC9',
    backgroundColor: '#E8F5FF',
    borderRadius: 4,
    width: '100%',
    padding: 16,
    gap: 12,
  },
}));

interface Props {
  text: string;
}

function InformationIndicador({ text }: Props) {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box>
        <Information />
      </Box>
      <Box color="text.secondary">{text}</Box>
    </Box>
  );
}

export default InformationIndicador;
