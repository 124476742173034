import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';

export const TableRowStyled = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    paddingBlock: theme.spacing(1),
    paddingInline: theme.spacing(4),
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.darkGrey[2],
    },
  },
}))(TableRow);

export const TooltipStyled = withStyles((theme) => ({
  tooltip: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[1],
    lineHeight: '1.7',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(18),
  },

  wrapperSaleReportTable: ({ openSidebar }) => ({
    width: `calc(100vw - ${openSidebar ? '282px' : '84px'})`,
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100vw - 282px)',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
    },
  }),

  wrapperSaleReportSummary: {
    boxShadow: 'inset 0px -5px 40px rgba(196, 208, 237, 0.15)',
    marginTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginInline: theme.spacing(-4),
  },

  boldRow: {
    '& > p': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },

  textDisabled: {
    color: theme.palette.darkGrey[50],
    textAlign: 'end',
  },

  strikethroughCell: {
    textDecoration: 'line-through',
  },

  describeDiscountOrder: {
    paddingTop: theme.spacing(1),
    '& > strong': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },

  headSkeleton: {
    display: 'flex',
    justifyContent: 'center',
    paddingBlock: theme.spacing(1),
  },

  head: {
    paddingBlock: theme.spacing(3),
    paddingInline: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(14),
    '&>span': {
      columnGap: theme.spacing(0.4),
      lineHeight: '1.2',
    },
  },
}));

export default useStyles;
