import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import {
  getDiningOptionsRequest,
  getDiningOptionDetailRequest,
  createDiningOptionRequest,
  deleteDiningOptionRequest,
  editDiningOptionRequest,
  setIsEnabledDiningOptionRequest,
  setIsDefaultDiningOptionRequest,
} from 'utils/api/baas/restaurant/diningOptions';

import {
  getDiningOptions,
  getDiningOptionDetail,
  createDiningOption,
  deleteDiningOption,
  editDiningOption,
  setIsEnabledDiningOption,
  setIsDefaultDiningOption,
} from './actions';

export function* handleGetDiningOptionsRequest(action) {
  const storeUuid = action.payload;

  try {
    yield put(getDiningOptions.request());

    const { data: diningOptions, pagination } = yield call(baasRequest, getDiningOptionsRequest, storeUuid);

    yield put(getDiningOptions.success({ diningOptions, pagination }));
  } catch (err) {
    yield put(getDiningOptions.failure(err));
  } finally {
    yield put(getDiningOptions.fulfill());
  }
}

export function* handleGetDiningOptionDetailRequest(action) {
  const { storeUuid, diningOptionUuid } = action.payload;

  try {
    yield put(getDiningOptionDetail.request());

    const { data } = yield call(baasRequest, getDiningOptionDetailRequest, { storeUuid, diningOptionUuid });

    yield put(getDiningOptionDetail.success(data));
  } catch (err) {
    yield put(getDiningOptionDetail.failure(err));
  } finally {
    yield put(getDiningOptionDetail.fulfill());
  }
}

export function* handleCreateDiningOptionRequest(action) {
  const { storeUuid, diningOption } = action.payload;

  try {
    yield put(createDiningOption.request());

    const { data } = yield call(baasRequest, createDiningOptionRequest, {
      storeUuid,
      diningOption,
    });

    yield put(createDiningOption.success(data));
  } catch (err) {
    yield put(createDiningOption.failure(err));
  } finally {
    yield put(createDiningOption.fulfill());
  }
}

export function* handleDeleteDiningOptionRequest(action) {
  const { storeUuid, diningOptionUuid } = action.payload;

  try {
    yield put(deleteDiningOption.request());

    yield call(baasRequest, deleteDiningOptionRequest, {
      storeUuid,
      diningOptionUuid,
    });

    yield put(deleteDiningOption.success(diningOptionUuid));
  } catch (err) {
    yield put(deleteDiningOption.failure(err));
  } finally {
    yield put(deleteDiningOption.fulfill());
  }
}

export function* handleEditDiningOptionRequest(action) {
  const { storeUuid, diningOptionUuid, diningOption } = action.payload;

  try {
    yield put(editDiningOption.request());

    const { data } = yield call(baasRequest, editDiningOptionRequest, {
      storeUuid,
      diningOptionUuid,
      diningOption,
    });

    yield put(editDiningOption.success(data));
  } catch (err) {
    yield put(editDiningOption.failure(err));
  } finally {
    yield put(editDiningOption.fulfill());
  }
}

export function* handleSetIsEnabledDiningOptionRequest(action) {
  const { storeUuid, diningOptionUuid, isEnabled } = action.payload;

  try {
    yield put(setIsEnabledDiningOption.request());

    const { data } = yield call(baasRequest, setIsEnabledDiningOptionRequest, {
      storeUuid,
      diningOptionUuid,
      isEnabled,
    });

    yield put(setIsEnabledDiningOption.success(data));
  } catch (err) {
    yield put(setIsEnabledDiningOption.failure(err));
  } finally {
    yield put(setIsEnabledDiningOption.fulfill());
  }
}

export function* handleSetIsDefaultDiningOptionRequest(action) {
  const { storeUuid, diningOptionUuid, isDefault } = action.payload;

  try {
    yield put(setIsDefaultDiningOption.request());

    const { data } = yield call(baasRequest, setIsDefaultDiningOptionRequest, {
      storeUuid,
      diningOptionUuid,
      isDefault,
    });

    yield put(setIsDefaultDiningOption.success(data));
  } catch (err) {
    yield put(setIsDefaultDiningOption.failure(err));
  } finally {
    yield put(setIsDefaultDiningOption.fulfill());
  }
}

export default function* restaurantDiningOptionsProviderSaga() {
  yield takeLatest(getDiningOptions.TRIGGER, handleGetDiningOptionsRequest);
  yield takeLatest(getDiningOptionDetail.TRIGGER, handleGetDiningOptionDetailRequest);
  yield takeLatest(createDiningOption.TRIGGER, handleCreateDiningOptionRequest);
  yield takeLatest(deleteDiningOption.TRIGGER, handleDeleteDiningOptionRequest);
  yield takeLatest(editDiningOption.TRIGGER, handleEditDiningOptionRequest);
  yield takeLatest(setIsEnabledDiningOption.TRIGGER, handleSetIsEnabledDiningOptionRequest);
  yield takeLatest(setIsDefaultDiningOption.TRIGGER, handleSetIsDefaultDiningOptionRequest);
}
