import { useContext } from 'react';

import SidebarLayoutContext from './SidebarLayoutContext';

function useSidebarLayout() {
  const { openDrawer, setOpenDrawer, openMdDrawer, setOpenMdDrawer, modeDraft, setModeDraft } = useContext(
    SidebarLayoutContext,
  );

  return { openDrawer, setOpenDrawer, openMdDrawer, setOpenMdDrawer, modeDraft, setModeDraft };
}

export default useSidebarLayout;
