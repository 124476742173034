import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import ButtonOutlined from '@design-system/ButtonOutlined';
import ButtonPrimary from '@design-system/ButtonPrimary';
import Divider from '@design-system/Divider';

import DotRed from '@experimental-components/IconsComponents/DotRed';

import useUserStore from 'hooks/useUserStore';

import useStyles from './styles';

function DialogPublishMenu({ brand, provider, open, onClose, onPublishMenus, t }) {
  const classes = useStyles();
  const { storeName } = useUserStore();

  function renderMenuInProvider(menu) {
    let returnRender = null;

    menu?.providers?.forEach((providerMenu) => {
      if (providerMenu?.providerName === provider?.value) {
        returnRender = (
          <Box key={providerMenu?.uuid} alignItems="center" className={classes.textPrimary} display="flex" p={2}>
            <DotRed />
            &nbsp;{menu?.name}
          </Box>
        );
      }
    });

    return returnRender;
  }

  return (
    <Dialog maxWidth="md" open={open}>
      <Box alignItems="center" display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Box fontSize="1.5rem" fontWeight="fontWeightMedium" mt={4} textAlign="center">
          {t('menuMaker:publishMenu.dialog.title')}
        </Box>
        <Box className={classes.textSecondary} mt={2}>
          {t('menuMaker:publishMenu.dialog.description')}
        </Box>
        <Box alignItems="center" display="flex" flexDirection="row" justifyContent="center" m="2rem 4rem">
          <Box px={3}>
            <Box className={classes.textSecondary} fontWeight="fontWeightMedium">
              {t('menuMaker:publishMenu.dialog.store')}
            </Box>
            <Box className={classes.textPrimary}>{storeName}</Box>
          </Box>
          <Box px={3}>
            <Box className={classes.textSecondary} fontWeight="fontWeightMedium">
              {t('menuMaker:publishMenu.dialog.brand')}
            </Box>
            <Box className={classes.textPrimary}>{brand?.name}</Box>
          </Box>
          <Box px={3}>
            <Box className={classes.textSecondary} fontWeight="fontWeightMedium">
              {t('menuMaker:publishMenu.dialog.chanel')}
            </Box>
            <Box alignItems="center" className={classes.textPrimary} display="flex">
              {provider?.icon}
              &nbsp;
              {t(provider?.label)}
            </Box>
          </Box>
        </Box>
        <Divider className={classes.divider} />
        <Box pt={4} px={3}>
          <Box className={classes.textSecondary} fontWeight="fontWeightMedium">
            {t('menuMaker:publishMenu.dialog.menus')}
          </Box>
          <Grid alignItems="center" container direction="row" justify="center">
            {brand?.menus?.map((menu) => renderMenuInProvider(menu))}
          </Grid>
        </Box>
      </Box>
      <DialogActions align="center" className={classes.actions}>
        <ButtonOutlined className={classes.actionButton} disableElevation onClick={onClose}>
          {t('common:buttons.cancel')}
        </ButtonOutlined>

        <ButtonPrimary className={classes.actionButton} disableElevation onClick={onPublishMenus}>
          {t('common:buttons.publish')}
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  );
}

DialogPublishMenu.propTypes = {
  brand: PropTypes.object,
  provider: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onPublishMenus: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation('menuMaker')(DialogPublishMenu);
