import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import ButtonOutlined from '@design-system/ButtonOutlined';
import IconError from '@experimental-components/IconsComponents/ErrorIllustration';
import SuccessIllustration from '@experimental-components/IconsComponents/SuccessIllustration';

function DialogMessage({ open, onClose, type, textTitle, textDescription, children, notification }) {
  const { t } = useTranslation();

  function renderIconStatus() {
    if (type === 'success') {
      return <SuccessIllustration size={120} />;
    }

    if (type === 'error') {
      return <IconError size={120} />;
    }

    return <CircularProgress color="secondary" size={72} thickness={2.5} />;
  }

  return (
    <Dialog fullScreen onClose={onClose} open={open} style={{ zIndex: 1400 }}>
      <Box alignItems="center" display="flex" flexDirection="column" height="100%" justifyContent="center">
        {renderIconStatus()}

        <Box fontSize="2.5rem" fontWeight="fontWeightMedium" mb={3} mt="1rem" textAlign="center">
          {textTitle}
        </Box>

        <Box fontSize="1.5rem" lineHeight={1.7} textAlign="center" width="40%">
          {textDescription}
        </Box>
        {children}

        {(type === 'success' || type === 'error') && (
          <Box mt="3rem">
            <ButtonOutlined disableElevation onClick={onClose}>
              {t('common:buttons.close')}
            </ButtonOutlined>
          </Box>
        )}

        {notification && notification}
      </Box>
    </Dialog>
  );
}

DialogMessage.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.oneOf(['success', 'loading', 'error']),
  textTitle: PropTypes.string,
  textDescription: PropTypes.string,
  children: PropTypes.node,
  notification: PropTypes.node,
};

export default DialogMessage;
