import { Tooltip, TooltipProps, withStyles } from '@material-ui/core';

import Help from '@experimental-components/IconsComponents/Help';

const TooltipStyled = withStyles((theme) => ({
  tooltip: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    boxShadow: '0px 8px 40px #f2f2f4',
    lineHeight: '1.7',
  },
}))(Tooltip);

interface Props extends TooltipProps {
  sizeIcon?: number;
}

function TooltipBase(props: Props) {
  const { sizeIcon = 20, children } = props;

  return (
    <>
      {children && <TooltipStyled {...props}>{children}</TooltipStyled>}{' '}
      {!children && (
        <TooltipStyled {...props}>
          <Help size={sizeIcon} />
        </TooltipStyled>
      )}
    </>
  );
}

TooltipBase.Help = Help;

export default TooltipBase;
