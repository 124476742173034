import { useState } from 'react';

import {
  getUserStoresStoreKey,
  getOrganizationUser,
  getUserCurrentStore,
  setOrganizationUser,
  setUserCurrentStore,
} from 'utils/auth';

import { Organization, StoreProps } from './types';

export default function useOrganization() {
  const [organization, setOrganization] = useState<Organization>(getOrganizationUser());
  const [currentStore, setCurrentStore] = useState<StoreProps>(getUserCurrentStore());

  const setNewOrganization = (newOrg: Organization) => {
    setOrganizationUser(newOrg);
    setOrganization(newOrg);
  };

  const setNewCurrentStore = (newOrg: StoreProps) => {
    setUserCurrentStore(newOrg);
    setCurrentStore(newOrg);
  };

  const getOrgByCurrentStore = () => {
    return currentStore?.organizationUuid || '';
  };

  return {
    stores: getUserStoresStoreKey(),
    organization,
    setOrganization: setNewOrganization,
    currentStore,
    setCurrentStore: setNewCurrentStore,
    getOrgByCurrentStore,
  };
}
