/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Trans, useTranslation } from 'react-i18next';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';

import Tabs from '@design-system/Tabs';
import InputTextFiled from '@design-system/TextField';
import InputAdornmentFiled from '@design-system/InputAdornmentFiled';
import SelectDS from '@design-system/Select';

import ImageDropzone from 'components/ImageDropzone';
import MultiSelect from 'components/MultiSelect';
import SettingsAdvanceTaxes from 'components/SettingsAdvanceTaxes';
import SkuMultiStore from 'components/SkuMultiStore';
import SelectColor from 'components/SelectColor';

import useEnvironment from 'hooks/useEnvironment';

import { tabsFormProduct, tabsFormOptions, getInitialOptionsAutocomplete } from 'utils/products';
import { taxProductKeys, taxServingUnitTypes } from 'utils/products/taxTypes';
import { regexTypes, formatTextAllowedValues } from 'utils/formatField';
import { validateUppercase, handleFormatValidateItems, validateDuplicateSkuItems } from 'utils/menuMaker';

import {
  actionsTypes,
  getMergeKitchenAreasAdd,
  getMergeKitchenAreasRemove,
  getCategoriesChanges,
  getKitchenAreasDetail,
} from 'utils/kitchenAreas/makeKitchenAreasFields';

import TabPanel from './TabPanel';
import KitchenAreasAsigned from './KitchenAreasAsigned';
import KitchenAreasBox from './kitchenAreasBox';
import ConfirmDialog from './modalConfirm';
import ProductSkeleton from './ProductSkeleton';
import useStyles from './styles';
import SoldBehaviourSection from './SoldBehaviourSection';
import { SalesTypes } from './types';

function ProductForm({
  canSelectOtherAreas,
  cantPrintKA,
  categoriesState,
  defaultTaxes = {},
  isEdition,
  isLoaded,
  isLoading,
  kitchenAreasState,
  menusProductOverride = [],
  modifierGroupsState,
  multiTaxes,
  onSetStep,
  openDrawer,
  selectedCategories,
  setCanSelectOtherAreas,
  setCantPrintKA,
  setSelectedCategories,
  uuidSelected,
  categoryOfProduct,
  shouldShowUnitSelector = false,
}) {
  const classes = useStyles();
  const { t } = useTranslation(['menuMaker', 'kitchenAreas']);
  const formMethods = useFormContext();
  const { register, errors, control, setValue, watch, trigger } = formMethods;
  const { environmentsState } = useEnvironment();

  const [tabSelected, setTabSelected] = useState(tabsFormOptions.GENERAL);
  const [showConfirmAreasKitchen, setShowConfirmAreasKitchen] = useState({});
  const [selectedVirtualCategories, setSelectedVirtualCategories] = useState([]);
  const [virtualKitchenAreas, setVirtualKitchenAreas] = useState([]);
  const [showConfirmChangeTypeSold, setShowConfirmChangeTypeSold] = useState({});

  const watchFields = watch();
  const flagMenuColor = environmentsState.REACT_APP_FEATURE_MENU_COLOR === 'true';

  const kitchenAreasFields = useWatch({
    control,
    name: 'kitchenAreasFields',
  });

  useEffect(() => {
    if (!openDrawer) {
      setCanSelectOtherAreas(false);
    }
  }, [openDrawer]);

  useEffect(() => {
    if (isLoading || (!isEdition && openDrawer) || categoryOfProduct) {
      setTabSelected(tabsFormOptions.GENERAL);
    }
  }, [isLoading, isEdition, categoryOfProduct]);

  const handleChangeSelectedTab = (event, newValue) => {
    setTabSelected(newValue);
  };

  function handleChangeCategories(selectedOptions) {
    const categoriesChanges = getCategoriesChanges(selectedCategories, selectedOptions);

    if (isEdition && !canSelectOtherAreas) {
      if (categoriesChanges.actionType !== actionsTypes.ACTION_TYPE_NOTHING && !canSelectOtherAreas) {
        setShowConfirmAreasKitchen({
          message:
            categoriesChanges.actionType === actionsTypes.ACTION_TYPE_ADD
              ? t('menuMaker:dialogConfirmKitchenAreas.messageAddMode')
              : t('menuMaker:dialogConfirmKitchenAreas.messageRemoveMode'),
          show: true,
          isAddMode: categoriesChanges.actionType === actionsTypes.ACTION_TYPE_ADD,
        });
        proccessSelectedCategoriesVirtual(selectedOptions, categoriesChanges);
      } else {
        setSelectedCategories(selectedOptions);
        setValue('selectedCategories', selectedOptions, { shouldValidate: true });
      }
    } else if (isEdition && canSelectOtherAreas) {
      setSelectedCategories(selectedOptions);
      setValue('selectedCategories', selectedOptions, { shouldValidate: true });
    } else if (!isEdition && !canSelectOtherAreas) {
      proccessSelectedCategories(selectedOptions, categoriesChanges);
    } else {
      setSelectedCategories(selectedOptions);
      setValue('selectedCategories', selectedOptions, { shouldValidate: true });
    }
  }

  function renderServingUnitItem(selectedKey) {
    const renderSelected = <Box className={classes.placeholderSelected}>{t('common:actions.select')}</Box>;

    if (selectedKey) {
      const itemSelected = taxServingUnitTypes.filter((item) => item.key === selectedKey);
      return itemSelected[0]?.label;
    }

    return renderSelected;
  }

  function renderProductCatalogueItem(selectedKey) {
    let renderSelected = <Box className={classes.placeholderSelected}>{t('common:actions.select')}</Box>;

    if (selectedKey) {
      const itemSelected = taxProductKeys.filter((item) => item.key === selectedKey);
      renderSelected = (
        <Box className={classes.contentSelectedTypeProductSat} component="span">
          {itemSelected[0]?.label}
        </Box>
      );
    }

    return renderSelected;
  }

  function handleCanSelectAreas(event) {
    const { checked } = event.target;
    setCanSelectOtherAreas(checked);
    if (checked && kitchenAreasFields?.length > 0) {
      setValue('kitchenAreasFields', [], { shouldValidate: true });
    } else {
      const categoriesChanges = getCategoriesChanges([], watchFields?.selectedCategories);
      const newKitchenAreas = getMergeKitchenAreasAdd([], categoriesChanges?.kitchenAreasAssignation);
      setValue('kitchenAreasFields', newKitchenAreas, { shouldValidate: true });
    }
  }

  function proccessSelectedCategories(selectedOptions, categoriesChanges) {
    setSelectedCategories(selectedOptions);
    setValue('selectedCategories', selectedOptions, { shouldValidate: true });
    if (categoriesChanges.actionType === actionsTypes.ACTION_TYPE_NOTHING) return;

    let newKitchenAreas = [];
    if (categoriesChanges.actionType === actionsTypes.ACTION_TYPE_ADD) {
      newKitchenAreas = getMergeKitchenAreasAdd(kitchenAreasFields, categoriesChanges?.kitchenAreasAssignation);
    } else {
      newKitchenAreas = getMergeKitchenAreasRemove(
        kitchenAreasFields,
        selectedCategories,
        categoriesChanges?.kitchenAreasAssignation,
      );
    }
    setValue('kitchenAreasFields', newKitchenAreas, { shouldValidate: true });
  }

  function proccessSelectedCategoriesVirtual(selectedOptions, categoriesChanges) {
    setSelectedVirtualCategories(selectedOptions);
    let newKitchenAreas = [];
    if (categoriesChanges.actionType === actionsTypes.ACTION_TYPE_ADD) {
      const defaultConfig = {};
      newKitchenAreas = getMergeKitchenAreasAdd(kitchenAreasFields, categoriesChanges?.kitchenAreasAssignation);

      if (kitchenAreasFields?.length > 0) {
        newKitchenAreas.forEach((kictehArea) => {
          defaultConfig[`${kictehArea}Field`] = true;
        });
      }
    } else {
      newKitchenAreas = getMergeKitchenAreasRemove(
        kitchenAreasFields,
        selectedCategories,
        categoriesChanges?.kitchenAreasAssignation,
      );
    }
    setVirtualKitchenAreas(newKitchenAreas);
  }

  function handleCancelConfirmNewAreasKitchen() {
    setShowConfirmAreasKitchen({ show: false });
    setSelectedVirtualCategories([]);
    setVirtualKitchenAreas([]);
  }

  function handleConfirmNewAreasKitchen() {
    setSelectedCategories(selectedVirtualCategories);
    setValue('selectedCategories', selectedVirtualCategories, { shouldValidate: true });
    handleCancelConfirmNewAreasKitchen();
    setValue('kitchenAreasFields', virtualKitchenAreas, { shouldValidate: true });
  }

  function handleChangeTypeSold(value) {
    if (isEdition && value !== SalesTypes.BY_GRAMMAGE) {
      const titleModal = value === SalesTypes.ALONE ? 'titleSoldAlone' : 'titleNotSoldAlone';
      const messageModal = value === SalesTypes.ALONE ? 'messageSoldAlone' : 'messageNotSoldAlone';
      setShowConfirmChangeTypeSold({ show: true, value, titleModal, messageModal });
    } else {
      setValue('soldAloneField', value, { shouldValidate: true });
      handleResetValuesSoldAlone();
    }
  }

  function handleConfirmChangeTypeSold() {
    handleResetValuesSoldAlone();
    setValue('soldAloneField', showConfirmChangeTypeSold.value, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setShowConfirmChangeTypeSold({ show: false });
  }

  function handleCancelChangeTypeSold() {
    setShowConfirmChangeTypeSold({ show: false });
  }

  function handleResetValuesSoldAlone() {
    setSelectedCategories([]);
    setCanSelectOtherAreas(false);
    setCantPrintKA(false);
    setValue('selectedCategories', [], {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue('selectedModifierGroups', [], {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue('kitchenAreasFields', [], {
      shouldValidate: true,
      shouldDirty: true,
    });

    setValue('exemptField', false, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue('taxGroupFieldIVA', defaultTaxes?.taxGroupFieldIVA ?? '', {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue('taxGroupFieldIEPS', defaultTaxes?.taxGroupFieldIEPS ?? '', {
      shouldValidate: true,
      shouldDirty: true,
    });
  }

  const handlePreventNewLine = (e) => {
    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault();
      return false;
    }
  };

  const handleValidateAllowedValues = (target, field) => {
    target.value = formatTextAllowedValues(target.value, regexTypes.REGEX_PRODUCT_NAME_ALLOWED);
    setValue(field, target.value);
    return target.value;
  };
  const handleOpenSelectSKU = () => {
    onSetStep(2);
  };

  const renderTitle = () => {
    return (
      <Typography className={classes.title}>
        {t(`menuMaker:sections.${isEdition ? 'editProduct' : 'addProduct'}`)}
      </Typography>
    );
  };

  if (isLoading) {
    return (
      <ProductSkeleton>
        <ProductSkeleton.Title>{renderTitle()}</ProductSkeleton.Title>
      </ProductSkeleton>
    );
  }

  if (isLoaded) {
    return (
      <Grid container direction="column" spacing={4}>
        <Grid item>{renderTitle()}</Grid>

        {isEdition && (
          <Grid item>
            <Box className={classes.messageEditionProduct}>{t('menuMaker:productForm.isEditProduct')}</Box>
          </Grid>
        )}

        <Tabs
          indicatorColor="primary"
          onChange={handleChangeSelectedTab}
          scrollButtons="auto"
          textColor="primary"
          value={tabSelected}
          variant="scrollable"
        >
          {tabsFormProduct.map((option) => (
            <Tabs.Tab
              key={`form-product-tab-${option.value}`}
              data-testid={option.testId}
              label={t(option.label)}
              value={option.value}
            />
          ))}
        </Tabs>
        <TabPanel index={tabsFormOptions.GENERAL} mt={7} px={2} value={tabSelected}>
          <Grid container direction="column" item spacing={4}>
            <Grid item>
              <InputAdornmentFiled
                autoComplete="off"
                autoFocus
                data-testid="inputNameProduct"
                endAdornment={
                  <InputAdornment position="end">
                    <Box color="#CECDD1" fontSize="14px">
                      {watch('nameProductField')?.length ?? 0}/40
                    </Box>
                  </InputAdornment>
                }
                error={Boolean(errors?.nameProductField)}
                errorMsg={errors?.nameProductField?.message}
                fullWidth
                inputProps={{
                  maxLength: 40,
                  minLength: 3,
                  'data-testid': 'nameProduct',
                }}
                inputRef={register({
                  required: t('common:messages.requireField'),
                  maxLength: {
                    value: 40,
                    message: t('common:errors.maxLengthField', { chars: 40 }),
                  },
                  minLength: {
                    value: 3,
                    message: t('common:errors.minLength3Field'),
                  },
                  validate: {
                    controlUppercase: (value) => validateUppercase(value) || t('common:errors.controlUppercase'),
                  },
                })}
                label={t('menuMaker:productForm.tabGeneral.nameProductField')}
                name="nameProductField"
                onChange={({ target }) => handleValidateAllowedValues(target, 'nameProductField')}
              />
            </Grid>

            <Grid item>
              <Box display="grid" gridGap={13} gridTemplateColumns="1fr 1fr">
                <Box>
                  <Box mb={1}>{t('menuMaker:productForm.tabGeneral.descriptionProductField')}</Box>

                  <Box className={classes.boxesColumn}>
                    <InputAdornmentFiled
                      className={classes.baseTextArea}
                      data-testid="inputDescriptionProduct"
                      endAdornment={
                        <Box bottom="16px" color="#CECDD1" fontSize="14px" position="absolute" right="16px">
                          {watch('descriptionProductField')?.length ?? 0}/125
                        </Box>
                      }
                      error={Boolean(errors?.descriptionProductField)}
                      errorMsg={errors?.descriptionProductField?.message}
                      fullWidth
                      hideLabel
                      hideLabelHelperTex
                      inputProps={{
                        maxLength: 125,
                        minLength: 3,
                        className: classes.textArea,
                      }}
                      inputRef={register({
                        maxLength: {
                          value: 125,
                          message: t('common:errors.maxCharsField', { chars: 125 }),
                        },
                        minLength: {
                          value: 3,
                          message: t('common:errors.minLength3Field'),
                        },
                        validate: {
                          controlUppercase: (value) => validateUppercase(value) || t('common:errors.controlUppercase'),
                        },
                      })}
                      multiline
                      name="descriptionProductField"
                      onChange={({ target }) => handleValidateAllowedValues(target, 'descriptionProductField')}
                      onKeyDown={handlePreventNewLine}
                    />
                  </Box>
                </Box>

                <Box>
                  <Typography className={classes.subtitlesThree}>
                    {t('menuMaker:productForm.tabGeneral.photo')}
                  </Typography>

                  <Box className={classes.boxesColumn}>
                    <Typography className={classes.descriptionPhoto}>
                      <Trans i18nKey="menuMaker:productForm.tabGeneral.descriptionPhoto" />
                    </Typography>
                    <Controller
                      control={control}
                      name="imageProductField"
                      render={({ onChange, value, name }) => (
                        <ImageDropzone
                          initialImageUrl={value}
                          isEdition={isEdition}
                          name={name}
                          onImageChange={onChange}
                          openDrawer={openDrawer}
                          value={value}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <SoldBehaviourSection
              formMethods={formMethods}
              isEdition={isEdition}
              menusProductOverride={menusProductOverride}
              onCancelChangeType={handleCancelChangeTypeSold}
              onChangeTypeSold={handleChangeTypeSold}
              onConfirmChangeType={handleConfirmChangeTypeSold}
              priceProductField={watchFields?.priceProductField}
              shouldShowUnitSelector={shouldShowUnitSelector}
              showConfirmChangeTypeSold={showConfirmChangeTypeSold}
              uuidSelected={uuidSelected}
            />

            <Grid
              className={clsx({ [classes.hideElements]: watchFields?.soldAloneField === SalesTypes.WITH_MODIFIER })}
              item
            >
              <Typography className={classes.subtitles}>{t('menuMaker:productForm.categoryPicker.title')}</Typography>

              <Box mb={1}>
                <Typography variant="body2">{t('menuMaker:productForm.categoryPicker.inputPlaceholder')}</Typography>
              </Box>

              <Controller
                control={control}
                name="selectedCategories"
                render={({ value, name }) => (
                  <MultiSelect
                    getOptionLabel={(option) => option.name}
                    getOptionUniqueKey={(option) => option.uuid}
                    id="product-autocomplete-categories"
                    inputPlaceholderText={t('menuMaker:productForm.categoryPicker.inputPlaceholder')}
                    isSorteable={false}
                    name={name}
                    noOptionsText={t('menuMaker:productForm.categoryPicker.noCategories')}
                    onChange={(selectedOptions) => {
                      handleChangeCategories(handleFormatValidateItems(selectedOptions));
                    }}
                    options={getInitialOptionsAutocomplete(categoriesState.list, value)}
                    renderOption={(option, props) => {
                      return (
                        <li {...props} data-testid={`option-category-${option.uuid}`}>
                          {option.name}
                        </li>
                      );
                    }}
                    selectedOptions={value}
                    value={value}
                  />
                )}
                rules={{
                  validate: (items) => {
                    const itemsDuplicateSku = validateDuplicateSkuItems(items);

                    return (
                      itemsDuplicateSku?.length === 0 || t('menuMaker:skuLabels.product.errorDuplicateCategoriesSku')
                    );
                  },
                }}
              />
              {Boolean(errors?.selectedCategories) && (
                <Box className={classes.errorDuplicateSku}>{errors?.selectedCategories?.message}</Box>
              )}
            </Grid>

            <Grid className={clsx({ [classes.hideElements]: watchFields?.soldAloneField !== SalesTypes.ALONE })} item>
              <Typography className={classes.subtitles}>
                {t('menuMaker:productForm.modifierGroupsPicker.title')}
              </Typography>

              <Box mb={1}>
                <Typography variant="body2">{t('menuMaker:productForm.modifierGroupsPicker.description')}</Typography>
              </Box>
              <Controller
                control={control}
                name="selectedModifierGroups"
                render={({ onChange, value, name }) => (
                  <MultiSelect
                    getOptionDescription={(option) => option.description}
                    getOptionLabel={(option) => option.name}
                    getOptionUniqueKey={(option) => option.uuid}
                    id="product-autocomplete-modifiers"
                    inputPlaceholderText={t('menuMaker:productForm.modifierGroupsPicker.inputPlaceholder')}
                    name={name}
                    noOptionsText={t('menuMaker:productForm.modifierGroupsPicker.noModifiers')}
                    onChange={(selectedOptions) => onChange(handleFormatValidateItems(selectedOptions))}
                    options={getInitialOptionsAutocomplete(modifierGroupsState.list, value)}
                    renderOption={(option, props) => {
                      return (
                        <li {...props} data-testid={`option-modifier-${option.uuid}`}>
                          <Box>{option.name}</Box>
                          {option?.description && (
                            <Box className={classes.descriptionModifiers}>{option?.description}</Box>
                          )}
                        </li>
                      );
                    }}
                    selectedOptions={value}
                    value={value}
                  />
                )}
                rules={{
                  validate: (items) => {
                    const itemsDuplicateSku = validateDuplicateSkuItems(items);

                    return (
                      itemsDuplicateSku?.length === 0 ||
                      t('menuMaker:skuLabels.product.errorDuplicateModifierGroupsSku')
                    );
                  },
                }}
              />
              {Boolean(errors?.selectedModifierGroups) && (
                <Box className={classes.errorDuplicateSku}>{errors?.selectedModifierGroups?.message}</Box>
              )}

              <Box mt={5}>
                <Divider />
              </Box>
            </Grid>

            <Grid className={clsx({ [classes.disableSection]: !flagMenuColor })} item>
              <Controller
                control={control}
                name="colorField"
                render={({ onChange, value }) => <SelectColor entity="product" onChange={onChange} value={value} />}
                rules={{ required: true }}
              />
              <Box mt={5}>
                <Divider />
              </Box>
            </Grid>

            <Grid item>
              <Typography className={classes.subtitles}>{t('menuMaker:skuLabels.sku')}</Typography>

              <Box mb={4} mt={1}>
                <Typography color="textSecondary" variant="body2">
                  {t('menuMaker:skuLabels.product.info')}
                </Typography>
              </Box>

              <SkuMultiStore
                errors={errors}
                labelButon="addExistingSku"
                onClickButton={handleOpenSelectSKU}
                register={register}
                trigger={isEdition ? trigger : null}
                watch={watch}
              />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel index={tabsFormOptions.ADVANCED} mt={7} px={2} value={tabSelected}>
          <Grid container direction="column" item spacing={4}>
            <Grid item>
              <InputTextFiled
                data-testid="inputPosNameProduct"
                endAdornment={
                  <InputAdornment position="end">
                    <Box color="#CECDD1" fontSize="14px">
                      {watch('posNameField')?.length ?? 0}/70
                    </Box>
                  </InputAdornment>
                }
                error={Boolean(errors?.posNameField)}
                errorMsg={errors?.posNameField?.message}
                fullWidth
                inputProps={{ maxLength: 70, 'data-testid': 'posNameProduct' }}
                inputRef={register({
                  maxLength: {
                    value: 70,
                    message: t('common:errors.maxLengthField', { chars: 70 }),
                  },
                })}
                label={t('menuMaker:productForm.posNameLabel')}
                name="posNameField"
              />

              <Box mt={5}>
                <Divider />
              </Box>
            </Grid>

            <Grid item>
              <Controller
                control={control}
                name="taxServingUnitField"
                render={({ onChange, value, name, ref }) => (
                  <SelectDS
                    data-testid="taxServingUnitField"
                    displayEmpty
                    fullWidth
                    inputRef={ref}
                    label={t('menuMaker:productForm.unitMeasurement')}
                    name={name}
                    onChange={onChange}
                    renderValue={(selected) => renderServingUnitItem(selected)}
                    value={value}
                  >
                    {taxServingUnitTypes.map((taxKey) => (
                      <MenuItem key={taxKey.key} data-testid={taxKey.key} value={taxKey.key}>
                        {taxKey.label}
                      </MenuItem>
                    ))}
                  </SelectDS>
                )}
              />
            </Grid>

            <Grid item>
              <Box className={classes.wrapperMultiSelect} display="grid">
                <Controller
                  control={control}
                  name="taxProductKeyField"
                  render={({ onChange, value, name, ref }) => (
                    <SelectDS
                      data-testid="taxProductKeyField"
                      displayEmpty
                      fullWidth
                      inputRef={ref}
                      label={t('menuMaker:productForm.productCatalogue')}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            marginBottom: 4,
                            marginTop: 4,
                            maxHeight: 'calc(80% - 96px)',
                          },
                        },
                      }}
                      name={name}
                      onChange={onChange}
                      renderValue={(selected) => renderProductCatalogueItem(selected)}
                      value={value}
                    >
                      {taxProductKeys.map((productKey) => (
                        <MenuItem key={productKey.key} data-testid={productKey.key} value={productKey.key}>
                          {productKey.label}
                        </MenuItem>
                      ))}
                    </SelectDS>
                  )}
                />
              </Box>
            </Grid>

            <Grid
              className={clsx({ [classes.hideElements]: watchFields?.soldAloneField === SalesTypes.WITH_MODIFIER })}
              item
            >
              <Box mb={5}>
                <Divider />
              </Box>

              <SettingsAdvanceTaxes control={control} register={register} setValue={setValue} taxesGroup={multiTaxes} />

              <Box mt={5}>
                <Divider />
              </Box>
            </Grid>

            <Grid
              className={clsx({ [classes.hideElements]: watchFields?.soldAloneField === SalesTypes.WITH_MODIFIER })}
              item
            >
              <KitchenAreasAsigned
                canSelectOtherArea={canSelectOtherAreas}
                cantPrintKA={cantPrintKA}
                control={control}
                kitchenAreas={kitchenAreasState.list}
                kitchenAreasFieldsCanPrint={watchFields?.kitchenAreasFieldsCanPrint}
                loading={isLoading}
                onChangeCanSelectAreas={handleCanSelectAreas}
                register={register}
                setCantPrintKA={setCantPrintKA}
                t={t}
              />
            </Grid>
          </Grid>

          {showConfirmAreasKitchen.show && (
            <ConfirmDialog
              id="dialogConfirmKitchenAreas"
              labelAccept={showConfirmAreasKitchen.isAddMode ? t('common:buttons.confirm') : t('common:buttons.accept')}
              labelCancel={t('common:buttons.cancel')}
              message={showConfirmAreasKitchen.message}
              onAccept={handleConfirmNewAreasKitchen}
              onCancel={handleCancelConfirmNewAreasKitchen}
              open={showConfirmAreasKitchen.show}
              title={t('menuMaker:dialogConfirmKitchenAreas.title')}
            >
              {!canSelectOtherAreas && virtualKitchenAreas?.length > 0 && showConfirmAreasKitchen.isAddMode && (
                <KitchenAreasBox
                  kitchenAreasNames={getKitchenAreasDetail(virtualKitchenAreas, kitchenAreasState.list).map(
                    (ka) => ka.name,
                  )}
                />
              )}
            </ConfirmDialog>
          )}
        </TabPanel>
      </Grid>
    );
  }

  return null;
}

ProductForm.propTypes = {
  isEdition: PropTypes.bool,
  isLoading: PropTypes.bool,
  categoriesState: PropTypes.object,
  kitchenAreasState: PropTypes.object,
  modifierGroupsState: PropTypes.object,
  openDrawer: PropTypes.bool,
  canSelectOtherAreas: PropTypes.bool,
  setCanSelectOtherAreas: PropTypes.func,
  isLoaded: PropTypes.bool,
  cantPrintKA: PropTypes.bool,
  setCantPrintKA: PropTypes.func,
  defaultTaxes: PropTypes.object,
  multiTaxes: PropTypes.array,
  menusProductOverride: PropTypes.array,
  uuidSelected: PropTypes.string,
  categoryOfProduct: PropTypes.string,
};

export default ProductForm;
