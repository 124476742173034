import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';

import ToggleButtonGroup from '@design-system/ToggleButtonGroup';

import { booleansLabels, booleansTypesValues } from 'utils/getBoolean';

import useStyles from './styles';

function ConfigForm() {
  const { t } = useTranslation('settingsClockTime');
  const classes = useStyles();
  const { control } = useFormContext();

  return (
    <Box ml={4} mr={4}>
      <Grid container direction="column" spacing={8}>
        <Grid item md={7} sm={10} xs={12}>
          <Box color="text.primary" fontSize={16} fontWeight="500" mb={1}>
            {t('settingsClockTime:form.timeClockTitleSection')}
          </Box>
          <Box color="text.secondary" fontSize={14} fontWeight="400" mb={3}>
            {t('settingsClockTime:form.timeClockDescriptionSection')}
          </Box>
          <Controller
            control={control}
            name="isEnabledAuthPin"
            render={({ onChange, ...others }) => (
              <ToggleButtonGroup
                className={classes.toggleButtonGroup}
                exclusive
                onChange={(e, option) => onChange(option)}
                orientation="horizontal"
                spacing={3}
                {...others}
              >
                <ToggleButton className={classes.toggleButton} value={String(booleansTypesValues.TRUE)}>
                  {t(booleansLabels.TRUE)}
                </ToggleButton>
                <ToggleButton className={classes.toggleButton} value={String(booleansTypesValues.FALSE)}>
                  {t(booleansLabels.FALSE)}
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ConfigForm;
