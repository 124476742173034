import React from 'react';
import { styled } from '@material-ui/core/styles';

interface Props {
  children: React.ReactNode;
}

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  overflowX: 'auto',
  backgroundColor: theme.palette.common.white,
  marginBottom: '2rem',
}));

const Container = ({ children }: Props) => {
  return <Root aria-label="Container">{children}</Root>;
};

export default Container;
