import { Switch } from 'react-router-dom';

import { useFeature } from 'FeatureProvider';

import SettingsLink from 'containers/Settings/Loadable';
import SettingsPayIn from 'containers/SettingsPayInConfig/Loadable';
import SettingsKitchenTicket from 'containers/SettingsKitchenTicket/Loadable';
import SettingsPayments from 'containers/SettingsPayments/Loadable';
import SettingsDiscounts from 'containers/SettingsDiscounts/Loadable';
import SettingsCancellationReasons from 'containers/SettingsCancellationReasons/Loadable';
import SettingsOrderTypes from 'containers/SettingsOrderTypes/Loadable';
import SettingsReceiptCustom from 'containers/SettingsReceiptCustom/Loadable';
import SettingsServiceCharges from 'containers/SettingsServiceCharges/Loadable';
import SettingsCommissionCapture from 'containers/SettingsCommissionCapture/Loadable';
import SettingsParrotPay from 'containers/SettingsParrotPay/Loadable';
import SettingsKitchenAreas from 'containers/SettingsKitchenAreas/Loadable';
import SettingsServiceAreas from 'containers/SettingsServiceAreas/Loadable';
import SettingsAdminTables from 'containers/SettingsAdminTables/Loadable';
import SettingsTablesConfig from 'containers/SettingsTablesConfig/Loadable';
import SettingsCashRegister from 'containers/SettingsCashRegister/Loadable';
import SettingsMultiTaxes from 'containers/SettingsMultiTaxes/Loadable';
import SettingsTips from 'containers/SettingsTips/Loadable';
import SettingsTimeClock from 'containers/SettingsTimeClock';
import SettingsAdminImagesPos from 'containers/SetttingsAdminImagesPos/Loadable';

import useUserStore from 'hooks/useUserStore';

import AppRoute from '../PrivateRoute';

export function SettingsRoutes() {
  const {
    storeState: { selected: storeSelected },
  } = useUserStore();
  const superUserAccess = useFeature('adminStore');
  const settingsModuleAccess = useFeature('backofficeModuleSettings');
  const areaTableCanViewAreaTablesAccess = useFeature('areaTableCanViewAreaTables');
  const areaTableCanViewSettingsAccess = useFeature('areaTableCanViewSettings');
  const permissionSettingsModule = (superUserAccess || settingsModuleAccess) && !storeSelected?.isDraft;
  const permissionAreaTableCanViewAreaTablesModule =
    (superUserAccess || areaTableCanViewAreaTablesAccess) && !storeSelected?.isDraft;
  const permissionAreaTableCanViewSettingsAccessModule =
    (superUserAccess || areaTableCanViewSettingsAccess) && !storeSelected?.isDraft;

  return (
    <Switch>
      <AppRoute accessRoute={permissionSettingsModule} component={SettingsLink} exact path="/settings" />
      <AppRoute
        accessRoute={permissionSettingsModule}
        component={SettingsPayments}
        exact
        path="/settings/payment-methods/:actionType(duplicate|create|edit)?/:paymentUuid?"
      />
      <AppRoute
        accessRoute={permissionSettingsModule}
        component={SettingsDiscounts}
        exact
        path="/settings/payments-discounts/:actionType(duplicate|create|edit)?/:discountUuid?"
      />
      <AppRoute
        accessRoute={permissionSettingsModule}
        component={SettingsCancellationReasons}
        exact
        path="/settings/payments-cancellation-reasons/:actionType(create)?"
      />
      <AppRoute
        accessRoute={permissionSettingsModule}
        component={SettingsOrderTypes}
        exact
        path="/settings/order-types/:actionType(duplicate|create|edit)?/:diningOptionUuid?"
      />
      <AppRoute
        accessRoute={permissionSettingsModule}
        component={SettingsReceiptCustom}
        exact
        path="/settings/receipt-custom/:actionType(duplicate|create|edit)?/:brandUuid?"
      />
      <AppRoute
        accessRoute={permissionSettingsModule}
        component={SettingsKitchenTicket}
        exact
        path="/settings/kitchen-ticket/"
      />
      <AppRoute
        accessRoute={permissionSettingsModule}
        component={SettingsPayIn}
        exact
        path="/settings/payin/:actionType(create|edit)?/:reasonUuid?"
      />
      <AppRoute
        accessRoute={permissionSettingsModule}
        component={SettingsServiceCharges}
        exact
        path="/settings/payments-service-charges/:actionType(duplicate|create|edit)?/:serviceChargeUuid?"
      />
      <AppRoute
        accessRoute={permissionSettingsModule}
        component={SettingsCommissionCapture}
        exact
        path="/settings/commission-capture"
      />
      <AppRoute accessRoute={permissionSettingsModule} component={SettingsParrotPay} exact path="/settings/pay" />
      <AppRoute
        accessRoute={permissionSettingsModule}
        component={SettingsKitchenAreas}
        exact
        path="/settings/kitchen-areas/:actionType(duplicate|create|edit)?/:kitchenAreaUuidParam?"
      />
      <AppRoute
        accessRoute={permissionAreaTableCanViewAreaTablesModule}
        component={SettingsServiceAreas}
        exact
        path="/settings/tables-service-areas"
      />
      <AppRoute
        accessRoute={permissionAreaTableCanViewAreaTablesModule}
        component={SettingsServiceAreas}
        exact
        path="/settings/tables-service-areas/:actionType(create|edit)?/:serviceAreaUuid?"
      />
      <AppRoute
        accessRoute={permissionAreaTableCanViewAreaTablesModule}
        component={SettingsAdminTables}
        exact
        path="/settings/tables-admin/:actionType(create|edit)?/:tableUuid?"
      />
      <AppRoute
        accessRoute={permissionAreaTableCanViewSettingsAccessModule}
        component={SettingsTablesConfig}
        exact
        path="/settings/tables-config"
      />
      <AppRoute
        accessRoute={permissionAreaTableCanViewSettingsAccessModule}
        component={SettingsCashRegister}
        exact
        path="/settings/cash-register"
      />
      <AppRoute accessRoute={permissionSettingsModule} component={SettingsMultiTaxes} exact path="/settings/taxes" />
      <AppRoute accessRoute={permissionSettingsModule} component={SettingsTips} exact path="/settings/tips" />
      <AppRoute
        accessRoute={useFeature('@module.timeClock')}
        component={SettingsTimeClock}
        exact
        path="/settings/clock-time"
      />
      <AppRoute component={SettingsAdminImagesPos} exact path="/settings/admin-images-pos" />
    </Switch>
  );
}
