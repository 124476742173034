import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { initSignIn } from './actions';
import { selectIsAuthenticated, selectSignInFetching, selectSignInError, selectProfile } from './selectors';

export function withSignInHoc(Component) {
  function withSignIn(props) {
    return <Component {...props} />;
  }

  withSignIn.displayName = `withSignIn(${getDisplayName(Component)})`; // Display Name for Easy Debugging

  return withSignIn;
}

const mapStateToProps = createStructuredSelector({
  profile: selectProfile,
  authenticated: selectIsAuthenticated,
  signInFetching: selectSignInFetching,
  signInError: selectSignInError,
});

export function mapDispatchToProps(dispatch) {
  return {
    initSignIn: ({ user, password }) => dispatch(initSignIn({ user, password })),
    resetInitSignIn: () => dispatch(initSignIn.reset()),
  };
}

export const withSignInState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withSignInState, withSignInHoc);
