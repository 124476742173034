import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(24),
  },

  subtitles: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(18),
  },

  paperHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },

  wrapperTimers: {
    display: 'flex',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(6),
    },
  },

  formChanel: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.spacing(3),
  },

  checkBoxChanelPaper: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },

  checkBoxLabel: {
    width: '100%',
    margin: 0,
    paddingRight: theme.typography.pxToRem(24),
    paddingLeft: theme.typography.pxToRem(12),
  },

  checkBoxChanelActive: {
    borderColor: theme.palette.error.main,
  },

  selectTime: {
    minWidth: theme.typography.pxToRem(280),
  },

  checkBoxSelectAll: {
    marginBottom: '.5rem',
    '&>span': {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(14),
    },
  },

  weekdaysContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.spacing(3),
  },

  weekdays: {
    width: theme.typography.pxToRem(96),
    '&>span': {
      fontSize: theme.typography.pxToRem(14),
    },
  },

  grayBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.darkGrey[2],
    borderRadius: theme.typography.pxToRem(4),
    color: theme.palette.darkGrey.main,
    '& strong': {
      fontWeight: 500,
    },
  },

  toggleOverride: {
    display: 'flex',
    columnGap: theme.spacing(3),
    '&>button': {
      width: `${theme.typography.pxToRem(94)} !important`,
      height: `${theme.typography.pxToRem(56)} !important`,
      border: `1px solid ${theme.palette.darkGrey[15]} !important`,
      borderRadius: `${theme.shape.borderRadius}px !important`,
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.text.secondary,
    },
  },
  errorMessagesCustom: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(12),
  },
}));

export default useStyles;
