import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import TabsMui from '@material-ui/core/Tabs';

import Tab from './Tab';

const StyledTabs = withStyles((theme) => {
  return {
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      '& > span': {
        maxWidth: 64,
        width: '100%',
        backgroundColor: theme.palette.secondary.main,
      },
    },
  };
})((props) => <TabsMui {...props} TabIndicatorProps={{ children: <span /> }} />);

function Tabs({ ...others }) {
  return (
    <StyledTabs
      aria-label="navigation tabs"
      indicatorColor="secondary"
      scrollButtons="auto"
      textColor="primary"
      variant="scrollable"
      {...others}
    />
  );
}

Tabs.Tab = Tab;

Tabs.propTypes = {
  ...TabsMui.propTypes,
};

export default Tabs;
