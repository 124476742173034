import { useMutation } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { datadogLogs } from '@datadog/browser-logs';
import * as yup from 'yup';

import {
  getAuthToken,
  removeStoreBackupKey,
  removeStoreDratfKey,
  setAuthToken,
  setCookieUser,
  setOrganizationUser,
  setStoreDraftUser,
  setUserCurrentStore,
  setUserStoresStoreKey,
} from 'utils/auth';

import { logEventRequest, setUserPropertyRequest } from 'providers/root/AmplitudeProvider/helpers';

import { useQueryParams } from 'hooks';

import { authRefreshToken, authSignIn } from './services';
import { AuthForm, AuthPayload } from './types';

export function useAuthSignIn() {
  const { queryParams } = useQueryParams<{ continue?: string }>();
  const history = useHistory();
  return useMutation({
    retry: 1,
    mutationFn: (payload: AuthPayload) => authSignIn(payload),
    onSuccess: async (data, variables) => {
      const { session, profile, stores, organization, storeDraft } = data;
      const { user } = variables;

      removeStoreDratfKey();
      removeStoreBackupKey();

      if (!stores?.length) {
        return history.push({
          pathname: `/without-store`,
        });
      }

      setAuthToken({
        accessToken: session.accessToken,
        refreshToken: session.refreshToken,
      });

      const auth = getAuth();
      try {
        await signInWithCustomToken(auth, session?.firebaseToken ?? '');
      } catch (error) {
        datadogLogs.logger.error('firebase_auth', { error });
      }

      const storesTmp = stores?.map((store) => ({ ...store, isDraft: false }));

      setUserCurrentStore(storesTmp[0]);

      if (storeDraft) {
        setStoreDraftUser({ ...storeDraft, isDraft: true });
      }

      setUserStoresStoreKey(storesTmp);

      setCookieUser(profile);

      logEventRequest('@event.$login.login');
      setUserPropertyRequest({ email: user });
      setOrganizationUser(organization);

      const validContinueParamater = queryParams.continue !== '/permissions-denied' && queryParams.continue !== '%0';

      if (queryParams.continue && validContinueParamater) {
        return window.location.replace(queryParams.continue);
      }

      return window.location.replace('/reports/resume');
    },
    onError(_error, { user }) {
      logEventRequest('@event.$login.loginFail');
      setUserPropertyRequest({ email: user });
    },
  });
}

export function useAuthRefreshToken() {
  const refresh = getAuthToken()?.refreshToken;

  return useMutation({
    mutationFn: () => authRefreshToken({ refresh }),
    onSuccess: (data) => {
      if (!data) return;

      setAuthToken({
        accessToken: data.access,
        refreshToken: data.refresh,
      });
    },
  });
}

export function useResolver() {
  const { t } = useTranslation('signIn');

  const schema = yup.object().shape({
    usernameField: yup.string().email().trim().lowercase().required(t('signIn:form.fieldErrors.emailTextFieldError')),
    passwordField: yup.string().required(t('signIn:form.fieldErrors.passwordTextFieldError')),
    termsAndConditionsField: yup.boolean().oneOf([true]),
  });

  const resolver = yupResolver<AuthForm>(schema);

  return resolver;
}
