import produce from 'immer';

import { paginationConfigDefaults } from 'utils/reports';
import { sortingDirection } from 'utils/reports/tables';

import {
  getReports,
  getReportsByPagination,
  exportReportsToExcel,
  getReportsSessions,
  getReportsSessionsDetails,
  exportReportsToExcelDetails,
  getReportsSessionsDetailsPagination,
  getReportsProductDetails,
  getPaginationProductDetails,
  getReportsSessionsPagination,
  getReportsSessionsDetailsAll,
  getReportsBySorting,
} from './actions';

const initialState = {
  reportsList: {},
  reportDetailsList: {},
};

export const createInitialReportDetailsState = {
  reportsDetails: {
    error: null,
    fetching: false,
    fetched: false,
    report: {},
  },

  pagination: {
    page: paginationConfigDefaults.page,
    page_size: paginationConfigDefaults.pageSize,
    count: 0,
    next: null,
    previous: null,
  },

  exportReportToExcel: {
    error: null,
    fetching: false,
    fetched: false,
    document: null,
  },

  paginationStatus: {
    error: null,
    fetching: false,
    fetched: false,
    lastUpdatedRows: {},
  },

  reportsDetailsAll: {
    error: null,
    fetching: false,
    fetched: false,
    details: {},
  },
};

export const createInitialReportState = {
  error: null,
  fetching: false,
  fetched: false,
  reports: {},
  pagination: {
    page: paginationConfigDefaults.page,
    page_size: paginationConfigDefaults.pageSize,
    count: 0,
    next: null,
    previous: null,
  },

  productDetailsSales: {
    error: null,
    fetching: false,
    fetched: false,
    product: {},
    pagination: { page: 1 },
  },

  paginationProductDetails: {
    error: null,
    fetching: false,
    fetched: false,
  },

  loadPaginationStatus: {
    error: null,
    fetching: false,
    fetched: false,
    lastUpdatedRows: {},
  },

  sortingStatus: {
    error: null,
    fetching: false,
    fetched: false,
    columnSelected: 0,
    columnDirection: sortingDirection.ASC,
    lastSortingRows: {},
  },

  exportReportsToExcel: {
    error: null,
    fetching: false,
    fetched: false,
    document: null,
  },

  cashRegisterSessions: {
    error: null,
    fetching: false,
    fetched: false,
    sessions: [],
  },

  paginationCashRegisterSessions: {
    page: paginationConfigDefaults.page,
    page_size: paginationConfigDefaults.pageSize,
    count: 0,
    next: null,
    previous: null,
  },

  paginationStatusCashRegisterSessions: {
    error: null,
    fetching: false,
    fetched: false,
    lastUpdatedRows: {},
  },
};

function updateStateForReports(state, key, reportsState) {
  let newReportsState = reportsState;

  const currentReportsState = state.reportsList[key];

  if (currentReportsState) {
    newReportsState = { ...currentReportsState, ...reportsState };
  } else {
    newReportsState = { ...createInitialReportState, ...reportsState };
  }

  const nextState = produce(state, (draftState) => {
    draftState.reportsList = {
      ...draftState.reportsList,
      [key]: newReportsState,
    };
  });

  return {
    ...state,
    ...nextState,
  };
}

function updateStateForReportDetails(state, key, reportsState) {
  let newReportsState = reportsState;

  const currentReportsState = state.reportDetailsList[key];

  if (currentReportsState) {
    newReportsState = { ...currentReportsState, ...reportsState };
  } else {
    newReportsState = { ...createInitialReportDetailsState, ...reportsState };
  }

  const nextState = produce(state, (draftState) => {
    draftState.reportDetailsList = {
      ...draftState.reportDetailsList,
      [key]: newReportsState,
    };
  });

  return {
    ...state,
    ...nextState,
  };
}

const reportsProviderReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getReports.REQUEST:
      return updateStateForReports(state, payload.key, {
        ...createInitialReportState,
        fetching: true,
      });

    case getReports.SUCCESS:
      return updateStateForReports(state, payload.key, {
        reports: payload.reports,
        pagination: { ...createInitialReportState.pagination, ...payload.pagination },
        fetching: false,
        fetched: true,
      });

    case getReports.FAILURE:
      return updateStateForReports(state, payload.key, {
        error: payload.error,
        fetching: false,
      });

    case getReports.RESET:
      return updateStateForReports(state, payload.key, { ...createInitialReportState });

    case getReportsProductDetails.REQUEST: {
      const productTmp = payload?.orderBy
        ? { ...state.reportsList[payload?.key]?.productDetailsSales.product, headers: null, rows: null }
        : {};

      return updateStateForReports(state, payload.key, {
        productDetailsSales: {
          ...createInitialReportState.productDetailsSales,
          product: productTmp,
          fetching: true,
        },
      });
    }

    case getReportsProductDetails.SUCCESS: {
      return updateStateForReports(state, payload.key, {
        productDetailsSales: {
          ...createInitialReportState.productDetailsSales,
          fetching: false,
          fetched: true,
          product: payload.product,
          pagination: payload.pagination,
        },
      });
    }

    case getReportsProductDetails.FAILURE: {
      return updateStateForReports(state, payload.key, {
        productDetailsSales: {
          error: payload.error,
          fetching: false,
        },
      });
    }

    case getReportsProductDetails.RESET: {
      return updateStateForReports(state, payload, {
        productDetailsSales: {
          ...createInitialReportState.productDetailsSales,
        },
      });
    }

    case getPaginationProductDetails.REQUEST: {
      return updateStateForReports(state, payload.key, {
        paginationProductDetails: {
          ...createInitialReportState.paginationProductDetails,
          fetching: true,
        },
      });
    }

    case getPaginationProductDetails.SUCCESS: {
      const currentProductDetailsSales = state.reportsList[payload?.key]?.productDetailsSales;

      return updateStateForReports(state, payload.key, {
        productDetailsSales: {
          ...createInitialReportState.productDetailsSales,
          product: {
            ...currentProductDetailsSales?.product,
            rows: [...currentProductDetailsSales?.product?.rows, ...payload?.product?.rows],
          },
          pagination: payload?.pagination,
        },
        paginationProductDetails: {
          ...createInitialReportState.paginationProductDetails,
          fetched: true,
        },
      });
    }

    case getPaginationProductDetails.FAILURE: {
      return updateStateForReports(state, payload.key, {
        paginationProductDetails: {
          ...createInitialReportState.paginationProductDetails,
          error: payload.error,
        },
      });
    }

    case getPaginationProductDetails.RESET: {
      return updateStateForReports(state, payload.key, {
        paginationProductDetails: {
          ...createInitialReportState.paginationProductDetails,
        },
      });
    }

    case getReportsByPagination.REQUEST: {
      const currentReportsPaginationState = state.reportsList[payload.key];

      return updateStateForReports(state, payload.key, {
        pagination: { ...currentReportsPaginationState?.pagination, page: payload.page },
        loadPaginationStatus: {
          ...createInitialReportState.loadPaginationStatus,
          fetching: true,
        },
      });
    }

    case getReportsByPagination.SUCCESS: {
      const currentReportsState = state.reportsList[payload.key];
      let newsRows = [...currentReportsState?.reports?.rows];
      newsRows = [...newsRows, ...payload.reports?.rows];

      return updateStateForReports(state, payload.key, {
        reports: { ...currentReportsState?.reports, rows: [...newsRows] },
        pagination: { ...currentReportsState?.pagination, ...payload.pagination },
        loadPaginationStatus: {
          fetching: false,
          fetched: true,
          lastUpdatedRows: payload.reports?.rows,
        },
      });
    }

    case getReportsByPagination.FAILURE:
      return updateStateForReports(state, payload.key, {
        loadPaginationStatus: {
          error: payload.error,
          fetching: false,
        },
      });

    case getReportsByPagination.RESET:
      return updateStateForReports(state, payload.key, {
        loadPaginationStatus: {
          ...createInitialReportState.loadPaginationStatus,
        },
      });

    case exportReportsToExcel.REQUEST: {
      return updateStateForReports(state, payload.key, {
        exportReportsToExcel: {
          ...createInitialReportState.exportReportsToExcel,
          fetching: true,
        },
      });
    }

    case exportReportsToExcel.SUCCESS: {
      return updateStateForReports(state, payload.key, {
        exportReportsToExcel: {
          fetching: false,
          fetched: true,
          document: payload.document,
        },
      });
    }

    case exportReportsToExcel.FAILURE:
      return updateStateForReports(state, payload.key, {
        exportReportsToExcel: {
          error: payload.error,
          fetching: false,
        },
      });

    case exportReportsToExcel.RESET:
      return updateStateForReports(state, payload.key, {
        exportReportsToExcel: {
          ...createInitialReportState.exportReportsToExcel,
        },
      });

    case getReportsSessions.REQUEST:
      return updateStateForReports(state, payload.key, {
        cashRegisterSessions: {
          ...createInitialReportState.cashRegisterSessions,
          fetching: true,
        },
      });

    case getReportsSessions.SUCCESS:
      return updateStateForReports(state, payload.key, {
        cashRegisterSessions: {
          sessions: payload.sessions,
          fetching: false,
          fetched: true,
        },

        paginationCashRegisterSessions: {
          ...createInitialReportState.paginationCashRegisterSessions,
          ...payload.pagination,
        },
      });

    case getReportsSessions.FAILURE:
      return updateStateForReports(state, payload.key, {
        cashRegisterSessions: {
          error: payload.error,
          fetching: false,
        },
      });

    case getReportsSessions.RESET:
      return updateStateForReports(state, payload.key, {
        cashRegisterSessions: {
          ...createInitialReportState.cashRegisterSessions,
        },
      });

    case getReportsSessionsPagination.REQUEST: {
      const currentPaginationState = state.reportsList[payload.key];

      return updateStateForReports(state, payload.key, {
        paginationCashRegisterSessions: {
          ...currentPaginationState?.paginationCashRegisterSessions,
          page: payload.page,
        },
        paginationStatusCashRegisterSessions: {
          ...createInitialReportState.paginationStatusCashRegisterSessions,
          fetching: true,
        },
      });
    }

    case getReportsSessionsPagination.SUCCESS: {
      const currentState = state.reportsList[payload.key];
      let newsSessions = [...currentState?.cashRegisterSessions?.sessions];
      newsSessions = [...newsSessions, ...payload.sessions];

      return updateStateForReports(state, payload.key, {
        reports: { ...currentState?.reports, rows: [...newsSessions] },
        paginationCashRegisterSessions: { ...currentState?.paginationCashRegisterSessions, ...payload.pagination },
        paginationStatusCashRegisterSessions: {
          fetching: false,
          fetched: true,
          lastUpdatedRows: payload.sessions,
        },
      });
    }

    case getReportsSessionsPagination.FAILURE:
      return updateStateForReports(state, payload.key, {
        paginationStatusCashRegisterSessions: {
          error: payload.error,
          fetching: false,
        },
      });

    case getReportsSessionsPagination.RESET:
      return updateStateForReports(state, payload.key, {
        paginationStatusCashRegisterSessions: {
          ...createInitialReportState.paginationStatusCashRegisterSessions,
        },
      });

    case getReportsSessionsDetails.REQUEST:
      return updateStateForReportDetails(state, payload.key, {
        reportsDetails: {
          ...createInitialReportDetailsState.reportsDetails,
          fetching: true,
        },
      });

    case getReportsSessionsDetails.SUCCESS:
      return updateStateForReportDetails(state, payload.key, {
        reportsDetails: {
          report: payload.report,
          fetching: false,
          fetched: true,
        },
        pagination: { ...createInitialReportDetailsState.pagination, ...payload.pagination },
      });

    case getReportsSessionsDetails.FAILURE:
      return updateStateForReportDetails(state, payload.key, {
        reportsDetails: {
          error: payload.error,
          fetching: false,
        },
      });

    case getReportsSessionsDetails.RESET:
      return updateStateForReportDetails(state, payload.key, {
        reportsDetails: {
          ...updateStateForReportDetails.reportsDetails,
        },
        pagination: { ...createInitialReportDetailsState.pagination },
      });

    case exportReportsToExcelDetails.REQUEST:
      return updateStateForReportDetails(state, payload.key, {
        exportReportToExcel: {
          ...createInitialReportDetailsState.exportReportToExcel,
          fetching: true,
        },
      });

    case exportReportsToExcelDetails.SUCCESS:
      return updateStateForReportDetails(state, payload.key, {
        exportReportToExcel: {
          fetching: false,
          fetched: true,
          document: payload.document,
        },
      });

    case exportReportsToExcelDetails.FAILURE:
      return updateStateForReportDetails(state, payload.key, {
        exportReportToExcel: {
          error: payload.error,
          fetching: false,
        },
      });

    case exportReportsToExcelDetails.RESET:
      return updateStateForReportDetails(state, payload.key, {
        exportReportToExcel: {
          ...createInitialReportDetailsState.exportReportToExcel,
        },
      });

    case getReportsSessionsDetailsPagination.REQUEST: {
      const currentPaginationState = state.reportDetailsList[payload.key];

      return updateStateForReportDetails(state, payload.key, {
        pagination: { ...currentPaginationState?.pagination, page: payload.page },

        paginationStatus: {
          ...createInitialReportDetailsState?.paginationStatus,
          fetching: true,
        },
      });
    }

    case getReportsSessionsDetailsPagination.SUCCESS: {
      const currentReportDetailsState = state.reportDetailsList[payload.key];
      let newsRows = [...currentReportDetailsState?.reportsDetails?.report?.rows];
      newsRows = [...newsRows, ...payload.report?.rows];

      return updateStateForReportDetails(state, payload.key, {
        reportsDetails: {
          report: { ...currentReportDetailsState?.reportsDetails?.report, rows: [...newsRows] },
        },

        pagination: { ...currentReportDetailsState?.pagination, ...payload.pagination },

        paginationStatus: {
          fetching: false,
          fetched: true,
          lastUpdatedRows: payload.report?.rows,
        },
      });
    }

    case getReportsSessionsDetailsPagination.FAILURE:
      return updateStateForReportDetails(state, payload.key, {
        paginationStatus: {
          error: payload.error,
          fetching: false,
        },
      });

    case getReportsSessionsDetailsPagination.RESET:
      return updateStateForReportDetails(state, payload.key, {
        paginationStatus: {
          ...createInitialReportDetailsState?.paginationStatus,
        },
      });

    case getReportsSessionsDetailsAll.REQUEST:
      return updateStateForReportDetails(state, payload.key, {
        reportsDetailsAll: {
          ...createInitialReportDetailsState.reportsDetailsAll,
          fetching: true,
        },
      });

    case getReportsSessionsDetailsAll.SUCCESS:
      return updateStateForReportDetails(state, payload.key, {
        reportsDetailsAll: {
          details: payload.data,
          fetching: false,
          fetched: true,
        },
      });

    case getReportsSessionsDetailsAll.FAILURE:
      return updateStateForReportDetails(state, payload.key, {
        reportsDetailsAll: {
          error: payload.error,
          fetching: false,
        },
      });

    case getReportsSessionsDetailsAll.RESET:
      return updateStateForReportDetails(state, payload.key, {
        reportsDetailsAll: {
          ...updateStateForReportDetails.reportsDetailsAll,
        },
      });

    // sorting
    case getReportsBySorting.REQUEST: {
      const currentReportsSortingState = state.reportsList[payload.key];

      return updateStateForReports(state, payload.key, {
        ...currentReportsSortingState,
        pagination: {
          ...createInitialReportState.pagination,
        },
        sortingStatus: {
          ...currentReportsSortingState.sortingStatus,
          fetching: true,
          fetched: false,
          error: null,
        },
      });
    }

    case getReportsBySorting.SUCCESS: {
      const currentReportsState = state.reportsList[payload.key];
      return updateStateForReports(state, payload.key, {
        ...currentReportsState,
        reports: {
          ...currentReportsState?.reports,
          rows: payload.reports?.rows,
        },
        pagination: { ...currentReportsState.pagination, ...payload.pagination },
        sortingStatus: {
          ...currentReportsState.sortingStatus,
          fetching: false,
          fetched: true,
          lastSortingRows: payload.reports?.rows,
        },
      });
    }

    case getReportsBySorting.SORTING: {
      const currentReportsState = state.reportsList[payload.key];
      return updateStateForReports(state, payload.key, {
        ...currentReportsState,
        sortingStatus: {
          ...currentReportsState.sortingStatus,
          fetched: false,
          columnSelected: payload.selected,
          columnDirection: payload.direction,
        },
      });
    }
    case getReportsBySorting.RESET_SORTING: {
      const currentReportsState = state.reportsList[payload.key];
      return updateStateForReports(state, payload.key, {
        ...currentReportsState,
        sortingStatus: {
          ...createInitialReportState.sortingStatus,
        },
      });
    }

    case getReportsBySorting.FAILURE: {
      const currentReportsState = state.reportsList[payload.key];
      return updateStateForReports(state, payload.key, {
        ...currentReportsState,
        sortingStatus: {
          ...currentReportsState.sortingStatus,
          error: payload.error,
          fetching: false,
          fetched: false,
        },
      });
    }

    case getReportsBySorting.RESET:
      return updateStateForReports(state, payload.key, {
        ...createInitialReportState,
        sortingStatus: {
          ...createInitialReportState.sortingStatus,
        },
      });

    default:
      return state;
  }
};

export default reportsProviderReducer;
