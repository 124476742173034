/* eslint-disable no-unused-vars */
import produce from 'immer';

import { initSignIn } from './actions';

export const initialState = {
  error: null,
  fetching: false,
  authenticated: false,
  profile: {},
  stores: [],
};

const signInProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case initSignIn.REQUEST:
        draft.fetching = true;
        draft.error = false;
        draft.authenticated = false;
        break;

      case initSignIn.SUCCESS:
        draft.fetching = false;
        draft.authenticated = true;
        draft.profile = payload.profile;
        draft.stores = payload.stores;
        break;

      case initSignIn.FAILURE:
        draft.error = true;
        draft.fetching = false;
        draft.authenticated = false;
        break;

      case initSignIn.RESET:
        draft.error = initialState.error;
        draft.fetching = initialState.fetching;
        draft.authenticated = initialState.authenticated;
        draft.profile = initialState.profile;
        draft.stores = initialState.stores;

        break;

      default:
        return draft;
    }
  });

export default signInProviderReducer;
