/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { stringify } from 'query-string';

import Box from '@material-ui/core/Box';

import Layout, { ContentLayout } from 'Layout';

import { withKitchenAreas } from 'providers/settings/KitchenAreas';
import { withRequiredLicense } from 'providers/root/AuthProvider';
import { withProducts } from 'providers/menuMaker/Products';
import { withCategories } from 'providers/menuMaker/Categories';
import { withProductDetails } from 'providers/menuMaker/ProductDetails';
import { withMultiTaxes } from 'providers/settings/MultiTaxes';
import { useGetCategoryCatalog } from 'providers/menuMaker/Categories/hooks';
import { useGetModifierGroupsCatalog } from 'providers/menuMaker/ModifierGroups/hooks';
import { useSidebarLayout } from 'providers/root/SidebarLayoutProvider';
import useAmplitude from 'providers/root/AmplitudeProvider/useAmplitude';

import useNotification from '@design-system/Notification/useNotification';
import PageError from 'components/PageError';
import useUserStore from 'hooks/useUserStore';
import MenuMakerTabsNavigation from 'components/MenuMakerTabsNavigation';
import HeaderProductsFilters from 'components/HeaderProductsFilters';
import ProductDrawer from 'components/ProductDrawer';

import useSortingProducts from 'hooks/useSortingProducts';

import { statusType } from 'utils/products';
import { getEnabledKitchenAreas } from 'utils/kitchenAreas/makeKitchenAreasFields';

import {
  drawerActions,
  drawerActionList,
  drawerEntities,
  drawerEntityList,
  getQueryParamsMenuMaker,
} from 'utils/drawerMenuMaker';

import ProductsTableWrapper from './ProductsTableWrapper';
import useStyles from './styles';

export function Products({
  loadProducts,
  products,
  categories,
  productsFetching,
  productsFetched,
  productsError,
  resetProducts,
  createProduct,
  createProductObject,
  createProductFetching,
  createProductFetched,
  createProductError,
  resetCreateProduct,
  handleToggleProductAvailability,
  toggleProductAvailabilityFetching,
  toggleProductAvailabilityFetched,
  toggleProductAvailabilityError,
  resetToggleProductAvailability,
  resetDeleteProduct,
  updateProductImage,
  updateProductImageFetching,
  updateProductImageFetched,
  updateProductImageError,
  resetUpdateProductImage,
  loadProductsScrollPagination,
  productsPaginationInfo,
  loadProductsPaginationScrollState,
  loadKitchenAreas,
  kitchenAreas,
  kitchenAreasState,
  resetKitchenAreas,
  loadGetProduct,
  productDetailsObject,
  productDetailsState,
  resetProductDetails,
  editProduct,
  productEditObject,
  productEditState,
  resetEditProduct,
  duplicateProduct,
  duplicateProductState,
  resetDuplicateProduct,
  loadMultiTaxes,
  multiTaxesWithNoOption,
  multiTaxesState,
  resetMultiTaxes,
  t,
}) {
  const { modeDraft } = useSidebarLayout();
  const history = useHistory();
  const location = useLocation();
  const [typeOfProductAvailability, setTypeOfProductAvailability] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedProductUuid, setSelectedProductUuid] = useState(null);
  const classes = useStyles({ openDrawer, isModeDraft: modeDraft });

  const {
    storeId,
    storeState: { selected: storeSelected },
  } = useUserStore();
  const { setSortingByFilters, filtersSorting, resetSorting } = useSortingProducts();
  const { closeAllSnackbar, setNotification } = useNotification();
  const { loadCategoryCatalog, categories: categoriesList, categoriesState, resetCategories } = useGetCategoryCatalog(
    storeId,
  );
  const { modifierGroups, modifierGroupsState, loadModifierGroups, resetModifierGroups } = useGetModifierGroupsCatalog(
    storeId,
  );
  const { logEvent } = useAmplitude();

  const { action, entity, uuid } = getQueryParamsMenuMaker(location);
  const isEdition = action === drawerActions.EDIT;

  useEffect(() => {
    logEvent('@event.$menuMaker.viewMenuMaker', '@@section.products');
    setTimeout(() => {
      loadProducts({ page: 1 });
    }, 0);
  }, []);

  useEffect(() => {
    const isValid = drawerActionList.includes(action) && drawerEntityList.includes(entity);

    setOpenDrawer(Boolean(isEdition ? isValid && uuid : isValid));
  }, [action, entity, uuid, isEdition]);

  useEffect(() => {
    if (createProductFetched) {
      logEvent('@event.$menuMaker.menuMaker', ['@@section.products', '@@type.new', '@@item.products'], {
        itemId: createProductObject?.uuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });
      loadProducts({ ...filtersSorting, isSorting: true, page: 1 });
      setNotification(
        t('menuMaker:productForm.messages.saveSuccess', {
          productName: createProductObject?.name,
        }),
        { variant: 'success' },
      );
      history.replace({ search: '' });
      resetCreateProduct();
    }

    if (createProductError) {
      setNotification(t('menuMaker:productForm.messages.saveError'), { variant: 'error' });
      resetCreateProduct();
    }
  }, [createProductFetched, createProductError, history, setNotification, t]);

  useEffect(() => {
    if (updateProductImageFetched) {
      setNotification(t('menuMaker:productUpdateImage.updateImageSuccess'), { variant: 'success' });
    }

    if (updateProductImageError) {
      setNotification(t('menuMaker:productUpdateImage.updateImageError'), { variant: 'error' });
    }
  }, [setNotification, t, updateProductImageError, updateProductImageFetched]);

  useEffect(() => {
    if (toggleProductAvailabilityFetched && typeOfProductAvailability === statusType.STATUS_UNAVAILABLE) {
      setNotification(t('menuMaker:messages.inStockHidedSuccess'), { variant: 'success' });
    } else if (toggleProductAvailabilityFetched && typeOfProductAvailability === statusType.STATUS_AVAILABLE) {
      setNotification(t('menuMaker:messages.inStockIncludedSuccess'), { variant: 'success' });
    }

    if (toggleProductAvailabilityFetched || toggleProductAvailabilityError) {
      resetToggleProductAvailability();
    }
  }, [
    toggleProductAvailabilityFetched,
    toggleProductAvailabilityError,
    resetToggleProductAvailability,
    typeOfProductAvailability,
    setNotification,
    t,
  ]);

  useEffect(() => {
    if (productEditState.fetched) {
      logEvent('@event.$menuMaker.menuMaker', ['@@section.products', '@@type.edit', '@@item.products'], {
        itemId: productEditObject?.uuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });
      loadProducts({ ...filtersSorting, isSorting: true, page: 1 });
      setNotification(
        t('menuMaker:productForm.messages.editSuccess', {
          productName: productEditObject?.name,
        }),
        { variant: 'success' },
      );
      history.replace({ search: '' });
      resetEditProduct();
    }

    if (productEditState.error) {
      setNotification(t('menuMaker:productForm.messages.editError'), { variant: 'error' });
      resetEditProduct();
    }
  }, [productEditState, setNotification, t]);

  useEffect(() => {
    if (duplicateProductState.fetched) {
      logEvent('@event.$menuMaker.menuMaker', ['@@section.products', '@@type.duplicate', '@@item.products'], {
        itemId: selectedProductUuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });
      loadProducts({ ...filtersSorting, isSorting: true, page: 1 });
      setNotification(t('menuMaker:productForm.messages.duplicateSuccess'), { variant: 'success' });
      setSelectedProductUuid(null);
      resetDuplicateProduct();
    }
    if (duplicateProductState.error) {
      setNotification(t('menuMaker:productForm.messages.duplicateError'), { variant: 'error' });
      resetDuplicateProduct();
    }
  }, [duplicateProductState]);

  useEffect(() => {
    return function cleanup() {
      resetProducts();
      resetCreateProduct();
      resetDeleteProduct();
      resetToggleProductAvailability();
      resetUpdateProductImage();
      resetEditProduct();
      resetDuplicateProduct();
      resetSorting();
      closeAllSnackbar();
    };
  }, [
    resetProducts,
    resetCreateProduct,
    resetDeleteProduct,
    resetToggleProductAvailability,
    resetUpdateProductImage,
    resetSorting,
    closeAllSnackbar,
  ]);

  function handleSortingByTextProducts(values) {
    const { filterByText } = values;

    if (filterByText?.length >= 3) {
      logEvent('@event.$menuMaker.menuMakerFilters', '@@section.products', { search: filterByText });
      setSortingByFilters({ ...values });
    }

    if (!filterByText) {
      setSortingByFilters({ ...values });
    }
  }

  function handleSortingByCategories(values) {
    setSortingByFilters({ ...values });
  }

  function handelSortingByTypeSold(values) {
    setSortingByFilters({ ...values });
  }

  function handleSortingByBrands(values) {
    setSortingByFilters({ ...values });
  }

  function handleSortingOrderBy(values) {
    setSortingByFilters({ ...values });
  }

  function handleOpenNewProduct() {
    const query = { action: drawerActions.CREATE, entity: drawerEntities.PRODUCT };

    history.replace({ search: stringify(query) });
  }

  function handleLoadProducts() {
    loadProducts({ page: 1 });
  }
  const handlePaginationProducts = useCallback(() => {
    const currentPage = productsPaginationInfo.page + 1;

    if (!loadProductsPaginationScrollState.fetching && currentPage !== productsPaginationInfo?.page) {
      loadProductsScrollPagination({ ...filtersSorting, page: currentPage });
    }
  }, [
    loadProductsPaginationScrollState.fetching,
    loadProductsScrollPagination,
    productsPaginationInfo.page,
    filtersSorting,
  ]);

  function hanldeUpdateProductImage(productUuid, image) {
    updateProductImage({ storeUuid: storeId, productUuid, image });
  }

  function handleToggleProductStock(productParams) {
    setTypeOfProductAvailability(productParams.availability);
    handleToggleProductAvailability(productParams);
  }

  function handleDuplicateProduct(productUuid) {
    setSelectedProductUuid(productUuid);
    duplicateProduct({ storeUuid: storeId, productUuid });
  }

  function handleLoadCategories() {
    loadCategoryCatalog();
  }

  function handleLoadModifierGroups() {
    loadModifierGroups();
  }

  const handleLoadProductDetails = () => {
    loadGetProduct(storeId, uuid);
  };

  const handleSubmitProduct = (data) => {
    if (isEdition) {
      editProduct({ storeUuid: storeId, product: { uuid, ...data } });
    } else {
      createProduct({ storeUuid: storeId, product: { ...data } });
    }
  };

  return (
    <Layout>
      <ContentLayout>
        <ContentLayout.Header className={classes.headerLayout} sticky>
          <MenuMakerTabsNavigation />
        </ContentLayout.Header>

        <ContentLayout.Container pb="0" pl="0" pr="0">
          <Box className={classes.root}>
            <HeaderProductsFilters
              categoriesList={categories}
              onAddButtonClick={handleOpenNewProduct}
              onSearchBoxChange={handleSortingByTextProducts}
              onSelectBrandsChange={handleSortingByBrands}
              onSelectedCategoriesChange={handleSortingByCategories}
              onSelectedOrderBy={handleSortingOrderBy}
              onSelectTypeSoldProductChange={handelSortingByTypeSold}
              openDrawer={openDrawer}
              pt={4}
              px={4}
            />

            {productsError && (
              <PageError
                data-testid="menusError"
                id="menusError"
                labelAction={t('common:buttons.retry')}
                message={t('menuMaker:errors.loadedError')}
                onAction={handleLoadProducts}
              />
            )}

            {!productsError && (
              <Box mb={6}>
                <ProductsTableWrapper
                  categoriesByUuid={categories}
                  isLoading={productsFetching || (!products.length && !productsFetched)}
                  isSearchFilter={filtersSorting?.isSearchFilter}
                  loadingEnabledProduct={toggleProductAvailabilityFetching}
                  loadingUpdateProductImage={updateProductImageFetching}
                  onClearNotification={closeAllSnackbar}
                  onDuplicateProduct={handleDuplicateProduct}
                  onPaginate={handlePaginationProducts}
                  onUpdateProductImage={hanldeUpdateProductImage}
                  openDrawer={openDrawer}
                  paginateState={loadProductsPaginationScrollState}
                  paginateValues={productsPaginationInfo}
                  products={products}
                  toggleProductStock={handleToggleProductStock}
                />
              </Box>
            )}
          </Box>

          <ProductDrawer
            actionState={
              isEdition ? { ...productEditState } : { fetching: createProductFetching, fetched: createProductFetched }
            }
            categoriesState={{
              list: categoriesList,
              fetched: categoriesState.fetched,
              fetching: categoriesState.fetching,
            }}
            isEdition={isEdition}
            kitchenAreasState={{ list: getEnabledKitchenAreas(kitchenAreas), ...kitchenAreasState }}
            modifierGroupsState={{
              list: modifierGroups,
              fetched: modifierGroupsState.fetched,
              fetching: modifierGroupsState.fetching,
            }}
            multiTaxesState={{ list: multiTaxesWithNoOption, ...multiTaxesState }}
            onLoadCategories={handleLoadCategories}
            onLoadKitchenAreas={loadKitchenAreas}
            onLoadModifierGroups={handleLoadModifierGroups}
            onLoadMultiTaxes={loadMultiTaxes}
            onLoadProductDetails={handleLoadProductDetails}
            onResetCategories={resetCategories}
            onResetGetKitchenAreas={resetKitchenAreas}
            onResetModifierGroups={resetModifierGroups}
            onResetMultitaxes={resetMultiTaxes}
            onResetProductDetails={resetProductDetails}
            onSubmit={handleSubmitProduct}
            openDrawer={openDrawer}
            productState={{ data: productDetailsObject, ...productDetailsState }}
            uuidSelected={uuid}
          />
        </ContentLayout.Container>
      </ContentLayout>
    </Layout>
  );
}

Products.propTypes = {
  loadProducts: PropTypes.func,
  products: PropTypes.array,
  categories: PropTypes.object,
  productsFetching: PropTypes.bool,
  productsFetched: PropTypes.bool,
  productsError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  resetProducts: PropTypes.func,
  createProduct: PropTypes.func,
  createProductObject: PropTypes.object,
  createProductFetching: PropTypes.bool,
  createProductFetched: PropTypes.bool,
  resetCreateProduct: PropTypes.func,
  createProductError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  handleToggleProductAvailability: PropTypes.func,
  toggleProductAvailabilityFetching: PropTypes.bool,
  toggleProductAvailabilityFetched: PropTypes.bool,
  toggleProductAvailabilityError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  resetToggleProductAvailability: PropTypes.func,
  resetDeleteProduct: PropTypes.func,
  updateProductImage: PropTypes.func,
  updateProductImageFetching: PropTypes.bool,
  updateProductImageFetched: PropTypes.bool,
  updateProductImageError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  resetUpdateProductImage: PropTypes.func,
  loadProductsScrollPagination: PropTypes.func,
  productsPaginationInfo: PropTypes.object,
  loadProductsPaginationScrollState: PropTypes.object,
  loadKitchenAreas: PropTypes.func,
  kitchenAreas: PropTypes.array,
  kitchenAreasState: PropTypes.object,
  resetKitchenAreas: PropTypes.func,
  loadGetProduct: PropTypes.func,
  productDetailsObject: PropTypes.object,
  productDetailsState: PropTypes.object,
  resetProductDetails: PropTypes.func,
  editProduct: PropTypes.func,
  productEditObject: PropTypes.object,
  productEditState: PropTypes.object,
  resetEditProduct: PropTypes.func,
  duplicateProduct: PropTypes.func,
  duplicateProductState: PropTypes.object,
  resetDuplicateProduct: PropTypes.func,
  loadMultiTaxes: PropTypes.func,
  multiTaxesWithNoOption: PropTypes.array,
  multiTaxesState: PropTypes.object,
  resetMultiTaxes: PropTypes.func,
  t: PropTypes.func,
};

export default compose(
  memo,
  withRequiredLicense(),
  withProductDetails,
  withCategories,
  withProducts,
  withKitchenAreas,
  withMultiTaxes,
  withTranslation(['menuMaker', 'kitchenAreas', 'settingsTaxes']),
)(Products);
