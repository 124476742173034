import queryString from 'query-string';

import request from 'utils/request';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getReportsProductsSalesRequest(
  storeUuid,
  { reportFormat, startDate, endDate, brands, providers, orderTypes, categories, withoutCategories, page, orderBy },
  options = {},
) {
  const query = queryString.stringify({
    report_format: reportFormat,
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
    page,
    page_size: 50,
    order_by: orderBy,
  });

  return request(`${API_BASE_URL}/v1/reports/sales/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      brands,
      providers,
      order_types: orderTypes,
      categories,
      without_categories: withoutCategories,
    }),
    ...options,
  });
}

export function exportReportsToExcelRequest(
  storeUuid,
  { startDate, endDate, brands, providers, orderTypes, categories, withoutCategories },
  options = {},
) {
  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
  });

  return request(`${API_BASE_URL}/v1/reports/sales/export/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      brands,
      providers,
      order_types: orderTypes,
      categories,
      without_categories: withoutCategories,
    }),
    config: {
      responseType: 'blob',
    },
    ...options,
  });
}

export function getReportsProductDetailSalesRequest(
  storeUuid,
  {
    productUuid,
    itemType,
    startDate,
    endDate,
    brands,
    providers,
    orderTypes,
    categories,
    withoutCategories,
    orderBy,
    page,
  },
  options = {},
) {
  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
    item_type: itemType?.toUpperCase(),
    page,
    page_size: 50,
    order_by: orderBy ?? undefined,
  });

  return request(`${API_BASE_URL}/v1/reports/sales/${productUuid}/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      brands,
      providers,
      categories,
      order_Types: orderTypes,
      without_categories: withoutCategories,
    }),
    ...options,
  });
}
