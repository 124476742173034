import { useState, useEffect } from 'react';
import clsx from 'clsx';

import {
  Box,
  withStyles,
  makeStyles,
  FormControl,
  MenuProps,
  MenuItem as MenuItemMUI,
  ListItemText,
  Select,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';

import { Checkbox, TextField } from '@design-system';
import IconChevronDown from '@experimental-components/IconsComponents/ChevronDown';
import Divider from '@design-system/Divider';
import { useTranslation } from 'react-i18next';

export const SelectRoot = withStyles((theme) => ({
  select: {
    borderRadius: theme.shape.borderRadius,
    padding: '0 1rem',
    display: 'flex',
    alignItems: 'center',
    maxHeight: '56px',
    paddingRight: '32px !important',
  },

  filled: {
    backgroundColor: theme.palette.darkGrey[2],
    borderRadius: theme.shape.borderRadius,

    '&:hover': {
      borderRadius: theme.shape.borderRadius,
    },

    '&:focus': {
      borderRadius: theme.shape.borderRadius,
    },
  },
  disabled: {
    backgroundColor: theme.palette.darkGrey[2],
  },
}))(Select);

export const MenuItem = withStyles(() => ({
  root: {
    maxWidth: '600px',
    whiteSpace: 'normal',
  },
}))(MenuItemMUI);

export const InputLabelRoot = withStyles((theme) => ({
  root: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.common.black,
  },

  shrink: {
    transform: 'translate(0, 1.5px) scale(1)',
    transformOrigin: 'top left',
  },

  disabled: {
    color: theme.palette.darkGrey[50],
  },
}))(InputLabel);

export const FormHelperTextRoot = withStyles((theme) => ({
  root: {
    color: theme.palette.darkGrey[50],
    fontSize: 16,
  },
}))(FormHelperText);

export const useStyles = makeStyles((theme) => ({
  iconRoot: {
    right: 16,
    position: 'absolute',
    top: 'calc(50% - 6px)',
  },

  menuList: {
    color: '#1E2025',
    fontSize: theme.typography.pxToRem(16),
    padding: 0,
  },

  menuPaper: {
    marginTop: theme.spacing(2),
    boxSizing: 'border-box',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #F2F2F4',
    boxShadow: '0px 8px 40px rgba(133, 133, 133, 0.2)',
  },

  contentSelected: {
    color: theme.palette.darkGrey.main,
    fontSize: theme.typography.pxToRem(14),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  removed: {
    textDecoration: 'line-through',
  },
}));

interface Props {
  id?: string;
  helperText?: string;
  hideLabelHelperText?: boolean;
  label: string;
  options?: {
    value: string;
    label: string;
    isRemoved?: boolean;
  }[];
  disabled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  required?: boolean;
  onChange?: (selectedValues: string[]) => void;
  placeholder?: string;
  allItemsSelectedPlaceholder?: string;
  selectAllPlaceholder?: string;
  optionsSelected?: string[];
}

function MultipleSelect({
  id,
  helperText,
  hideLabelHelperText,
  label,
  options = [],
  disabled,
  error,
  fullWidth = true,
  required,
  onChange,
  placeholder,
  allItemsSelectedPlaceholder,
  selectAllPlaceholder,
  optionsSelected = [],
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<string[]>(optionsSelected);

  const CHOOSE_OPTIONS_TEXT = placeholder || t('common:forms.placeholderDefault');
  const SELECT_ALL_TEXT = selectAllPlaceholder || t('common:forms.selectAllPlaceholder');
  const ALL_SELECTED_TEXT = allItemsSelectedPlaceholder || t('common:forms.allItemsSelectedPlaceholder');

  useEffect(() => {
    if (optionsSelected?.length) {
      setSelectedOptions(optionsSelected);
    }
  }, [optionsSelected]);

  const menuProps: Partial<MenuProps> = {
    classes: {
      list: classes.menuList,
      paper: classes.menuPaper,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  const handleOnChange = (values: string[]) => {
    if (values.at(-1) === 'all') {
      const list = options.map((item) => item.value);

      const listTmp = selectedOptions.length === options.length ? [] : list;
      setSelectedOptions(listTmp);
      if (onChange) onChange(listTmp);
      return;
    }

    setSelectedOptions(values);
    if (onChange) onChange(values);
  };

  function handleRenderValue(values: string[]) {
    let renderSelected: string | undefined = CHOOSE_OPTIONS_TEXT;

    if (values.length > 0)
      renderSelected = options
        .filter((item) => values.includes(item.value))
        .map((item) => item.label)
        .join(', ');

    if (selectedOptions.length === options.length) {
      renderSelected = ALL_SELECTED_TEXT;
    }

    return <span className={classes.contentSelected}>{renderSelected}</span>;
  }

  return (
    <FormControl disabled={disabled} error={error} fullWidth={fullWidth} required={required}>
      <InputLabelRoot htmlFor={id} shrink>
        {label}
      </InputLabelRoot>

      <SelectRoot
        disabled={disabled}
        displayEmpty
        IconComponent={(iconProps) => (
          <IconChevronDown className={clsx(iconProps.className, classes.iconRoot)} color="#000" />
        )}
        input={<TextField />}
        MenuProps={menuProps}
        multiple
        onChange={(e) => handleOnChange(e.target.value as string[])}
        renderValue={(selected) => handleRenderValue(selected as string[])}
        value={selectedOptions}
      >
        <MenuItem key="all" disabled={disabled} value="all">
          <Box display="flex" pb="5px" pt="9px">
            <Checkbox checked={selectedOptions.length === options.length} label="" />
            <ListItemText primary={SELECT_ALL_TEXT} />
          </Box>
        </MenuItem>

        <Divider />

        {options.map((option) => (
          <MenuItem key={option.value} disabled={disabled} value={option.value}>
            <Checkbox checked={selectedOptions.includes(option.value)} label="" />
            <ListItemText className={clsx({ [classes.removed]: option?.isRemoved })} primary={option.label} />
          </MenuItem>
        ))}
      </SelectRoot>

      {!hideLabelHelperText && <FormHelperTextRoot id={`${id}HelperText`}>{helperText}</FormHelperTextRoot>}
    </FormControl>
  );
}

export default MultipleSelect;
