import produce from 'immer';

import { DEFAULT_SIZE_PAGE, DEFAULT_PAGE, sortingOrderTypes, sortingPropertyTypes } from 'utils/diningOptions';

import {
  getDiningOptions,
  getDiningOptionDetail,
  createDiningOption,
  deleteDiningOption,
  editDiningOption,
  setIsEnabledDiningOption,
  setIsDefaultDiningOption,
} from './actions';

export const initialState = {
  getDiningOptions: {
    error: null,
    fetching: false,
    fetched: false,
    diningOptions: [],
    pagination: {
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_SIZE_PAGE,
      count: 0,
    },
  },

  sorting: {
    property: sortingPropertyTypes.NAME,
    filterByText: '',
    direction: sortingOrderTypes.ASC,
  },

  getDiningOptionDetail: {
    error: null,
    fetching: false,
    fetched: false,
    diningOption: {},
  },

  createDiningOption: {
    error: null,
    fetching: false,
    fetched: false,
    diningOption: {},
  },

  deleteDiningOption: {
    error: null,
    fetching: false,
    fetched: false,
  },

  editDiningOption: {
    error: null,
    fetching: false,
    fetched: false,
    diningOption: {},
  },

  setIsEnabledDiningOption: {
    error: null,
    fetching: false,
    fetched: false,
  },

  setIsDefaultDiningOption: {
    error: null,
    fetching: false,
    fetched: false,
  },
};

const restaurantDiningOptionsProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getDiningOptions.REQUEST:
        draft.getDiningOptions.fetching = true;
        draft.getDiningOptions.error = false;
        draft.getDiningOptions.fetched = false;
        draft.getDiningOptions.pagination = { ...initialState.getDiningOptions.pagination };
        draft.sorting = {
          ...initialState.sorting,
        };
        break;

      case getDiningOptions.SUCCESS:
        draft.getDiningOptions.fetching = false;
        draft.getDiningOptions.fetched = true;
        draft.getDiningOptions.diningOptions = payload.diningOptions;
        draft.getDiningOptions.pagination = {
          ...draft.getDiningOptions.pagination,
          ...payload.pagination,
        };
        break;

      case getDiningOptions.FAILURE:
        draft.getDiningOptions.error = payload;
        draft.getDiningOptions.fetching = false;
        draft.getDiningOptions.fetched = false;
        break;

      case getDiningOptions.SORTING:
        draft.getDiningOptions.pagination = {
          ...draft.getDiningOptions.pagination,
          ...initialState.getDiningOptions.pagination,
        };

        draft.sorting = {
          ...draft.sorting,
          ...payload,
        };
        break;

      case getDiningOptions.PAGINATION:
        draft.getDiningOptions.pagination = {
          ...draft.getDiningOptions.pagination,
          ...payload,
        };
        break;

      case getDiningOptions.RESET_PAGINATION:
        draft.getDiningOptions.pagination = { ...initialState.getDiningOptions.pagination };
        break;

      case getDiningOptions.RESET_SORTING:
        draft.sorting = {
          ...initialState.sorting,
        };
        break;

      case getDiningOptions.RESET:
        draft.getDiningOptions = { ...initialState.getDiningOptions };
        break;

      // dining option detail
      case getDiningOptionDetail.REQUEST:
        draft.getDiningOptionDetail = { ...initialState.getDiningOptionDetail, fetching: true };
        break;

      case getDiningOptionDetail.SUCCESS: {
        draft.getDiningOptionDetail.fetching = false;
        draft.getDiningOptionDetail.fetched = true;
        draft.getDiningOptionDetail.diningOption = payload;

        break;
      }

      case getDiningOptionDetail.FAILURE:
        draft.getDiningOptionDetail.error = payload;
        draft.getDiningOptionDetail.fetching = false;
        draft.getDiningOptionDetail.fetched = false;
        break;

      case getDiningOptionDetail.RESET:
        draft.getDiningOptionDetail = { ...initialState.getDiningOptionDetail };
        break;

      // create dining option
      case createDiningOption.REQUEST:
        draft.createDiningOption = { ...initialState.createDiningOption, fetching: true };
        break;

      case createDiningOption.SUCCESS: {
        const diningOptionsList = [...draft.getDiningOptions.diningOptions];
        diningOptionsList.unshift(payload);

        draft.createDiningOption.fetching = false;
        draft.createDiningOption.fetched = true;
        draft.createDiningOption.diningOption = payload;
        draft.getDiningOptions.diningOptions = diningOptionsList;

        break;
      }

      case createDiningOption.FAILURE:
        draft.createDiningOption.error = payload;
        draft.createDiningOption.fetching = false;
        draft.createDiningOption.fetched = false;
        break;

      case createDiningOption.RESET:
        draft.createDiningOption = { ...initialState.createDiningOption };
        break;

      // delete dining option
      case deleteDiningOption.REQUEST:
        draft.deleteDiningOption = { ...initialState.deleteDiningOption, fetching: true };
        break;

      case deleteDiningOption.SUCCESS: {
        const diningOptionsList = draft.getDiningOptions.diningOptions.filter(
          (diningOption) => diningOption.uuid !== payload,
        );

        draft.deleteDiningOption.fetching = false;
        draft.deleteDiningOption.fetched = true;
        draft.getDiningOptions.diningOptions = diningOptionsList;

        break;
      }

      case deleteDiningOption.FAILURE:
        draft.deleteDiningOption.error = payload;
        draft.deleteDiningOption.fetching = false;
        draft.deleteDiningOption.fetched = false;
        break;

      case deleteDiningOption.RESET:
        draft.deleteDiningOption = { ...initialState.deleteDiningOption };
        break;

      // edit dining option
      case editDiningOption.REQUEST:
        draft.editDiningOption = { ...initialState.editDiningOption, fetching: true };
        break;

      case editDiningOption.SUCCESS: {
        const diningOptionsList = draft.getDiningOptions.diningOptions.map((diningOption) => {
          return { ...diningOption };
        });
        diningOptionsList.forEach((diningOption, index) => {
          if (diningOption?.uuid === payload?.uuid) {
            diningOptionsList[index] = payload;
          }
        });

        draft.editDiningOption.fetching = false;
        draft.editDiningOption.fetched = true;
        draft.editDiningOption.diningOption = payload;
        draft.getDiningOptions.diningOptions = diningOptionsList;

        break;
      }

      case editDiningOption.FAILURE:
        draft.editDiningOption.error = payload;
        draft.editDiningOption.fetching = false;
        draft.editDiningOption.fetched = false;
        break;

      case editDiningOption.RESET:
        draft.editDiningOption = { ...initialState.editDiningOption };
        break;

      // setIsEnabled
      case setIsEnabledDiningOption.REQUEST:
        draft.setIsEnabledDiningOption = { ...initialState.setIsEnabledDiningOption, fetching: true };
        break;

      case setIsEnabledDiningOption.SUCCESS: {
        const diningOptionsList = draft.getDiningOptions.diningOptions.map((diningOption) => {
          return { ...diningOption };
        });
        diningOptionsList.find((diningOption) => diningOption?.uuid === payload?.uuid).isEnabled = payload?.isEnabled;
        diningOptionsList.find((diningOption) => diningOption?.uuid === payload?.uuid).isDefault = payload?.isDefault;

        draft.setIsEnabledDiningOption.fetching = false;
        draft.setIsEnabledDiningOption.fetched = true;
        draft.getDiningOptions.diningOptions = diningOptionsList;
        break;
      }

      case setIsEnabledDiningOption.FAILURE:
        draft.setIsEnabledDiningOption.error = payload;
        draft.setIsEnabledDiningOption.fetching = false;
        draft.setIsEnabledDiningOption.fetched = false;
        break;

      case setIsEnabledDiningOption.RESET:
        draft.setIsEnabledDiningOption = { ...initialState.setIsEnabledDiningOption };
        break;

      // setIsDefault
      case setIsDefaultDiningOption.REQUEST:
        draft.setIsDefaultDiningOption = { ...initialState.setIsDefaultDiningOption, fetching: true };
        break;

      case setIsDefaultDiningOption.SUCCESS: {
        const diningOptionsList = draft.getDiningOptions.diningOptions.map((diningOption) => {
          return { ...diningOption, isDefault: false };
        });
        diningOptionsList.find((diningOption) => diningOption?.uuid === payload?.uuid).isDefault = payload?.isDefault;

        draft.setIsDefaultDiningOption.fetching = false;
        draft.setIsDefaultDiningOption.fetched = true;
        draft.getDiningOptions.diningOptions = diningOptionsList;
        break;
      }

      case setIsDefaultDiningOption.FAILURE:
        draft.setIsDefaultDiningOption.error = payload;
        draft.setIsDefaultDiningOption.fetching = false;
        draft.setIsDefaultDiningOption.fetched = false;
        break;

      case setIsDefaultDiningOption.RESET:
        draft.setIsDefaultDiningOption = { ...initialState.setIsDefaultDiningOption };
        break;

      default:
        return draft;
    }
  });

export default restaurantDiningOptionsProviderReducer;
