/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Table from '@experimental-components/Table';
import Link from '@material-ui/core/Link';
import camelCase from 'lodash/camelCase';
import { stringify } from 'query-string';

import IconButton from '@design-system/IconButton';
import IconMoreVertical from '@experimental-components/IconsComponents/MoreVerticalV2';
import ActionsMenuMaker from 'components/ActionsMenuMaker';
import PlaceholdersTable from 'components/PlaceholdersTable';
import DisplayMessage from 'components/DisplayMessageV2';
import ConfirmDialog from 'components/ConfirmDialog';

import useUserStore from 'hooks/useUserStore';

import { drawerActions, drawerEntities } from 'utils/drawerMenuMaker';

import useStyles from './styles';

export function ModifierGroupsTable({
  isSearchFilter,
  loading,
  modifierGroupPagination,
  modifierGroups,
  onDeleteModifierGroup,
  deleteModifierGroupState,
  onPaginate,
  openDrawer,
  paginationModifierGroupState,
  onDuplicateModifierGroup,
  t,
}) {
  const history = useHistory();
  const classes = useStyles();
  const { storeId: storeUuid } = useUserStore();
  const [selectedModifierGroup, setSelectedModifierGroup] = useState(null);
  const [selectedModifierGroupDelete, setSelectedModifierGroupDelete] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorActions, setAnchorActions] = useState(null);
  const scrollPaginationRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];

        if (isIntersecting && modifierGroupPagination?.next) {
          if (!paginationModifierGroupState.fetching) {
            onPaginate();
          }
        }
      },
      { threshold: 1 },
    );

    if (scrollPaginationRef.current) observer.observe(scrollPaginationRef.current);

    return () => {
      observer.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollPaginationRef, modifierGroupPagination?.next]);

  useEffect(() => {
    if (deleteModifierGroupState.fetched || deleteModifierGroupState.error) {
      setAnchorEl(null);
      setSelectedModifierGroup(null);
      setSelectedModifierGroupDelete(null);
    }
  }, [deleteModifierGroupState]);

  const handleDeleteModifierGroup = () => {
    onDeleteModifierGroup(storeUuid, selectedModifierGroupDelete);
  };

  const handleConfirmDeleteModifierGroup = (modifierGroup) => () => {
    setSelectedModifierGroupDelete(modifierGroup);
    setAnchorEl(anchorActions);
    handleCloseActions();
  };

  const handleDuplicateModifierGroup = (modifierGroup) => () => {
    setAnchorActions(null);
    onDuplicateModifierGroup(modifierGroup?.uuid);
    setSelectedModifierGroup(null);
  };

  const handleCancelDeleteModifierGroup = () => {
    setSelectedModifierGroupDelete(null);
    setAnchorEl(null);
  };

  const handleEditModifierGroup = (modifierGroup) => {
    const query = { action: drawerActions.EDIT, entity: drawerEntities.MODIFIER_GROUP, uuid: modifierGroup?.uuid };

    history.replace({ search: stringify(query) });
  };

  const handleOpenActions = (modifierGroup) => ({ currentTarget }) => {
    setAnchorActions(currentTarget);
    setSelectedModifierGroup(modifierGroup);
  };

  const handleCloseActions = () => {
    setAnchorActions(null);
    setSelectedModifierGroup(null);
  };

  function renderTableRows() {
    if (loading) {
      return <PlaceholdersTable placeholderCols={openDrawer ? 4 : 6} />;
    }

    return modifierGroups.map((modifierGroup, index) => (
      <Table.Row key={modifierGroup.uuid}>
        <Table.Cell align="left" component="td" isBorder={index !== 0} scope="row">
          <Link
            className={classes.linkCell}
            color="textPrimary"
            component="button"
            data-testid={`link_${modifierGroup.uuid}`}
            onClick={() => handleEditModifierGroup(modifierGroup)}
            underline="none"
            variant="body2"
          >
            {modifierGroup.name}
          </Link>
        </Table.Cell>

        {!openDrawer && (
          <Table.Cell align="left" component="td" isBorder={index !== 0} scope="row">
            {modifierGroup?.description ? modifierGroup.description : t('common:notAvailableDefault')}
          </Table.Cell>
        )}

        <Table.BigCell align="left" component="td" isBorder={index !== 0} scope="row">
          {modifierGroup.options?.length ? (
            <Table.BigCell.ExpandableList
              list={modifierGroup?.options?.map((option) => (
                <Table.BigCell.Option key={option?.uuid}>{option?.name}</Table.BigCell.Option>
              ))}
            />
          ) : (
            t('common:notAvailableDefault')
          )}
        </Table.BigCell>

        <Table.BigCell align="left" component="td" isBorder={index !== 0} scope="row">
          {modifierGroup.appliedProducts?.length ? (
            <Table.BigCell.ExpandableList
              list={modifierGroup?.appliedProducts?.map((product) => (
                <Table.BigCell.Option key={product?.uuid}>{product?.name}</Table.BigCell.Option>
              ))}
            />
          ) : (
            t('common:notAvailableDefault')
          )}
        </Table.BigCell>

        {!openDrawer && (
          <Table.Cell align="left" component="td" isBorder={index !== 0} scope="row">
            {modifierGroup.modifierType
              ? t(`menuMaker:modifierGroupForm.modifierType.options.${camelCase(modifierGroup.modifierType)}.name`)
              : t('common:notAvailableDefault')}
          </Table.Cell>
        )}

        <Table.Cell align="right" isBorder={index !== 0} width={84}>
          <IconButton aria-label="actions" onClick={handleOpenActions(modifierGroup)}>
            <IconMoreVertical />
          </IconButton>
        </Table.Cell>
      </Table.Row>
    ));
  }

  if (!modifierGroups?.length && isSearchFilter) {
    return (
      <DisplayMessage
        data-testid="modifierGroupsSearchNoData"
        id="modifierGroupsSearchNoData"
        maxWidth={450}
        message={t('common:messages.searchMessageNoResults')}
        sizeIcon={120}
        title={t('common:messages.searchTitleNoResults')}
      />
    );
  }

  if (!modifierGroups?.length && !loading && !isSearchFilter) {
    return (
      <DisplayMessage
        data-testid="modifierGroupsNoData"
        id="modifierGroupsNoData"
        message={t('menuMaker:messages.noModifierGroups')}
        sizeIcon={120}
      />
    );
  }

  return (
    <>
      <Table aria-label="simple table">
        <Table.Head>
          <Table.Row>
            <Table.HeadCell align="left">{t('menuMaker:modifierGroupsTable.headers.name')}</Table.HeadCell>
            {!openDrawer && (
              <Table.HeadCell align="left">{t('menuMaker:modifierGroupsTable.headers.note')}</Table.HeadCell>
            )}
            <Table.HeadCell align="left">{t('menuMaker:modifierGroupsTable.headers.includes')}</Table.HeadCell>
            <Table.HeadCell align="left">{t('menuMaker:modifierGroupsTable.headers.articles')}</Table.HeadCell>
            {!openDrawer && (
              <Table.HeadCell align="left">{t('menuMaker:modifierGroupsTable.headers.modifierType')}</Table.HeadCell>
            )}
            <Table.HeadCell align="right" />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {renderTableRows()}
          {paginationModifierGroupState.fetching && <PlaceholdersTable placeholderCols={openDrawer ? 4 : 6} />}
        </Table.Body>
      </Table>

      <Box ref={scrollPaginationRef} mb={6} name="actionTableScroll" />

      <ActionsMenuMaker
        anchorEl={anchorActions}
        onClose={handleCloseActions}
        onDelete={handleConfirmDeleteModifierGroup(selectedModifierGroup)}
        onDuplicate={handleDuplicateModifierGroup(selectedModifierGroup)}
        open={Boolean(anchorActions)}
      />

      <ConfirmDialog
        anchorEl={anchorEl}
        data-testid="popperModifierGroupDelete"
        id="popperModifierGroupDelete"
        isDisabled={deleteModifierGroupState.fetching}
        labelAccept={t('common:buttons.delete')}
        labelCancel={t('common:buttons.cancel')}
        loading={deleteModifierGroupState.fetching}
        message={t('menuMaker:modifierGroupsTable.popper.onDelete.message')}
        onAccept={handleDeleteModifierGroup}
        onCancel={handleCancelDeleteModifierGroup}
        open={Boolean(anchorEl)}
        title={t('menuMaker:modifierGroupsTable.popper.onDelete.title')}
      />
    </>
  );
}

ModifierGroupsTable.propTypes = {
  isSearchFilter: PropTypes.bool,
  loading: PropTypes.bool,
  modifierGroupPagination: PropTypes.object,
  modifierGroups: PropTypes.array,
  onDeleteModifierGroup: PropTypes.func,
  deleteModifierGroupState: PropTypes.object,
  onPaginate: PropTypes.func,
  openDrawer: PropTypes.bool,
  paginationModifierGroupState: PropTypes.object,
  onDuplicateModifierGroup: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation('menuMaker')(ModifierGroupsTable);
