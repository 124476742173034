const PAYMENT_METHODS = 'paymentMethods';
const PAYMENT_DISCOUNTS = 'discounts';
const PAYMENT_CANCELLATION_REASONS = 'cancellationReasons';
const ORDER_TYPES = 'orderTypes';
const KITCHEN_TICKET = 'kitchenTicket';
const RECEIPT_CUSTOM = 'receiptCustom';
const CASH_MOVEMENT_REASONS = 'cashMovementReasons';
const PAYMENT_SERVICE_CHARGES = 'servicesCharges';
const COMMISSION_CAPTURE = 'commissionCapture';
const KITCHEN_AREAS = 'kitchenAreas';
const TABLES_SERVICE_AREAS = 'servicesAreas';
const TABLES = 'tables';
const TABLES_CONFIG = 'tablesConfig';
const STOCK_CATEGORIES = 'stockCategories';
const STORAGE_AREAS = 'storageAreas';
const CASH_REGISTER = 'cashRegister';
const PARROT_PAY = 'parrotPay';
const TAXES = 'taxes';
const TIPS = 'tips';
const CLOCK_TIME = 'clockTime';
const ADMIN_IMAGES_POS = 'adminImagesPos';

export const settingsTypes = {
  PAYMENT_METHODS,
  PAYMENT_DISCOUNTS,
  PAYMENT_CANCELLATION_REASONS,
  ORDER_TYPES,
  KITCHEN_TICKET,
  RECEIPT_CUSTOM,
  CASH_MOVEMENT_REASONS,
  PAYMENT_SERVICE_CHARGES,
  COMMISSION_CAPTURE,
  KITCHEN_AREAS,
  TABLES_SERVICE_AREAS,
  TABLES,
  TABLES_CONFIG,
  STOCK_CATEGORIES,
  STORAGE_AREAS,
  CASH_REGISTER,
  PARROT_PAY,
  TAXES,
  TIPS,
  CLOCK_TIME,
  ADMIN_IMAGES_POS,
};

const CREATE = 'create';
const EDIT = 'edit';
const DUPLICATE = 'duplicate';

export const actionsType = {
  CREATE,
  EDIT,
  DUPLICATE,
};

export const settingsPaths = {
  [PAYMENT_METHODS]: 'payment-methods',
  [PAYMENT_DISCOUNTS]: 'payments-discounts',
  [PAYMENT_CANCELLATION_REASONS]: 'payments-cancellation-reasons',
  [ORDER_TYPES]: 'order-types',
  [KITCHEN_TICKET]: 'kitchen-ticket',
  [RECEIPT_CUSTOM]: 'receipt-custom',
  [CASH_MOVEMENT_REASONS]: 'cash-movement-reasons',
  [PAYMENT_SERVICE_CHARGES]: 'payments-service-charges',
  [COMMISSION_CAPTURE]: 'commission-capture',
  [KITCHEN_AREAS]: 'kitchen-areas',
  [TABLES_SERVICE_AREAS]: 'tables-service-areas',
  [TABLES]: 'tables-admin',
  [TABLES_CONFIG]: 'tables-config',
  [STOCK_CATEGORIES]: 'stock-categories',
  [STORAGE_AREAS]: 'storage-areas',
  [CASH_REGISTER]: 'cash-register',
  [PARROT_PAY]: 'pay',
  [TAXES]: 'taxes',
  [TIPS]: 'tips',
  [CLOCK_TIME]: 'clock-time',
  [ADMIN_IMAGES_POS]: 'admin-images-pos',
};

export function getSettingsPaths(type, action) {
  const actionPath = action ? `/${action}` : '';
  return `/settings/${settingsPaths[type]}${actionPath}`;
}

export const settingsPathRoot = '/settings';
