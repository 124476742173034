import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  listItem: {
    zIndex: '1200',
    whiteSpace: 'nowrap',
    padding: theme.spacing(2),
  },

  grabCursor: {
    '& > :first-child': {
      cursor: 'grab',
    },
  },

  itemProduct: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    fontSize: '1rem',
    justifyContent: 'space-between',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
    '& > div:first-child': {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
  },

  disableHover: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  listContainer: {
    width: 'fit-content',
    maxWidth: '100%',
    minWidth: '70%',
    border: `1px solid ${theme.palette.darkGrey[15]}`,
    borderRadius: theme.typography.pxToRem(5),
    marginTop: theme.typography.pxToRem(14),
    padding: 0,
    '&>:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.darkGrey[15]}`,
    },

    '& li': {
      '& button': {
        padding: `0 ${theme.typography.pxToRem(14)}`,
      },
    },
  },

  errorDuplicateSku: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(12),
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;
