import clsx from 'clsx';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, UseFormMethods, useWatch } from 'react-hook-form';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import ToggleButton from '@material-ui/lab/ToggleButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import PriceFormatField from 'components/PriceFormatField';
import InputOverridePrice from 'components/InputOverridePrice';

import IconButton from '@design-system/IconButton';
import InputTextFiled from '@design-system/TextField';

import Help from '@experimental-components/IconsComponents/Help';
import SelectFieldGroup from '@experimental-components/SelectFieldGroup';
import NumberFieldGroup from '@experimental-components/NumberFieldGroup';
import InformationIndicador from '@experimental-components/InformationIndicator';

import { maxValueAllowed } from 'utils/currency';
import { IconButtonEvent } from 'utils/eventsTypes';
import { statusType, formatAmount } from 'utils/products';

import { MenusProductOverrideProps, SalesTypes, GrammageTypes, GrammageTypesProps } from './types';
import ToolTip from './ToolTip';
import useStyles from './styles';
import ConfirmDialog from './modalConfirm';

interface ConfirmChangeTypeSoldProps {
  show?: boolean;
  titleModal?: string;
  messageModal?: string;
}

interface Props {
  formMethods: UseFormMethods;
  onChangeTypeSold: (value: number) => void;
  menusProductOverride: MenusProductOverrideProps[];
  uuidSelected: string;
  priceProductField?: string;
  isEdition: boolean;
  shouldShowUnitSelector: boolean;
  showConfirmChangeTypeSold: ConfirmChangeTypeSoldProps;
  onConfirmChangeType: () => void;
  onCancelChangeType: () => void;
}

function SoldBehaviourSection({
  formMethods,
  onChangeTypeSold,
  menusProductOverride,
  uuidSelected,
  priceProductField,
  isEdition,
  shouldShowUnitSelector,
  showConfirmChangeTypeSold,
  onConfirmChangeType,
  onCancelChangeType,
}: Props) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const classes: any = useStyles();
  const { t } = useTranslation(['menuMaker']);
  const [anchorElTooltip, setAnchorElTooltip] = useState<Element | null>(null);

  const { errors, control, setValue } = formMethods;

  const soldAloneField = useWatch({
    control,
    name: 'soldAloneField',
  });

  const servingUnit = useWatch({
    control,
    name: 'servingUnit',
  });

  function handleSetAnchorElOverridePrice(event: IconButtonEvent) {
    setAnchorElTooltip(event.currentTarget);
  }

  function handleSetAnchorElClose() {
    setAnchorElTooltip(null);
  }

  const unitValue = useMemo(() => {
    if (isEdition && !shouldShowUnitSelector) {
      const idUnit = servingUnit;
      return GrammageTypes.find((u) => idUnit === u.id)?.name || '';
    }
    return '';
  }, [isEdition, shouldShowUnitSelector, servingUnit]);

  useEffect(() => {
    if (shouldShowUnitSelector && soldAloneField === SalesTypes.BY_GRAMMAGE) {
      setValue('servingUnit', GrammageTypes[0].id);
    }
  }, [shouldShowUnitSelector, soldAloneField]);

  return (
    <>
      <Grid item>
        <Box className={classes.subtitlesThree} mb={3}>
          {t('menuMaker:productForm.isSoldAlone.label')}
        </Box>
        <Controller
          control={control}
          name="soldAloneField"
          render={({ value }) => (
            <ToggleButtonGroup
              className={classes.toggleButtonsGroup}
              exclusive
              onChange={(e, option) => option !== null && onChangeTypeSold(option)}
              value={value}
            >
              <ToggleButton
                data-testid="productSoldAloneYes"
                disabled={isEdition && !shouldShowUnitSelector && soldAloneField === SalesTypes.BY_GRAMMAGE}
                value={SalesTypes.ALONE}
              >
                {t('menuMaker:productForm.isSoldAlone.soldAloneLabel')}
              </ToggleButton>
              <ToggleButton
                data-testid="productSoldAloneNo"
                disabled={isEdition && !shouldShowUnitSelector && soldAloneField === SalesTypes.BY_GRAMMAGE}
                value={SalesTypes.WITH_MODIFIER}
              >
                {t('menuMaker:productForm.isSoldAlone.soldWithModifier')}
              </ToggleButton>
              <ToggleButton data-testid="productSoldByGrammage" value={SalesTypes.BY_GRAMMAGE}>
                {t('menuMaker:productForm.isSoldAlone.soldByGrammage')}
              </ToggleButton>
            </ToggleButtonGroup>
          )}
          rules={{ required: true }}
        />
      </Grid>

      <Grid container spacing={2} style={{ padding: '0 16px 0 16px' }}>
        <Grid item sm={soldAloneField !== SalesTypes.BY_GRAMMAGE ? 12 : 6}>
          <Controller
            control={control}
            name="priceProductField"
            render={({ onChange, value, name }) => (
              <PriceFormatField
                autoComplete="off"
                customInput={InputTextFiled}
                data-testid="inputPriceProduct"
                error={Boolean(errors?.priceProductField)}
                errorMsg={errors?.priceProductField?.message}
                fullWidth
                isAllowed={maxValueAllowed(9999999.99)}
                label={t('menuMaker:productForm.price.label')}
                name={name}
                onChange={onChange}
                placeholder="$0.00"
                useNegative={false}
                value={value}
              />
            )}
            rules={{
              min: {
                message: t('menuMaker:productForm.price.noNegativeValuesErrorLabel'),
                value: 0,
              },
              maxLength: {
                message: t('menuMaker:productForm.price.tooManyDigitsErrorLabel'),
                value: 11,
              },
              required: t('common:messages.requireField'),
            }}
          />
        </Grid>
        {soldAloneField === SalesTypes.BY_GRAMMAGE && (
          <>
            <Grid item sm={soldAloneField !== SalesTypes.BY_GRAMMAGE ? 12 : 6}>
              <Controller
                control={control}
                defaultValue=""
                name="serving"
                render={({ name, onChange, value }) => (
                  <NumberFieldGroup
                    className={isEdition ? classes.customInput : ''}
                    decimalScale={2}
                    disabled={false}
                    endAdornment={
                      <>
                        {shouldShowUnitSelector && (
                          <Controller
                            control={control}
                            defaultValue=""
                            name="servingUnit"
                            render={({ name: nameUnitAmountField, onChange: onChangeUnit, value: valueUnit }) => (
                              <SelectFieldGroup
                                displayEmpty
                                name={nameUnitAmountField}
                                onChange={onChangeUnit}
                                value={valueUnit}
                              >
                                {GrammageTypes.map((unit: GrammageTypesProps) => (
                                  <MenuItem key={unit.id} value={unit.id}>
                                    {unit.name}
                                  </MenuItem>
                                ))}
                              </SelectFieldGroup>
                            )}
                          />
                        )}
                        {!shouldShowUnitSelector && (
                          <>
                            <Controller
                              control={control}
                              name="servingUnit"
                              render={(props) => (
                                <input readOnly style={{ height: 0, width: 0, visibility: 'hidden' }} {...props} />
                              )}
                            />
                            <InputAdornment position="end">
                              <Box style={{ background: '#F2F2F4' }} width={100}>
                                {unitValue}
                              </Box>
                            </InputAdornment>
                          </>
                        )}
                      </>
                    }
                    error={Boolean(errors?.serving)}
                    errorMsg={t(errors?.serving?.message)}
                    isFullWidth
                    label={t('menuMaker:productForm.isSoldAlone.salesUnit')}
                    name={name}
                    onChange={onChange}
                    placeholder="0"
                    value={value}
                  />
                )}
                rules={{
                  min: {
                    message: t('menuMaker:productForm.price.noNegativeValuesErrorLabel'),
                    value: 0,
                  },
                  required: t('common:messages.requireField'),
                }}
              />
            </Grid>
            <Grid>
              <Box color="text.secondary" fontSize={16} mb={3} ml={1} mr={1} mt={3}>
                {t('menuMaker:productForm.isSoldAlone.grammageExample')}
              </Box>
              <Box ml={1} mr={1}>
                <InformationIndicador text={t('menuMaker:productForm.isSoldAlone.soldGrammageInformation')} />
              </Box>
            </Grid>
          </>
        )}
        <Grid item>
          {menusProductOverride?.length > 0 && (
            <>
              <Box mt={4}>
                <Box className={classes.headOverridePrices}>
                  <Box fontSize="1rem" fontWeight="fontWeightMedium">
                    {t('menuMaker:productForm.pricePerMenu.label')}
                  </Box>
                  <IconButton onClick={(event: IconButtonEvent) => handleSetAnchorElOverridePrice(event)}>
                    <Help />
                  </IconButton>
                </Box>
                <Box className={classes.twoCols}>
                  {menusProductOverride?.map((menu: MenusProductOverrideProps) => (
                    <Box
                      key={menu?.uuid}
                      className={clsx(classes.contentItemMenuOverride, {
                        [classes.disabledOverridePrices]: menu?.availability === statusType.STATUS_UNAVAILABLE,
                      })}
                    >
                      <Box className={classes.itemTextOverride}>{menu?.menu?.name}</Box>
                      <InputOverridePrice
                        disabled={menu?.availability === statusType.STATUS_UNAVAILABLE}
                        isResume={false}
                        menuName={menu?.menu?.name}
                        menuUuid={menu?.menu?.uuid}
                        placeholder={t('menuMaker:contentResume.labels.priceInMenuPlaceholder')}
                        productUuid={uuidSelected}
                        value={menu?.price}
                        valueBase={formatAmount(priceProductField)}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
              <ToolTip
                anchorEl={anchorElTooltip}
                message={t('menuMaker:productForm.pricePerMenu.helperText')}
                onClose={handleSetAnchorElClose}
              />
            </>
          )}
        </Grid>

        <Box mt={5}>
          <Divider />
        </Box>
        {showConfirmChangeTypeSold.show && (
          <ConfirmDialog
            id="dialogConfirmModifer"
            labelAccept={t('common:buttons.accept')}
            labelCancel={t('common:buttons.cancel')}
            message={t(`menuMaker:productForm.modalTypeSold.${showConfirmChangeTypeSold.messageModal}`)}
            onAccept={onConfirmChangeType}
            onCancel={onCancelChangeType}
            open={showConfirmChangeTypeSold.show}
            title={t(`menuMaker:productForm.modalTypeSold.${showConfirmChangeTypeSold.titleModal}`)}
          />
        )}
      </Grid>
    </>
  );
}

export default SoldBehaviourSection;
