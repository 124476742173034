import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  rootRow: {
    padding: '1.5rem 0',
    borderBottom: `1px solid ${theme.palette.darkGrey[5]}`,
    width: '100%',
  },
  headerRow: {
    padding: '1rem 2.5rem',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  labelRow: {
    textAlign: 'start',
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(2),
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  wrapperTimesRow: {
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
    '& > div': {
      fontSize: '1rem',
    },
  },

  chipTimesRow: {
    backgroundColor: theme.palette.darkGrey[5],
    fontSize: '1rem',
    marginRight: theme.spacing(2),
    minWidth: theme.spacing(5),
  },
  contentLeftRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& > :first-child': {
      marginRight: theme.spacing(3),
    },
  },
  contentRightRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& > :first-child': {
      marginRight: theme.spacing(5),
    },
  },
  providersRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    visibility: 'visible',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  table: {
    display: 'table',
    tableLayout: 'fixed',
    width: '100%',
  },
  row: {
    display: 'flex',
    whiteSpace: 'nowrap',
    color: 'inherit',
    outline: 0,
    verticalAlign: 'middle',
    width: '100%',
  },
  td: {
    width: '100%',
    padding: '0 !important',
  },
}));

export default useStyles;
