/* eslint-disable @typescript-eslint/no-explicit-any */
import NumberFormat from 'react-number-format';

import InputBase /* , { InputBaseProps } */ from './InputBase';

// interface Props extends InputBaseProps {
//   inputRef?: (el: HTMLInputElement) => void;
//   thousandSeparator?: boolean | string;
// }

function NumberFieldGroup({ inputRef, thousandSeparator, ...others }: any) {
  return (
    <NumberFormat
      {...others}
      allowNegative={false}
      customInput={InputBase}
      getInputRef={inputRef}
      thousandSeparator={thousandSeparator}
    />
  );
}

NumberFieldGroup.input = InputBase;

export default NumberFieldGroup;
