import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'end',
    gap: theme.spacing(3),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      gridTemplateColumns: '1fr',
    },
  },

  wrapperFilters: ({ filters, openDrawer }) => {
    let columns = 'repeat(2, 1fr)';
    let fullWidthColumns = '1fr';

    if (filters === 1) {
      columns = '2fr 2fr 1fr';
      fullWidthColumns = 'repeat(2, 1fr)';
    }

    return {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: openDrawer ? fullWidthColumns : columns,
      gap: theme.spacing(3),
      alignItems: 'end',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: fullWidthColumns,
      },
      [theme.breakpoints.down('sm')]: {
        gridRow: '2/3',
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '1fr',
      },
    };
  },

  searchBox: {
    width: '100%',
  },

  contentSelected: {
    color: theme.palette.darkGrey.main,
    fontSize: theme.typography.pxToRem(14),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  wrapperMultiSelect: {
    overflow: 'hidden',
  },

  wrapperActions: {
    display: 'flex',
    gap: theme.spacing(3),
    justifyContent: 'end',
  },

  middlePrimaryButton: {
    alignSelf: 'baseline',
  },

  optionAll: {
    paddingBlock: theme.spacing(1.5),
  },
}));

export default useStyles;
