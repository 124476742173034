import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getSettingsStoreRequest(options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/stores/settings/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function updateSettingsStoreRequest(updateSettingsStoreData, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/stores/settings/?${query}`, {
    method: 'PUT',
    body: JSON.stringify(updateSettingsStoreData),
    ...options,
  });
}

export function getSettingsStoreCustomizePrintsRequest({ context, brandUuid, evalBrandTicket }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    context,
    brand: brandUuid,
    eval_brand_ticket: evalBrandTicket,
  });

  return request(`${API_BASE_URL}/v1/stores/settings/tickets/?${query}`, {
    method: 'GET',
    ...options,
  });
}
