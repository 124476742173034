import withStyles from '@material-ui/core/styles/withStyles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const ButtonPrimaryBase = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(18),
    padding: '1rem 2rem',
    lineHeight: 1.5,
    height: theme.typography.pxToRem(56),
    minWidth: theme.typography.pxToRem(133),
    color: theme.palette.common.black,

    outlined: {
      border: `1px solid ${theme.palette.common.black}`,
    },

    '&:hover': {
      backgroundColor: theme.palette.darkGrey[5],
      boxShadow: 'none',
    },

    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.darkGrey[5],
    },

    '&:focus': {
      backgroundColor: theme.palette.darkGrey[5],
    },
  },
}))(Button);

interface Props extends ButtonProps {
  loading?: boolean;
}

function ButtonOutlined({ loading, ...others }: Props) {
  return (
    <ButtonPrimaryBase
      disableElevation
      disableRipple
      endIcon={loading && <CircularProgress color="inherit" size={18} />}
      variant="outlined"
      {...others}
    />
  );
}

export default ButtonOutlined;
