import { Switch } from 'react-router-dom';

import { useFeature, useFeatures } from 'FeatureProvider';

import useUserStore from 'hooks/useUserStore';

import Reports from 'containers/Reports/Loadable';
import ReportsPay from 'containers/ReportsPay/Loadable';
import TransferDetails from 'containers/ReportsPay/TransferDetailsLoadable';
import ReportsIndex from 'containers/ReportsIndex';
import ReportsOrders from 'containers/ReportsOrders/Loadable';
import ReportsPayments from 'containers/ReportsPayments/Loadable';
import ReportsCancellations from 'containers/ReportsCancellations/Loadable';
import ReportsDiscounts from 'containers/ReportsDiscounts/Loadable';
import ReportsResume from 'containers/ReportsResume/Loadable';
import ReportsClientData from 'containers/ReportsClientData/Loadable';
import ReportUsers from 'containers/ReportUsers/Loadable';
import ReportAssistance from 'containers/ReportAssistance/Loadable';
import ReportCostsAndMargins from 'containers/ReportCostAndMargins/Loadable';
import ReportModifiers from 'containers/ReportModifiers';

import { getModifiersReportFlags } from 'utils/featureFlags/reports';

import AppRoute from '../PrivateRoute';

export function ReportsRoutes() {
  const {
    storeState: { selected: storeSelected },
  } = useUserStore();
  const superUserAccess = useFeature('adminStore');
  const reportModuleAccess = useFeature('backofficeModuleReports');
  const permissionReportModule = (superUserAccess || reportModuleAccess) && !storeSelected?.isDraft;

  const { flags } = useFeatures();
  const { showModifierReport } = getModifiersReportFlags(flags);

  return (
    <Switch>
      <AppRoute accessRoute={permissionReportModule} component={ReportsIndex} exact path="/reports" />
      <AppRoute accessRoute={permissionReportModule} component={ReportsResume} exact path="/reports/resume" />
      <AppRoute
        accessRoute={permissionReportModule}
        component={Reports}
        exact
        path="/reports/:type(products-sales|cash-register)/:reportFormat(table|lines|list|details)?/:key?/:typeView(all|part)?/:itemType?"
      />
      <AppRoute accessRoute={permissionReportModule} component={ReportsPay} exact path="/reports/transactions-pay" />
      <AppRoute accessRoute={permissionReportModule} component={ReportsPay} exact path="/reports/deposits-pay" />
      <AppRoute
        accessRoute={permissionReportModule}
        component={TransferDetails}
        exact
        path="/reports/transfers-pay/details"
      />
      <AppRoute
        accessRoute={permissionReportModule}
        component={ReportsOrders}
        exact
        path="/reports/orders/:reportFormat(summary|detailed)?"
      />
      <AppRoute
        accessRoute={permissionReportModule}
        component={ReportsPayments}
        exact
        path="/reports/payments/summary"
      />
      <AppRoute
        accessRoute={permissionReportModule}
        component={ReportsPayments}
        exact
        path="/reports/payments/payments"
      />
      <AppRoute
        accessRoute={permissionReportModule}
        component={ReportsCancellations}
        exact
        path="/reports/cancellations/:reportFormat(summary|detailed)?"
      />
      <AppRoute
        accessRoute={permissionReportModule}
        component={ReportsDiscounts}
        exact
        path="/reports/discounts/:reportFormat(summary|detailed)?"
      />
      <AppRoute
        accessRoute={permissionReportModule}
        component={ReportsClientData}
        exact
        path="/reports/client-data/:type(summary|details)/:clientUuid?"
      />
      <AppRoute accessRoute={permissionReportModule} component={ReportUsers} exact path="/reports/users" />
      <AppRoute accessRoute={permissionReportModule} component={ReportAssistance} exact path="/reports/assistance" />
      <AppRoute
        accessRoute={permissionReportModule}
        component={ReportCostsAndMargins}
        exact
        path="/reports/costs-and-margins/:actionType(detail)?/:productUuid?"
      />
      <AppRoute
        accessRoute={permissionReportModule && showModifierReport}
        component={ReportModifiers}
        exact
        path="/reports/modifiers/:type(modifiersByProduct|modifiersByOption)"
      />
    </Switch>
  );
}
