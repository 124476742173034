import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

import { useSidebarLayout } from 'providers/root/SidebarLayoutProvider';

import DrawerMui from '@material-ui/core/Drawer';

import MainView from './MainView';
import Step from './Step';
import useStyles from './styles';

function DrawerMenuMaker({ children, currentStep, open }) {
  const { modeDraft } = useSidebarLayout();
  const classes = useStyles({ isModeDraft: modeDraft });

  return (
    <DrawerMui anchor="right" classes={{ paper: classes.drawerPaper }} open={open} variant="persistent">
      {Children.toArray(children).map((child) => cloneElement(child, { currentStep }))}
    </DrawerMui>
  );
}

DrawerMenuMaker.MainView = MainView;
DrawerMenuMaker.Step = Step;

DrawerMenuMaker.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  currentStep: PropTypes.number,
};

export default DrawerMenuMaker;
