import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const ChevronDownThin = forwardRef(function ChevronDown(props: Props, ref: Ref<SVGSVGElement>) {
  const { className, ...others } = props;

  return (
    <svg
      ref={ref}
      className={className}
      color="black"
      fill="none"
      height="12"
      viewBox="0 0 20 12"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        clipRule="evenodd"
        d="M-4.66255e-07 1.33333L1.35987 0.0584583L10 9.2746L18.6401 0.0584575L20 1.33333L10 12L-4.66255e-07 1.33333Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  );
});

export default ChevronDownThin;
