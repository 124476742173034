import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import IconClock from '@experimental-components/IconsComponents/Clock';
import ButtonPlus from '@experimental-components/ButtonPlus';
import IconClose from '@experimental-components/IconsComponents/Close';
import IconButton from '@design-system/IconButton';

import InputAdornmentFiled from '@design-system/InputAdornmentFiled';
import Checkbox from '@design-system/Checkbox';
import Select from '@design-system/Select';

import MenuSkeleton from 'components/MenuForm/MenuSkeleton';
import MultiSelect from 'components/MultiSelect';
import SkuMultiStore from 'components/SkuMultiStore';

import {
  formDefinition,
  getWeekDaysSelected,
  getInitialCategories,
  getWeekendDaysSelected,
  validateUppercase,
  handleFormatValidateItems,
  validateDuplicateSkuItems,
  getProvidersValues,
} from 'utils/menuMaker';
import { optionsTimes } from 'utils/times';
import { regexTypes, formatTextAllowedValues } from 'utils/formatField';

import CheckBoxChanel from './CheckBoxChanel';
import useStyles from './styles';

function MenuForm({ categoriesState, focusInCategories, isEdition, menuState, onSetStep, t }) {
  const categoriesRef = useRef(null);
  const classes = useStyles();
  const { control, errors, register, setValue, watch, trigger } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: 'timeFields' });
  const watchFields = watch();

  const [selectedWeekDays, setSelectedWeekDays] = useState([]);
  const [selectedWeekendDays, setSelectedWeekendDays] = useState([]);

  useEffect(() => {
    setSelectedWeekDays(getWeekDaysSelected(fields));
    setSelectedWeekendDays(getWeekendDaysSelected(fields));
  }, [fields]);

  useEffect(() => {
    if (focusInCategories) {
      categoriesRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, [categoriesRef.current]);

  const handleRemoveSchedule = (index) => {
    const selectedWeekDaysTmp = selectedWeekDays.filter((item, indexSelected) => indexSelected !== index);
    const selectedWeekendDaysTmp = selectedWeekendDays.filter((item, indexSelected) => indexSelected !== index);

    setSelectedWeekDays(selectedWeekDaysTmp);
    setSelectedWeekendDays(selectedWeekendDaysTmp);
    remove(index);
  };

  const handleSelectAllWeekDays = (isChecked, index) => {
    const selectedWeekDaysTmp = JSON.parse(JSON.stringify(selectedWeekDays));
    selectedWeekDaysTmp[index] = [];

    formDefinition.weekdaysListCheckBox.forEach((weekDay) => {
      if (isChecked) selectedWeekDaysTmp[index].push(weekDay?.value);

      setValue(`timeFields[${index}].${weekDay?.name}`, isChecked ? weekDay?.value : false, { shouldValidate: true });
    });

    setSelectedWeekDays(selectedWeekDaysTmp);
  };

  const hanldeVerifyCheckedCheckBox = (array, value) => {
    return array?.indexOf(value) > -1;
  };

  const handleSelectWeekDay = (event, index) => {
    const selectedWeekDaysTmp = JSON.parse(JSON.stringify(selectedWeekDays));
    const selectedWeekDaysTmpIndex = JSON.parse(JSON.stringify(selectedWeekDaysTmp[index])).filter(
      (day) => day !== event.target.value,
    );

    if (event.target.checked) selectedWeekDaysTmpIndex.push(event.target.value);

    selectedWeekDaysTmp[index] = selectedWeekDaysTmpIndex;
    setSelectedWeekDays(selectedWeekDaysTmp);
  };

  const handleSelectAllWeekend = (isChecked, index) => {
    const selectedWeekendDaysTmp = JSON.parse(JSON.stringify(selectedWeekendDays));
    selectedWeekendDaysTmp[index] = [];

    formDefinition.weekendListCheckBox.forEach((weekendDay) => {
      if (isChecked) selectedWeekendDaysTmp[index].push(weekendDay?.value);

      setValue(`timeFields[${index}].${weekendDay?.name}`, isChecked ? weekendDay?.value : false, {
        shouldValidate: true,
      });
    });

    setSelectedWeekendDays(selectedWeekendDaysTmp);
  };

  const handleSelectWeekendDay = (event, index) => {
    const selectedWeekendDaysTmp = JSON.parse(JSON.stringify(selectedWeekendDays));
    const selectedWeekendDaysTmpIndex = JSON.parse(JSON.stringify(selectedWeekendDaysTmp[index])).filter(
      (day) => day !== event.target.value,
    );

    if (event.target.checked) selectedWeekendDaysTmpIndex.push(event.target.value);

    selectedWeekendDaysTmp[index] = selectedWeekendDaysTmpIndex;
    setSelectedWeekendDays(selectedWeekendDaysTmp);
  };

  const appendFormTimer = () => {
    setSelectedWeekDays([...selectedWeekDays, []]);
    setSelectedWeekendDays([...selectedWeekendDays, []]);
    append({});
  };

  const handleValidateAllowedValues = (target, field) => {
    target.value = formatTextAllowedValues(target.value, regexTypes.REGEX_PRODUCT_NAME_ALLOWED);
    setValue(field, target.value);
    return target.value;
  };

  const handleOpenSelectSKU = () => {
    onSetStep(2);
  };

  const renderTitle = () => {
    return (
      <Typography className={classes.title}>{t(`menuMaker:sections.${isEdition ? 'editMenu' : 'addMenu'}`)}</Typography>
    );
  };

  if (categoriesState.fetching || menuState.fetching) {
    return (
      <MenuSkeleton>
        <MenuSkeleton.Title>{renderTitle()}</MenuSkeleton.Title>
      </MenuSkeleton>
    );
  }

  if (isEdition ? menuState.data && categoriesState.fetched : categoriesState.fetched) {
    return (
      <Grid container direction="column" spacing={4}>
        <Grid item>{renderTitle()}</Grid>

        <Grid item>
          <InputAdornmentFiled
            {...formDefinition.nameMenuField}
            autoComplete="off"
            autoFocus
            data-testid="inputNameMenu"
            endAdornment={
              <InputAdornment position="end">
                <Box color="#CECDD1" fontSize="14px">
                  {watch('nameMenuField')?.length ?? 0}/30
                </Box>
              </InputAdornment>
            }
            error={Boolean(errors[formDefinition.nameMenuField.name])}
            errorMsg={t(errors[formDefinition.nameMenuField.name]?.message, { chars: 30 })}
            fullWidth
            inputProps={{ maxLength: formDefinition.nameMenuField.maxLength }}
            inputRef={register({
              ...formDefinition.nameMenuField.register,
              validate: {
                controlUppercase: (value) => validateUppercase(value) || t('common:errors.controlUppercase'),
              },
            })}
            label={t(formDefinition.nameMenuField.label)}
            onChange={({ target }) => handleValidateAllowedValues(target, 'inputNameMenu')}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.subtitles}>{t('menuMaker:createMenu.schedules.title')}</Typography>

          <Box mb={3}>
            <Typography color="textSecondary" variant="body2">
              {t('menuMaker:createMenu.schedules.header')}
            </Typography>
          </Box>

          <Grid container direction="column">
            {fields.map((field, index) => (
              <Grid key={field.id} item>
                <Box className={classes.paperHeader}>
                  {index !== 0 && (
                    <IconButton data-testid="btnRemoveSchedule" onClick={() => handleRemoveSchedule(index)}>
                      <IconClose lighter size={20} />
                    </IconButton>
                  )}
                </Box>

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Controller
                      as={
                        <Select
                          {...formDefinition.startDateField}
                          data-testid="selectStartDate"
                          fullWidth
                          icon={<IconClock />}
                          label={t(formDefinition.startDateField.label)}
                        >
                          {optionsTimes.map((time) => (
                            <MenuItem key={time.value} value={time.value}>
                              {time.label}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      control={control}
                      defaultValue={
                        watchFields?.timeFields && watchFields?.timeFields[index]
                          ? watchFields?.timeFields[index]?.startDateField
                          : ''
                      }
                      error={Boolean(errors?.timeFields?.length && errors?.timeFields[index]?.startDateField)}
                      helperText={t(errors?.timeFields?.length && errors?.timeFields[index]?.startDateField?.message)}
                      name={`timeFields[${index}].${formDefinition.startDateField.name}`}
                      rules={{ ...formDefinition.startDateField.rules }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Controller
                      as={
                        <Select
                          {...formDefinition.endDateField}
                          data-testid="selectEndDate"
                          fullWidth
                          icon={<IconClock />}
                          label={t(formDefinition.endDateField.label)}
                          onClick={() => trigger()}
                        >
                          {optionsTimes.map((time) => (
                            <MenuItem key={time.value} value={time.value}>
                              {time.label}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      control={control}
                      defaultValue={
                        watchFields?.timeFields && watchFields?.timeFields[index]
                          ? watchFields?.timeFields[index]?.endDateField
                          : ''
                      }
                      error={Boolean(errors?.timeFields?.length && errors?.timeFields[index]?.endDateField)}
                      helperText={t(errors?.timeFields?.length && errors?.timeFields[index]?.endDateField?.message)}
                      name={`timeFields[${index}].${formDefinition.endDateField.name}`}
                      rules={{ ...formDefinition.endDateField.rules }}
                    />
                  </Grid>
                </Grid>

                <Box mt={3}>
                  <Grid className={classes.weekdaysContainer} container>
                    <Grid item>
                      <Checkbox
                        checked={selectedWeekDays[index]?.length === 5}
                        className={classes.checkBoxSelectAll}
                        data-testid="weekDaysCheck"
                        label={t('menuMaker:createMenu.fields.weekday')}
                        onChange={({ target: { checked } }) => handleSelectAllWeekDays(checked, index)}
                      />

                      <FormControl component="fieldset">
                        <FormGroup row>
                          {formDefinition.weekdaysListCheckBox.map((weekdaysCheck) => (
                            <Checkbox
                              {...weekdaysCheck}
                              key={weekdaysCheck.name}
                              checked={hanldeVerifyCheckedCheckBox(selectedWeekDays[index], weekdaysCheck.value)}
                              className={classes.weekdays}
                              data-testid={weekdaysCheck.name}
                              inputRef={register()}
                              label={t(weekdaysCheck.label)}
                              name={`timeFields[${index}].${weekdaysCheck.name}`}
                              onChange={(e) => handleSelectWeekDay(e, index)}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </Grid>

                    <Grid item>
                      <Checkbox
                        checked={selectedWeekendDays[index]?.length === 2}
                        className={classes.checkBoxSelectAll}
                        data-testid="weekendDaysCheck"
                        label={t('menuMaker:createMenu.fields.weekend')}
                        onChange={(e) => handleSelectAllWeekend(e.target.checked, index)}
                      />

                      <FormControl component="fieldset">
                        <FormGroup row>
                          {formDefinition.weekendListCheckBox.map((weekendCheck) => (
                            <Checkbox
                              {...weekendCheck}
                              key={weekendCheck.name}
                              checked={hanldeVerifyCheckedCheckBox(selectedWeekendDays[index], weekendCheck.value)}
                              className={classes.weekdays}
                              data-testid={weekendCheck.name}
                              inputRef={register()}
                              label={t(weekendCheck.label)}
                              name={`timeFields[${index}].${weekendCheck.name}`}
                              onChange={(e) => handleSelectWeekendDay(e, index)}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                {index + 1 !== fields.length ? (
                  <Box my={5}>
                    <Divider />
                  </Box>
                ) : null}
              </Grid>
            ))}
          </Grid>

          <Box mt={3}>
            <ButtonPlus data-testid="btnAddSchedule" onClick={appendFormTimer}>
              {t('menuMaker:createMenu.schedules.buttonAdd')}
            </ButtonPlus>
          </Box>

          <Box mt={5}>
            <Divider />
          </Box>
        </Grid>

        <Grid ref={categoriesRef} item>
          <Box>
            <Typography className={classes.subtitles}>{t('menuMaker:createMenu.categories.title')}</Typography>

            <Box mb={1}>
              <Typography color="textSecondary" variant="body2">
                {t('menuMaker:createMenu.categories.inputPlaceholder')}
              </Typography>
            </Box>

            <Grid container>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="selectedCategories"
                  render={({ onChange, value, name }) => (
                    <MultiSelect
                      getOptionLabel={(option) => option.name}
                      getOptionUniqueKey={(option) => option.uuid}
                      inputPlaceholderText={t('menuMaker:createMenu.categories.inputPlaceholder')}
                      name={name}
                      noOptionsText={t('menuMaker:createMenu.categories.noCategories')}
                      onChange={(selectedOptions) => onChange(handleFormatValidateItems(selectedOptions))}
                      options={getInitialCategories(categoriesState.list, value)}
                      selectedOptions={value}
                      value={value}
                    />
                  )}
                  rules={{
                    validate: (items) => {
                      const itemsDuplicateSku = validateDuplicateSkuItems(items);

                      return (
                        itemsDuplicateSku?.length === 0 || t('menuMaker:skuLabels.menu.errorDuplicateCategoriesSku')
                      );
                    },
                  }}
                />
                {Boolean(errors?.selectedCategories) && (
                  <Box className={classes.errorMessagesCustom} mt={2}>
                    {errors?.selectedCategories?.message}
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>

          <Box mt={5}>
            <Divider />
          </Box>
        </Grid>

        <Grid item>
          <Typography className={classes.subtitles}>{t('menuMaker:createMenu.chanels.title')}</Typography>

          <Box>
            <Typography color="textSecondary" variant="body2">
              {t('menuMaker:createMenu.chanels.header')}
            </Typography>
          </Box>

          <Box mt={3}>
            <FormControl component="fieldset" fullWidth>
              <FormGroup className={classes.formChanel}>
                {formDefinition.providersOptions.map((providerOption) => (
                  <CheckBoxChanel
                    key={providerOption.name}
                    data-testid={`provider-option-${providerOption.id}`}
                    defaultChecked={Boolean(watchFields[providerOption.name])}
                    inputRef={register({ ...providerOption.register })}
                    label={t(providerOption.label)}
                    name={providerOption.name}
                    value={providerOption.value}
                  />
                ))}
              </FormGroup>
            </FormControl>
            {getProvidersValues(watchFields)?.length === 0 && (
              <Box className={classes.errorMessagesCustom} mt={0}>
                {t('menuMaker:createMenu.fieldErrors.selectProviders')}
              </Box>
            )}
          </Box>
        </Grid>

        <Grid item>
          <Typography className={classes.subtitles}>{t('menuMaker:createMenu.editMenuProducts.title')}</Typography>

          <Box mb={3}>
            <Typography color="textSecondary" variant="body2">
              {t('menuMaker:createMenu.editMenuProducts.header')}
            </Typography>
          </Box>

          {isEdition ? (
            <Box display="grid" gridGap={24}>
              <Controller
                control={control}
                name={formDefinition.changePricesField.name}
                render={({ onChange, value, name }) => (
                  <ToggleButtonGroup
                    className={classes.toggleOverride}
                    exclusive
                    name={name}
                    onChange={(e, option) => option !== null && onChange(option)}
                    value={value}
                  >
                    <ToggleButton data-testid="radioChangePriceYes" value={1}>
                      {t('common:options.yes')}
                    </ToggleButton>
                    <ToggleButton value={0}>{t('common:options.no')}</ToggleButton>
                  </ToggleButtonGroup>
                )}
              />

              <Typography className={classes.grayBox}>
                <Trans i18nKey="menuMaker:createMenu.editMenuProducts.editionInfo" />
              </Typography>
            </Box>
          ) : (
            <Typography className={classes.grayBox}>
              {t('menuMaker:createMenu.editMenuProducts.editionNotAvailable')}
            </Typography>
          )}

          <Box mt={5}>
            <Divider />
          </Box>
        </Grid>

        <Grid item>
          <Typography className={classes.subtitles}>{t('menuMaker:skuLabels.key')}</Typography>

          <Box mb={4} mt={1}>
            <Typography color="textSecondary" variant="body2">
              {t('menuMaker:skuLabels.menu.info')}
            </Typography>
          </Box>

          <SkuMultiStore
            errors={errors}
            onClickButton={handleOpenSelectSKU}
            prefix="ME-"
            register={register}
            trigger={trigger}
            watch={watch}
          />
        </Grid>
      </Grid>
    );
  }

  return null;
}

MenuForm.propTypes = {
  categoriesState: PropTypes.object,
  focusInCategories: PropTypes.bool,
  isEdition: PropTypes.bool,
  menuState: PropTypes.object,
  onSetStep: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation('menuMaker')(MenuForm);
