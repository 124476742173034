import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
}
const Menu = forwardRef(function Menu(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#FFF', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path d="M20 17C20 12.0294 16.4183 8 12 8C7.58172 8 4 12.0294 4 17" stroke={color} strokeWidth="1.5" />
      <path d="M2 17H22" stroke={color} strokeWidth="1.5" />
      <path d="M3 19.5L21 19.5" stroke={color} strokeWidth="1.5" />
      <path
        d="M13.5 6.5C13.5 7.32843 12.8284 8 12 8C11.1716 8 10.5 7.32843 10.5 6.5C10.5 5.67157 11.1716 5 12 5C12.8284 5 13.5 5.67157 13.5 6.5Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
});

export default Menu;
