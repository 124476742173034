import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  row: {
    '& td, & th': {
      paddingInline: theme.spacing(1),
      paddingBlock: theme.spacing(2),
      verticalAlign: 'top',
    },
  },

  optionRow: {
    zIndex: '1200',
    '& > :first-child': {
      cursor: 'grab',
    },
  },

  texField: {
    borderRadius: theme.typography.pxToRem(4),
    fontSize: theme.typography.pxToRem(14),
    padding: theme.typography.pxToRem(16),
    color: '#2e2e2e',
    '& > input': {
      padding: 0,
    },
  },

  divider: {
    backgroundColor: '#f2f2f2',
  },

  table: {
    display: 'table',
    tableLayout: 'fixed',
    width: '100%',
  },
  helperPopOver: {
    marginTop: theme.spacing(2),
  },
  message: {
    fontSize: theme.typography.pxToRem(15),
    width: theme.typography.pxToRem(340),
    padding: `${theme.typography.pxToRem(17)} ${theme.typography.pxToRem(20)}`,
  },

  inputOptionError: {
    color: `${theme.palette.secondary.main} !important`,
  },
  errorDuplicateSku: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(12),
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;
