import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useTranslation } from 'react-i18next';

import withStyles from '@material-ui/core/styles/withStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import ButtonBaseMui from '@material-ui/core/ButtonBase';
import Avatar from '@material-ui/core/Avatar';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { ReactComponent as IconDidi } from 'images/svg/brand/icons/icn-Didi.svg';
import { ReactComponent as IconPOS } from 'images/svg/brand/icons/icn-POS.svg';
import { ReactComponent as IconRappi } from 'images/svg/brand/icons/icn-Rappi.svg';
import { ReactComponent as IconUBEREATS } from 'images/svg/brand/icons/icn-UberEats.svg';
import MenuOrdering from '@experimental-components/IconsComponents/MenuOrdering';
import ChevronDown from '@experimental-components/IconsComponents/ChevronDown';
import CloudUpload from '@experimental-components/IconsComponents/CloudUpload';

import IcnUberEats from 'images/png/icons/icn-UberEats.png';
import IcnDidi from 'images/png/icons/icn-Didi.png';
import IcnRappi from 'images/png/icons/icn-Rappi.png';

import { providersTypes } from 'utils/menuMaker';

const ButtonBase = withStyles((theme) => ({
  root: {
    fontFamily: 'Roboto',
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: theme.palette.darkGrey[2],
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: theme.shape.borderRadius,
    minWidth: theme.typography.pxToRem(200),
    height: theme.typography.pxToRem(56),
    '& > :first-child': {
      marginRight: theme.spacing(1),
    },
    '& > :last-child': {
      marginLeft: theme.spacing(1),
    },
  },
}))(ButtonBaseMui);

const MenuBase = withStyles((theme) => ({
  paper: {
    boxShadow: '0px 8px 40px rgba(133, 133, 133, 0.2)',
    marginTop: theme.spacing(1),
  },
}))(Menu);

const MenuItemBase = withStyles((theme) => ({
  root: {
    padding: '12px 16px',
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightRegular,
    '&$focusVisible': {
      backgroundColor: theme.palette.darkGrey[2],
    },
    '&$selected, &$selected:hover': {
      backgroundColor: theme.palette.darkGrey[2],
    },

    '&:hover': {
      backgroundColor: theme.palette.darkGrey[2],
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  iconOpenMenu: {
    transform: 'rotate(180deg)',
  },
  small: {
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32),
  },
}));

const providersOptions = {
  [providersTypes.PARROT]: {
    value: providersTypes.PARROT,
    label: 'menuMaker:createMenu.fields.posField',
    icon: <IconPOS />,
  },
  [providersTypes.UBER_EATS]: {
    value: providersTypes.UBER_EATS,
    label: 'menuMaker:createMenu.fields.uberEatsFiled',
    icon: <IconUBEREATS />,
  },
  [providersTypes.DIDI_FOOD]: {
    value: providersTypes.DIDI_FOOD,
    label: 'menuMaker:createMenu.fields.didiFoodFiled',
    icon: <IconDidi />,
  },
  [providersTypes.RAPPI]: {
    value: providersTypes.RAPPI,
    label: 'menuMaker:createMenu.fields.rappiFiled',
    icon: <IconRappi />,
  },
  [providersTypes.ONLINE]: {
    value: providersTypes.ONLINE,
    label: 'menuMaker:createMenu.fields.onlineOrderingField',
    icon: <MenuOrdering />,
  },
};

function SelectProvider({ brand, disabled, onSelectProvider, resetProviderSelected, onResetProviderSelected }) {
  const classes = useStyles();
  const { t } = useTranslation('menuMaker');
  const [anchorEl, setAnchorEl] = useState(null);
  const [providerSelected, setProviderSelected] = useState(null);

  const handleOpenMenu = (event) => {
    event.stopPropagation();
    onResetProviderSelected(false);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleSelectedItem = (value) => (e) => {
    e.stopPropagation();
    onSelectProvider(brand, value);
    setProviderSelected(value);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (resetProviderSelected) {
      setProviderSelected(null);
    }
  }, [resetProviderSelected]);

  function renderIconProvider(provider) {
    switch (provider) {
      case providersTypes.PARROT:
        return <IconPOS />;
      case providersTypes.UBER_EATS:
        return <Avatar className={classes.small} src={IcnUberEats} />;
      case providersTypes.DIDI_FOOD:
        return <Avatar className={classes.small} src={IcnDidi} />;
      case providersTypes.RAPPI:
        return <Avatar className={classes.small} src={IcnRappi} />;
      case providersTypes.ONLINE:
        return (
          <Avatar className={classes.small}>
            <MenuOrdering />
          </Avatar>
        );
      default:
        break;
    }
  }

  return (
    <Box>
      <ButtonBase disabled={disabled} disableRipple onClick={handleOpenMenu}>
        {providerSelected ? providerSelected?.icon : <CloudUpload />}
        <Box component="span">
          {t(providerSelected ? providerSelected?.label : 'menuMaker:publishMenu.publishMenus')}
        </Box>
        <ChevronDown className={clsx({ [classes.iconOpenMenu]: Boolean(anchorEl) })} color="#000" lighter size={20} />
      </ButtonBase>

      <MenuBase
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
        keepMounted
        onClose={handleCloseMenu}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {brand?.providers?.map((providerKey) => (
          <MenuItemBase
            key={providerKey}
            disableRipple
            onClick={handleSelectedItem(providersOptions[providerKey])}
            selected={providerSelected?.value === providerKey}
          >
            <ListItemIcon>{renderIconProvider(providerKey)}</ListItemIcon>
            <ListItemText primary={t(providersOptions[providerKey]?.label)} />
          </MenuItemBase>
        ))}
      </MenuBase>
    </Box>
  );
}

SelectProvider.propTypes = {
  disabled: PropTypes.bool,
  brand: PropTypes.object,
  onSelectProvider: PropTypes.func,
  resetProviderSelected: PropTypes.bool,
  onResetProviderSelected: PropTypes.func,
};

export default SelectProvider;
