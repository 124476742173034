const PERMISSIONS_REPEATED_PIN = 'permissions.RepeatedPin';
const PERMISSIONS_EMAIL_AND_PASSWORD_IS_REQUIRED = 'permissions.EmailAndPasswordIsRequired';
const MENU_CATEGORY_NAME_EXISTING = 'menu.CategoryNameExisting';
const EMAIL_ALREADY_USED = 'parrot.EmailAlreadyUsed';
const PERMISSIONS_POSPIN_IS_REQUIRED = 'permissions.PosPinIsRequired';
const MENU_CIRCULAR_COPY = 'menu.CircularCopy';
const MENU_IS_COPY = 'menu.MenuIsCopy';
const MENU_COPY_EXISTING = 'menu.CopyExisting';
const MENU_STORE_GENERATE_COPIES = 'menu.StoreGenerateCopies';
const ONLINE_ORDERING_NOT_CONFIG_BRAND = 'onlineOrdering.onlineConfigNotFound';
const ONLINE_ORDERING_UNIQUE = 'unique';
const ONLINE_INVALID_IMAGE = 'invalid_image';
const STORE_NOT_FOUND = 'store.StoreNotFound';
const MENU_NAME_EXISTING = 'menu.MenuRepeated';
const MISSING_STORAGE_AREA = 'inventoryEvent.MissingAreas';
const INVALID_SUPPLY_PRODUCTION = 'inventoryEvent.InvalidSupplyProduction';

export const errorsCodeTypes = {
  PERMISSIONS_REPEATED_PIN,
  PERMISSIONS_EMAIL_AND_PASSWORD_IS_REQUIRED,
  MENU_CATEGORY_NAME_EXISTING,
  EMAIL_ALREADY_USED,
  PERMISSIONS_POSPIN_IS_REQUIRED,
  MENU_CIRCULAR_COPY,
  MENU_IS_COPY,
  MENU_COPY_EXISTING,
  MENU_STORE_GENERATE_COPIES,
  ONLINE_ORDERING_NOT_CONFIG_BRAND,
  ONLINE_ORDERING_UNIQUE,
  ONLINE_INVALID_IMAGE,
  STORE_NOT_FOUND,
  MENU_NAME_EXISTING,
  MISSING_STORAGE_AREA,
  INVALID_SUPPLY_PRODUCTION,
};

export function getErrorCode(errorsList, code) {
  let errorsObject = {};

  if (errorsList?.messages && errorsList?.messages?.length) {
    errorsObject = errorsList?.messages?.reduce((obj, item) => {
      return {
        ...obj,
        [item?.code]: item?.message,
      };
    }, {});

    return errorsObject[code];
  }

  return false;
}

/**
 * Validations keys
 * */
const VALIDATION_PHONE_NUMBER = 'phoneNumber';
const VALIDATION_PHONE = 'phone';

export const validationsTypesList = [VALIDATION_PHONE_NUMBER, VALIDATION_PHONE];

/**
 * Validations codes
 * */
const VALIDATION_CODE_INVALID_PHONE_NUMBER = 'invalid_phone_number';

export const validationsCodeTypes = {
  VALIDATION_CODE_INVALID_PHONE_NUMBER,
};

export const getFirstErrorFromApi = (error = {}) => {
  const { messages } = error;
  if (!messages) return {};
  if (!Array.isArray(messages)) return {};
  const [errorObjet] = messages;
  return errorObjet || {};
};
