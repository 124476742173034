import { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDebounce } from '@open-react-hooks/use-debounce';

import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';

import InputAdornmentFiled from '@design-system/InputAdornmentFiled';

import ButtonPlus from '@experimental-components/ButtonPlus';
import IconSearch from '@experimental-components/IconsComponents/SearchOutline';

import { TypeOfSaleFilterValues } from 'components/ProductForm/types';

import SelectCategories from './SelectCategories';
import TypeSoldProducts from './TypeSoldProducts';
import SelectBrands from './SelectBrands';
import SortByProducts from './SortByProducts';
import useStyles from './styles';

function HeaderProductsFilters({
  categoriesList,
  onAddButtonClick,
  onSearchBoxChange,
  onSelectedCategoriesChange,
  onSelectTypeSoldProductChange,
  onSelectBrandsChange,
  onSelectedOrderBy,
  openDrawer,
  ...others
}) {
  const classes = useStyles();
  const { t } = useTranslation('menuMaker');
  const { debounce } = useDebounce({ delay: 300 });

  const [, setFiltersLocals] = useState({
    filterByCategories: [],
    filterByBrands: [],
    page: 1,
    filterByText: null,
    orderBy: [],
  });

  function handleChange(event) {
    const { value } = event.target;

    if (onSearchBoxChange) {
      if (value) {
        setFiltersLocals((currentFilters) => {
          onSearchBoxChange({ ...currentFilters, filterByText: value });
          return { ...currentFilters, filterByText: value };
        });
      } else {
        setFiltersLocals((currentFilters) => {
          onSearchBoxChange({ ...currentFilters, filterByText: null });
          return { ...currentFilters, filterByText: null };
        });
      }
    }
  }

  function handleSelectedCategoriesChange(value, withoutCategories) {
    setFiltersLocals((currentFilters) => {
      onSelectedCategoriesChange({ ...currentFilters, filterByCategories: value, withoutCategories });
      return { ...currentFilters, filterByCategories: value, withoutCategories };
    });
  }

  function handleFilterByTypeSoldProductChange(value) {
    setFiltersLocals((currentFilters) => {
      onSelectTypeSoldProductChange({
        ...currentFilters,
        typeOfSale: value === TypeOfSaleFilterValues.ALL ? null : value,
      });
      return { ...currentFilters, typeOfSale: value === TypeOfSaleFilterValues.ALL ? null : value };
    });
  }

  function handleFilterByBrands(value, withoutBrands) {
    setFiltersLocals((currentFilters) => {
      onSelectBrandsChange({ ...currentFilters, filterByBrands: value, withoutBrands });
      return { ...currentFilters, filterByBrands: value, withoutBrands };
    });
  }

  function handleSortBy(value) {
    setFiltersLocals((currentFilters) => {
      onSelectedOrderBy({ ...currentFilters, orderBy: [value] });
      return { ...currentFilters, orderBy: [value] };
    });
  }

  return (
    <Box className={classes.root} {...others}>
      <Box className={classes.wrapperMultiSelect}>
        <SelectBrands onSelectedBrands={handleFilterByBrands} />
      </Box>

      <Box className={classes.wrapperMultiSelect}>
        <SelectCategories categories={categoriesList} onSelectedCategories={handleSelectedCategoriesChange} />
      </Box>

      <Box className={classes.wrapperMultiSelect}>
        <TypeSoldProducts onSelectTypeSoldProduct={handleFilterByTypeSoldProductChange} />
      </Box>

      <Box>
        <InputAdornmentFiled
          data-testid="searchBoxProducts"
          fullWidth
          hideLabelHelperTex
          id="searchBoxProducts"
          onChange={debounce(handleChange)}
          placeholder={t('menuMaker:headerForm.searchProduct')}
          startAdornment={
            <InputAdornment position="start">
              <IconSearch />
            </InputAdornment>
          }
        />
      </Box>
      <Box></Box>
      <Box className={classes.wrapperActions}>
        <SortByProducts data-testid="orderByProducts" id="orderByProducts" onSelectedSortBy={handleSortBy} />
        <ButtonPlus className={classes.middlePrimaryButton} data-testid="btnAddNewProduct" onClick={onAddButtonClick}>
          {!openDrawer ? t('menuMaker:buttons.newProduct') : ''}
        </ButtonPlus>
      </Box>
    </Box>
  );
}

HeaderProductsFilters.propTypes = {
  categoriesList: PropTypes.object,
  onAddButtonClick: PropTypes.func,
  onSearchBoxChange: PropTypes.func,
  onSelectedCategoriesChange: PropTypes.func,
  onSelectTypeSoldProductChange: PropTypes.func,
  onSelectBrandsChange: PropTypes.func,
  onSelectedOrderBy: PropTypes.func,
  openDrawer: PropTypes.bool,
};

export default HeaderProductsFilters;
