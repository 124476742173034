import { withStyles, Tab as TabMui, TabProps } from '@material-ui/core';

const TabBase = withStyles((theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 300,
    minWidth: theme.typography.pxToRem(125),
  },

  textColorPrimary: {
    color: theme.palette.common.black,
    '&$selected': {
      color: theme.palette.common.black,
    },
  },

  selected: {
    fontWeight: 500,
  },
}))(TabMui);

function Tab(props: TabProps) {
  return <TabBase disableFocusRipple disableRipple {...props} />;
}

export default Tab;
