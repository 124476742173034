import React from 'react';

import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import ButtonBase, { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import CircularProgress from '@material-ui/core/CircularProgress';

import useIconParrot from 'hooks/useIconParrot';

const useStyles = makeStyles((theme) => ({
  label: {
    width: '100%', // Ensure the correct width for iOS Safari
    display: 'inherit',
    alignItems: 'inherit',
    justifyContent: 'inherit',
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.common.black,

    '&:hover': {
      color: theme.palette.text.secondary,
    },
  },

  startIcon: {
    display: 'inherit',
    marginRight: 16,
    marginLeft: -4,

    '&$iconSizeSmall': {
      marginLeft: -2,
    },
  },

  disabledLabel: {
    color: theme.palette.darkGrey[15],
  },

  redLabel: {
    color: theme.palette.error.main,
  },
}));

interface Props extends ButtonBaseProps {
  children?: React.ReactNode;
  name: string;
  loading?: boolean;
  loadingText?: boolean;
  disabled?: boolean;
  isRed?: boolean;
}

function ButtonExportExcel({
  children,
  name,
  loading = false,
  loadingText = false,
  disabled = false,
  isRed = false,
  ...others
}: Props) {
  const classes = useStyles();

  const { IconExcel, IconExcelDisabled, IconExcelRed } = useIconParrot({
    icons: ['excel', 'excelDisabled', 'excelRed'],
  });

  const isRedIcon = isRed ? <IconExcelRed /> : <IconExcel />;
  const renderIcon = disabled ? <IconExcelDisabled /> : isRedIcon;

  return (
    <ButtonBase data-cy={name} disabled={disabled} disableRipple disableTouchRipple {...others}>
      <span
        className={clsx(classes.label, {
          [classes.disabledLabel]: disabled,
          [classes.redLabel]: isRed,
        })}
      >
        <span className={classes.startIcon}>
          {loading ? <CircularProgress color="inherit" size={18} /> : renderIcon}
        </span>

        {loading ? loadingText : children}
      </span>
    </ButtonBase>
  );
}

export default ButtonExportExcel;
