/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import ChevronDown from '@experimental-components/IconsComponents/ChevronDown';

import SelectProvider from './SelectProvider';
import TableMenusForBrand from './TableMenusForBrand';
import useStyles from './styles';

function RowBrand({ brand, openDefault = false, onConfirmPublish, onResetOptions, resetOptions, refScroll }) {
  const classes = useStyles();
  const [openRow, setOpenRow] = useState(openDefault);

  const handleOpenBrand = () => {
    setOpenRow((state) => !state);
  };

  return (
    <Box className={classes.rootRow}>
      <Box ref={refScroll} className={classes.headerRow} onClick={() => handleOpenBrand()}>
        <Box className={classes.labelRow}>{brand?.name}</Box>

        <Box className={classes.contentRightRow}>
          <SelectProvider
            brand={brand}
            disabled={!brand?.menus?.length}
            onResetProviderSelected={onResetOptions}
            onSelectProvider={onConfirmPublish}
            resetProviderSelected={resetOptions}
          />

          <ChevronDown
            className={clsx(classes.iconInitialState, { [classes.iconRotate]: openRow })}
            color="#000"
            lighter
            size={24}
          />
        </Box>
      </Box>
      <Collapse in={openRow}>
        <TableMenusForBrand menus={brand?.menus} />
      </Collapse>
    </Box>
  );
}

RowBrand.propTypes = {
  brand: PropTypes.object,
  openDefault: PropTypes.bool,
  refScroll: PropTypes.object,
};

export default RowBrand;
