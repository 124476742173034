/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { useListsOptions } from 'providers/root/ListsOptionsProvider';
import { useElementEdited } from 'containers/MenuMakerResume/ProviderEdition';
import useMenuMakerDrawerState from 'hooks/useMenuMakerDrawerState';
import useNavigation from 'hooks/navigation/useNavigation';

import DrawerMenuMaker from 'components/DrawerMenuMaker';
import CategoryForm from 'components/CategoryForm';
import SelectProducts from 'components/SelectProducts';
import ModalConfirm from 'components/ModalConfirm';
import SelectSKU from 'components/SelectSKU';
import ModalValidateUsedSku from 'components/ModalValidateUsedSku';

import { setSortingPosition } from 'utils/sorting';
import { getKitchenAreaDefault, getKitchenAreasAvailable } from 'utils/kitchenAreas/makeKitchenAreasFields';
import {
  defaultCategory,
  categoryFormIsDirty,
  categoryFormIsValid,
  defaultCategoryObject,
  getMenusOfFields,
  getUuidProducts,
  validateProductsField,
  getCategoryMenus,
} from 'utils/categories';

import { generateSkuValue, validateDuplicateSkuItems } from 'utils/menuMaker';

function CategoryDrawer({
  actionState,
  categoryState,
  focusInProducts,
  isEdition,
  kitchenAreasState,
  menuOfCategory,
  menusState,
  onLoadCategoryDetails,
  onLoadKitchenAreas,
  onLoadMenus,
  onResetCategoryDetails,
  onResetGetKitchenAreas,
  onResetGetMenus,
  onSubmit,
  openDrawer,
  productsCategoryState,
  uuidSelected,
}) {
  const [initCategory, setInitCategory] = useState(defaultCategory);
  const [productsSelected, setProductsSelected] = useState([]);
  const [productsSelectedTmp, setProductsSelectedTpm] = useState([]);
  const [step, setStep] = useState(1);
  const [nextPage, setNextPage] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [skuSelectedTmp, setSkuSelectedTmp] = useState(null);
  const [openValidateSku, setOpenValidateSku] = useState(false);

  const unblockRef = useRef();
  const { setKeepOpen, setDrawerIsDirty } = useMenuMakerDrawerState();
  const { setReloadStoreOnClose, setChangeLocationState, setGoToMenuDraft } = useNavigation();
  const { productsOptionsState } = useListsOptions();
  const { setElementEdited } = useElementEdited();

  const { t } = useTranslation();
  const history = useHistory();
  const methods = useForm({ mode: 'onChange', defaultValues: { kitchenAreasFields: [] } });
  const isLoadingForm =
    kitchenAreasState.fetching || menusState.fetching || productsCategoryState.fetching || categoryState.fetching;

  const watchFields = methods.watch();
  const isFormDirty =
    categoryFormIsDirty(initCategory, { ...watchFields, products: productsSelected }) && !isLoadingForm;
  const isFormValid = categoryFormIsValid(methods.formState, watchFields?.menusField);
  const isProductsFieldValid = validateProductsField(productsSelectedTmp, productsSelected);
  const isProductFieldSkuDuplicate = validateDuplicateSkuItems(productsSelected);

  useEffect(() => {
    if (menusState.fetched && openDrawer && menuOfCategory) {
      if (menusState.list?.length && !menusState.list?.some((item) => item?.uuid === menuOfCategory)) {
        handleCloseDrawer();
      }
    }
  }, [menusState.fetched]);

  useEffect(() => {
    if (openDrawer) {
      onLoadMenus();
      onLoadKitchenAreas();
    }

    return () => {
      onResetGetKitchenAreas();
      onResetGetMenus();
      onResetCategoryDetails();
    };
  }, [openDrawer]);

  useEffect(() => {
    onResetCategoryDetails();

    if (isEdition) {
      onLoadCategoryDetails();
    }
  }, [uuidSelected]);

  useEffect(() => {
    if (menuOfCategory && watchFields.menusField && !watchFields.menusField[menuOfCategory]) {
      setStep(1);
      methods.reset({ ...initCategory, menusField: { [menuOfCategory]: menuOfCategory } });
      setInitCategory({ ...initCategory, menusField: { [menuOfCategory]: menuOfCategory } });
      setSkuSelectedTmp(initCategory?.skuFieldMultiStore);
    }
  }, [menuOfCategory, watchFields.menusField]);

  useEffect(() => {
    let categoryValues = { ...defaultCategory };
    setStep(1);

    if (kitchenAreasState.fetched) {
      if (isEdition) {
        if (!isEmpty(categoryState.data) && productsCategoryState.fetched) {
          const { data } = categoryState;
          const skuValue = data?.sku?.slice(0, 3) === 'CA-' ? data?.sku.slice(3) : data?.sku;

          categoryValues = {
            nameCategoryField: data?.name,
            menusField: getCategoryMenus(data?.menus),
            kitchenAreasFields: getKitchenAreasAvailable(data.kitchenAreasAssignation, kitchenAreasState.list),
            colorField: data?.color || 'FFFFFF',
            skuFieldMultiStore: skuValue ?? '',
          };

          setInitCategory({ ...categoryValues, products: productsCategoryState.list });
          setProductsSelected(productsCategoryState.list);
          setSkuSelectedTmp(skuValue ?? '');
        }
      } else {
        const defaultKitchenArea = getKitchenAreaDefault(kitchenAreasState.list);
        const kitchenAreasArray = defaultKitchenArea?.uuid ? [defaultKitchenArea.uuid] : [];
        const categorySKU = generateSkuValue();

        categoryValues = {
          ...defaultCategory,
          kitchenAreasFields: kitchenAreasArray,
          skuFieldMultiStore: categorySKU,
        };
        setInitCategory({ ...categoryValues, products: [] });
        setProductsSelected([]);
        setSkuSelectedTmp(categorySKU);
      }
    }

    const fillForm = async () => {
      await methods.reset(categoryValues);
      if (isEdition) methods.trigger();
    };

    fillForm();
  }, [isEdition, categoryState.data, kitchenAreasState.fetched, productsCategoryState.fetched]);

  useEffect(() => {
    setSkuSelectedTmp(watchFields?.skuFieldMultiStore);
  }, [watchFields?.skuFieldMultiStore]);

  useEffect(() => {
    setDrawerIsDirty(isFormDirty);
    if (!openDrawer) {
      setKeepOpen(false);
      setDrawerIsDirty(false);
      setReloadStoreOnClose(false);
    }

    if (openDrawer && isFormDirty) {
      const unblock = history.block((destination) => {
        setNextPage(destination);

        setOpenConfirmationModal(isFormDirty);
        if (!isFormDirty) setKeepOpen(false);

        return !isFormDirty;
      });
      unblockRef.current = unblock;

      return () => {
        unblock();
      };
    }
  }, [isFormDirty, openDrawer]);

  useEffect(() => {
    if (actionState.fetched) {
      if (setElementEdited) setElementEdited(true);
      unblockNavigation();
    }
  }, [actionState.fetched]);

  const handleCloseDrawer = () => {
    history.replace({ search: '' });
  };

  const handleGoBack = () => {
    setProductsSelectedTpm([]);
    setSkuSelectedTmp(watchFields?.skuFieldMultiStore);
    setStep(1);
  };

  const handleSaveCategory = () => {
    setNextPage(null);
    const data = watchFields;
    const menusOfCategories = getMenusOfFields(data?.menusField);

    onSubmit({
      ...defaultCategoryObject,
      name: data?.nameCategoryField,
      menus: menusOfCategories,
      kitchenAreas: data.kitchenAreasFields,
      products: getUuidProducts(productsSelected),
      color: data?.colorField,
      sku: `CA-${data?.skuFieldMultiStore}`,
    });
  };

  const handleSelectProducts = (values) => {
    setProductsSelectedTpm(setSortingPosition(values));
  };

  const handleAcceptProducts = () => {
    setProductsSelected(productsSelectedTmp);
    setStep(1);
  };

  const unblockNavigation = () => {
    setKeepOpen(false);
    if (unblockRef.current) unblockRef.current();
    unblockRef.current = null;
    setOpenConfirmationModal(false);

    if (nextPage?.pathname === history.location?.pathname && nextPage?.search === history.location?.search) {
      setStep(1);
      methods.reset(initCategory);
      setSkuSelectedTmp(initCategory.skuFieldMultiStore);
    }

    history.replace({ pathname: nextPage?.pathname, search: nextPage?.search });
    setReloadStoreOnClose(true);
  };

  const handleCancel = () => {
    setChangeLocationState(false);
    setReloadStoreOnClose(false);
    setGoToMenuDraft(false);
    setKeepOpen(true);
    setNextPage(null);
    setOpenConfirmationModal(false);
  };

  const handleAcceptSKU = () => {
    methods.setValue('skuFieldMultiStore', skuSelectedTmp, { shouldValidate: true });
    setStep(1);
  };

  const handleSelectSku = (value) => {
    setSkuSelectedTmp(value ?? '');
  };

  const handleValidateSku = () => {
    setOpenValidateSku(true);
  };

  const handleCloseValidateSku = () => {
    setOpenValidateSku(false);
  };

  return (
    <>
      <FormProvider {...methods}>
        <DrawerMenuMaker currentStep={step} open={openDrawer}>
          <DrawerMenuMaker.MainView
            disabled={
              !(isFormDirty && isFormValid) ||
              kitchenAreasState.fetching ||
              menusState.fetching ||
              actionState.fetching ||
              openValidateSku ||
              isProductFieldSkuDuplicate?.length > 0
            }
            labelAction={t('common:buttons.save')}
            loading={actionState.fetching || openValidateSku}
            onAction={handleValidateSku}
            onClose={openDrawer ? handleCloseDrawer : undefined}
            step={1}
          >
            <CategoryForm
              focusInProducts={focusInProducts}
              isEdition={isEdition}
              isLoaded={
                kitchenAreasState.fetched &&
                menusState.fetched &&
                (isEdition ? categoryState.fetched && productsCategoryState.fetched : true) &&
                !menusState.fetching
              }
              isLoading={
                kitchenAreasState.fetching ||
                menusState.fetching ||
                productsCategoryState.fetching ||
                categoryState.fetching
              }
              kitchenAreasState={kitchenAreasState}
              menuOfCategory={menuOfCategory}
              menusState={menusState}
              onChangeProductsOrder={setProductsSelected}
              onSetStep={setStep}
              optionsSelected={productsSelected}
            />
          </DrawerMenuMaker.MainView>

          <DrawerMenuMaker.Step
            disabled={productsOptionsState.fetching || !isProductsFieldValid}
            labelAction={t('common:buttons.accept')}
            onAction={handleAcceptProducts}
            onGoBack={handleGoBack}
            step={2}
            t={t}
          >
            <SelectProducts
              description={t('common:addProducts.descriptionCategories')}
              onSelectProducts={handleSelectProducts}
              optionsSelected={productsSelected}
              t={t}
            />
          </DrawerMenuMaker.Step>

          <DrawerMenuMaker.Step
            disabled={watchFields?.skuFieldMultiStore === skuSelectedTmp}
            labelAction={t('common:buttons.accept')}
            onAction={handleAcceptSKU}
            onGoBack={handleGoBack}
            step={3}
            t={t}
          >
            <SelectSKU
              onSelectOption={handleSelectSku}
              optionSelected={watchFields?.skuFieldMultiStore}
              t={t}
              type="category"
            />
          </DrawerMenuMaker.Step>
        </DrawerMenuMaker>
      </FormProvider>

      {openConfirmationModal && (
        <ModalConfirm
          labelAccept={t('common:buttons.accept')}
          labelCancel={t('common:buttons.cancel')}
          message={t('menuMaker:messages.exitNoSaveMessage')}
          onAccept={unblockNavigation}
          onCancel={handleCancel}
          open={openConfirmationModal}
          title={t('menuMaker:messages.exitNoSaveTitle')}
        />
      )}

      {openValidateSku && (
        <ModalValidateUsedSku
          actionState={actionState}
          itemUuid={uuidSelected}
          onAccept={handleSaveCategory}
          onClose={handleCloseValidateSku}
          sku={`CA-${watchFields?.skuFieldMultiStore}`}
          t={t}
          type="category"
        />
      )}
    </>
  );
}

CategoryDrawer.propTypes = {
  actionState: PropTypes.object,
  categoryState: PropTypes.object,
  focusInProducts: PropTypes.bool,
  isEdition: PropTypes.bool,
  kitchenAreasState: PropTypes.object,
  menuOfCategory: PropTypes.string,
  menusState: PropTypes.object,
  onLoadCategoryDetails: PropTypes.func,
  onLoadKitchenAreas: PropTypes.func,
  onLoadMenus: PropTypes.func,
  onResetGetKitchenAreas: PropTypes.func,
  onResetGetMenus: PropTypes.func,
  onSubmit: PropTypes.func,
  onResetCategoryDetails: PropTypes.func,
  openDrawer: PropTypes.bool,
  productsCategoryState: PropTypes.object,
  uuidSelected: PropTypes.string,
};

export default CategoryDrawer;
