import withStyles from '@material-ui/core/styles/withStyles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const ButtonPrimaryBase = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(18),
    border: 'none',
    lineHeight: 1.5,
    height: theme.typography.pxToRem(56),
    minWidth: theme.typography.pxToRem(133),
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,

    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      boxShadow: 'none',
    },

    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.error.dark,
    },

    '&:focus': {
      border: `1px solid ${theme.palette.error.dark}`,
    },

    '&$disabled': {
      color: theme.palette.common.white,
    },
  },

  disabled: {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.common.white,
  },
}))(Button);

interface Props extends ButtonProps {
  loading?: boolean;
}

function ButtonAlert({ loading, ...others }: Props) {
  return (
    <ButtonPrimaryBase
      disableElevation
      disableRipple
      endIcon={loading && <CircularProgress color="inherit" size={18} />}
      {...others}
    />
  );
}

export default ButtonAlert;
