import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

const defaultWrapperProps = {
  mb: 5,
  pl: 4,
  pr: 4,
};

function TitleSection({ title, description = '', wrapperProps = defaultWrapperProps }) {
  return (
    <Box {...wrapperProps}>
      <Box fontSize={24} fontWeight="fontWeightMedium">
        {title}
      </Box>
      <Box
        color="darkGrey.main"
        fontSize={16}
        fontWeight="fontWeightRegular"
        mb={3}
        mt={1}
        style={{ whiteSpace: 'break-spaces' }}
      >
        {description}
      </Box>
    </Box>
  );
}

TitleSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  wrapperProps: PropTypes.object,
};

export default TitleSection;
