import ButtonExportExcel from '@design-system/ButtonExportExcel';
import useNotification from '@design-system/Notification/useNotification';
import { useTranslation } from 'react-i18next';
import downloadFile from 'utils/download-file';

interface Props {
  name: string;
  fileName: string;
  disabled?: boolean;
  isVisible: boolean;
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutateAsync: (value?: any) => Promise<Blob>;
}
function ExportToExcelButton({ name, fileName, disabled = false, isVisible = true, isLoading, mutateAsync }: Props) {
  const { setNotification } = useNotification();
  const { t } = useTranslation('reports');

  async function onExportExcel() {
    try {
      const blobFile = await mutateAsync();
      downloadFile(blobFile, { name: fileName });
      setNotification('Reporte generado, revisa tus decargas.', { variant: 'success' });
    } catch (error) {
      setNotification('No pudimos generar tu reporte. Intenta de nuevo.', { variant: 'error' });
    }
  }

  if (!isVisible) {
    return <></>;
  }

  return (
    <ButtonExportExcel
      disabled={disabled}
      loading={isLoading}
      loadingText={t('reports:messages.exportToExcelLoadingText')}
      name={name}
      onClick={onExportExcel}
    >
      {t('reports:buttons.exportToExcel')}
    </ButtonExportExcel>
  );
}

export default ExportToExcelButton;
