import { takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { datadogLogs } from '@datadog/browser-logs';

import { authTokenRequest } from 'utils/api/baas/auth';
import {
  setAuthToken,
  setCookieUser,
  setUserStoresStoreKey,
  setUserCurrentStore,
  setOrganizationUser,
  setStoreDraftUser,
  removeStoreDratfKey,
  removeStoreBackupKey,
} from 'utils/auth';

import { logEventRequest, setUserPropertyRequest } from 'providers/root/AmplitudeProvider/helpers';

import { initSignIn } from './actions';

export function* handleInitSignInRequest(action) {
  const { user, password } = action.payload;

  try {
    removeStoreDratfKey();
    removeStoreBackupKey();
    yield put(initSignIn.request());

    const { session, profile, stores, organization, storeDraft } = yield call(authTokenRequest, {
      user,
      password,
    });

    if (!stores?.length) {
      yield put(
        push({
          pathname: `/without-store`,
        }),
      );
    }

    setAuthToken({
      accessToken: session.accessToken,
      refreshToken: session.refreshToken,
    });

    const auth = getAuth();
    signInWithCustomToken(auth, session?.firebaseToken ?? '').catch((error) => {
      datadogLogs.logger.error('firebase_auth', { error });
    });

    const storesTmp = stores?.map((store) => ({ ...store, isDraft: false }));

    setUserCurrentStore(storesTmp[0]);

    if (storeDraft) {
      setStoreDraftUser({ ...storeDraft, isDraft: true });
    }

    setUserStoresStoreKey(storesTmp);

    setCookieUser(profile);

    logEventRequest('@event.$login.login');
    setUserPropertyRequest({ email: user });
    setOrganizationUser(organization);

    yield put(
      initSignIn.success({
        profile,
        storesTmp,
      }),
    );
  } catch (err) {
    logEventRequest('@event.$login.loginFail');
    setUserPropertyRequest({ email: user });
    yield put(initSignIn.failure(err));
  }
}

export default function* signInProviderSaga() {
  yield takeLatest(initSignIn.TRIGGER, handleInitSignInRequest);
}
