import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import camelCase from 'lodash/camelCase';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Table from '@experimental-components/Table';
import ChanelItem from 'components/ChanelItem';

import useStyles from './styles';

function TableMenusForBrand({ menus }) {
  const { t } = useTranslation('menuMaker');
  const classes = useStyles();

  function renderBody() {
    const menusNotDraf = menus?.filter((menu) => menu?.providers?.length > 0);

    if (!menus?.length || !menusNotDraf?.length) {
      return (
        <Table.Row>
          <Table.Cell colSpan="4">Sin Registros</Table.Cell>
        </Table.Row>
      );
    }

    return menus?.map((menu, index) => (
      <Table.Row key={menu?.uuid}>
        <Table.Cell className={classes.tdName} isBorder={index !== 0}>
          {menu?.name}
        </Table.Cell>

        <Table.BigCell align="left" className={classes.td} component="td" isBorder={index !== 0} scope="row">
          {menu?.categories?.length > 0 ? (
            <Table.BigCell.ExpandableList
              defaultItems={6}
              list={menu?.categories?.map((category) => (
                <Table.BigCell.Option key={category?.uuid}>{category?.category?.name}</Table.BigCell.Option>
              ))}
            />
          ) : (
            t('common:notAvailableDefault')
          )}
        </Table.BigCell>

        <Table.BigCell align="left" className={classes.td} component="td" isBorder={index !== 0} scope="row">
          {menu?.providers?.length > 0
            ? menu?.providers.map((provider) => (
                <Box key={provider?.uuid} className={classes.publishProvider}>
                  <ChanelItem
                    key={camelCase(`${menu?.uuid}-${provider?.providerName || provider}`)}
                    idRef={`-${menu?.uuid}-${provider?.providerName || provider}`}
                    size={24}
                    value={provider?.providerName || provider}
                  />
                  <Typography>{provider?.lastPublishDate}</Typography>
                </Box>
              ))
            : t('common:notAvailableDefault')}
        </Table.BigCell>
      </Table.Row>
    ));
  }

  return (
    <Box mb={2}>
      <Table aria-label="menu maker table for brand">
        <Table.Head>
          <Table.Row>
            <Table.HeadCell align="left">{t('menuMaker:menuMakerTable.columns.name')}</Table.HeadCell>
            <Table.HeadCell align="left">{t('menuMaker:menuMakerTable.columns.categories')}</Table.HeadCell>
            <Table.HeadCell align="left">{t('menuMaker:menuMakerTable.columns.lastPublish')}</Table.HeadCell>
          </Table.Row>
        </Table.Head>

        <Table.Body>{renderBody()}</Table.Body>
      </Table>
    </Box>
  );
}

TableMenusForBrand.propTypes = {
  menus: PropTypes.array,
};

export default TableMenusForBrand;
