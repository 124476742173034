import { put, call, take, cancel, fork } from 'redux-saga/effects';
import { saveAs } from 'file-saver';

import baasRequest from 'utils/baasRequest';
import { getReportsRequest, exportReportsRequest, getReportDetailsRequest } from 'utils/reports';

import { logEventRequest } from 'providers/root/AmplitudeProvider/helpers';

import {
  getReports,
  getReportsByPagination,
  exportReportsToExcel,
  getReportsProductDetails,
  getReportsBySorting,
  getPaginationProductDetails,
} from './actions';

import {
  takeReportsSessionsDetails,
  takeExportReportsToExcelDetails,
  takeReportsSessions,
  takeReportsSessionsDetailsPagination,
  takeReportsSessionsPagination,
  takeReportsSessionsDetailsAll,
} from './sagas/cashRegister';

export function* handleGetReportsRequest({ payload: { key, type, reportFormat, ...filters } }) {
  const { startDate, endDate, brands, providers, orderTypes, categories, withoutCategories } = filters;

  yield put(getReports.request({ key }));

  try {
    const { data, pagination } = yield call(baasRequest, getReportsRequest, type, key, {
      reportFormat,
      startDate,
      endDate,
      brands,
      providers,
      orderTypes,
      categories,
      withoutCategories,
    });

    logEventRequest('@event.$reports.viewReport', ['@@type.complete', '@@section.sales']);
    yield put(getReports.success({ key, reports: data, pagination }));
  } catch (err) {
    yield put(getReports.failure({ error: err, key }));
  } finally {
    yield put(getReports.fulfill());
  }
}

export function* handleGetReportsByPaginationRequest({ payload: { key, type, page, ...filters } }) {
  const { startDate, endDate, brands, providers, orderTypes, categories, orderBy } = filters;
  yield put(getReportsByPagination.request({ key, page }));

  try {
    const { data, pagination } = yield call(baasRequest, getReportsRequest, type, key, {
      page,
      startDate,
      endDate,
      brands,
      providers,
      orderTypes,
      categories,
      orderBy,
    });
    yield put(getReportsByPagination.success({ key, reports: data, pagination }));
  } catch (err) {
    yield put(getReportsByPagination.failure({ error: err, key }));
  } finally {
    yield put(getReportsByPagination.fulfill());
  }
}

export function* handleGetReportsBySortingRequest({ payload: { key, type, reportFormat, ...filters } }) {
  const { startDate, endDate, brands, providers, orderTypes, categories, orderBy } = filters;

  yield put(getReportsBySorting.request({ key }));

  try {
    const { data, pagination } = yield call(baasRequest, getReportsRequest, type, key, {
      reportFormat,
      startDate,
      endDate,
      brands,
      providers,
      orderTypes,
      categories,
      orderBy,
    });

    yield put(getReportsBySorting.success({ key, reports: data, pagination }));
  } catch (err) {
    yield put(getReportsBySorting.failure({ error: err, key }));
  } finally {
    yield put(getReportsBySorting.fulfill());
  }
}

export function* handleExportReportsToExcelRequest({ payload: { key, type, nameFile, ...filters } }) {
  const { startDate, endDate, brands, providers, orderTypes, categories, withoutCategories } = filters;

  yield put(exportReportsToExcel.request({ key }));

  try {
    const responseFile = yield call(baasRequest, exportReportsRequest, type, key, {
      startDate,
      endDate,
      brands,
      providers,
      orderTypes,
      categories,
      withoutCategories,
    });
    logEventRequest('@event.$reports.exportReport', '@@type.sales');
    yield saveAs(responseFile, nameFile);
    yield put(exportReportsToExcel.success({ key, document: responseFile }));
  } catch (err) {
    yield put(exportReportsToExcel.failure({ error: err, key }));
  } finally {
    yield put(exportReportsToExcel.fulfill());
  }
}

export function* handleGetReportsProductDetailsRequest({ payload: { key, type, ...filters } }) {
  const { orderBy, page } = filters;

  yield put(getReportsProductDetails.request({ key, orderBy }));

  try {
    const { data: product, pagination } = yield call(baasRequest, getReportDetailsRequest, type, key, { ...filters });
    logEventRequest('@event.$reports.viewReportDetail', '@@section.sales');
    yield put(getReportsProductDetails.success({ key, product, pagination: { ...pagination, page } }));
  } catch (err) {
    yield put(getReportsProductDetails.failure({ error: err, key }));
  } finally {
    yield put(getReportsProductDetails.fulfill());
  }
}

export function* getPaginationProductDetailsSaga({ payload }) {
  try {
    const { key, type, ...filters } = payload;

    yield put(getPaginationProductDetails.request({ key }));

    const { data: product, pagination } = yield call(baasRequest, getReportDetailsRequest, type, key, { ...filters });
    yield put(
      getPaginationProductDetails.success({
        key,
        product,
        pagination: { ...pagination, page: payload.page },
      }),
    );
  } catch (error) {
    yield put(getPaginationProductDetails.failure({ error, key }));
  }
}

export function* takeReports() {
  const tasks = {};

  while (true) {
    const action = yield take(getReports.TRIGGER);
    const { key } = action.payload;

    if (tasks[key]) {
      yield cancel(tasks[key]);
    }

    tasks[key] = yield fork(handleGetReportsRequest, action);
  }
}

export function* takeReportsByPagination() {
  const tasks = {};

  while (true) {
    const action = yield take(getReportsByPagination.TRIGGER);
    const { key } = action.payload;

    if (tasks[key]) {
      yield cancel(tasks[key]);
    }

    tasks[key] = yield fork(handleGetReportsByPaginationRequest, action);
  }
}

export function* takeExportReportsToExcel() {
  const tasks = {};

  while (true) {
    const action = yield take(exportReportsToExcel.TRIGGER);
    const { key } = action.payload;

    if (tasks[key]) {
      yield cancel(tasks[key]);
    }

    tasks[key] = yield fork(handleExportReportsToExcelRequest, action);
  }
}
export function* takeReportsProductDetails() {
  const tasks = {};

  while (true) {
    const action = yield take(getReportsProductDetails.TRIGGER);
    const { key } = action.payload;

    if (tasks[key]) {
      yield cancel(tasks[key]);
    }

    tasks[key] = yield fork(handleGetReportsProductDetailsRequest, action);
  }
}

export function* takePaginationProductDetails() {
  const tasks = {};

  while (true) {
    const action = yield take(getPaginationProductDetails.TRIGGER);
    const { key } = action.payload;

    if (tasks[key]) {
      yield cancel(tasks[key]);
    }

    tasks[key] = yield fork(getPaginationProductDetailsSaga, action);
  }
}

export function* takeReportsBySorting() {
  const tasks = {};

  while (true) {
    const action = yield take(getReportsBySorting.TRIGGER);
    const { key } = action.payload;

    if (tasks[key]) {
      yield cancel(tasks[key]);
    }

    tasks[key] = yield fork(handleGetReportsBySortingRequest, action);
  }
}

export default function* reportsSaga() {
  yield fork(takeReportsSessions);
  yield fork(takeReportsSessionsPagination);
  yield fork(takeReportsSessionsDetails);
  yield fork(takeExportReportsToExcelDetails);
  yield fork(takeReports);
  yield fork(takeReportsProductDetails);
  yield fork(takeReportsByPagination);
  yield fork(takeExportReportsToExcel);
  yield fork(takeReportsSessionsDetailsPagination);
  yield fork(takeReportsSessionsDetailsAll);
  yield fork(takeReportsBySorting);
  yield fork(takePaginationProductDetails);
}
