import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { selectBrands } from 'providers/root/BrandsProvider/selectors';

import { createHeaders } from 'utils/baasRequest';
import {
  getMenuMakerMenusRequest,
  sortingPositionMenusRequest,
  menuCopyRequest,
  menuSyncRequest,
  getOrganizationStoreBrandsRequest,
  getValidationCopyMenuRequest,
} from 'utils/api/baas/menuMaker';

export function useSortingPositionMenus() {
  return useMutation(sortingPositionMenusRequest);
}

export function useMenuCopyMutation() {
  return useMutation((values) => menuCopyRequest(values), { mutationKey: 'menu-copy' });
}

export function useMenuSyncMutation() {
  return useMutation((values) => menuSyncRequest(values), { mutationKey: 'menu-sync' });
}

export function useGetMenusCatalog({ storeUuid }) {
  const { data, isFetching, isSuccess, isError, refetch, remove } = useQuery(
    ['allMenus', storeUuid],
    () => getMenuMakerMenusRequest({ storeUuid }, { headers: createHeaders() }),
    {
      initialData: { data: [] },
      select: (response) => response.data,
      enabled: false,
    },
  );

  const brands = useSelector(selectBrands);
  const getAllMenusForBrand = () => {
    const menusBrandsList = JSON.parse(JSON.stringify(brands));

    menusBrandsList.forEach((brand, index) => {
      const menuListFilterBrand = [...data].filter((menu) => menu?.brand?.uuid === brand?.uuid);
      const providersBrand = [];

      menuListFilterBrand?.forEach((menu) => {
        menu?.providers?.forEach((provider) => {
          if (!providersBrand.includes(provider?.providerName)) {
            providersBrand.push(provider?.providerName);
          }
        });
      });
      menusBrandsList[index].menus = menuListFilterBrand;
      menusBrandsList[index].providers = providersBrand;
    });

    return menusBrandsList;
  };

  return {
    allMenus: data,
    getAllMenusFetching: isFetching,
    getAllMenusFetched: isSuccess,
    getAllMenusError: isError,
    getAllMenus: refetch,
    resetGetAllMenus: remove,
    get allMenusForBrand() {
      return getAllMenusForBrand();
    },
  };
}

export function useGetOrganizationStoresBrands({
  includeMenus = false,
  includeBrands = false,
  enabledQuery = false,
} = {}) {
  const { data, isFetching, isFetched, isError, refetch, remove } = useQuery(
    ['organizationStoresBrands'],
    () => getOrganizationStoreBrandsRequest({ includeMenus, includeBrands }, { headers: createHeaders() }),
    {
      initialData: { data: [] },
      select: (response) => response.data,
      enabled: enabledQuery,
    },
  );

  return {
    organizationStoreBrands: data,
    organizationStoreBrandsState: { fetching: isFetching, fetched: isFetched, error: isError },
    getOrganizationStoreBrands: refetch,
    resetGetOrganizationStoreBrands: remove,
  };
}

export function useGetValidateCopyMeny(menu) {
  const { data, isFetching, isFetched, isError, error, refetch, remove } = useQuery(
    ['validateCopyMenus'],
    () => getValidationCopyMenuRequest({ menuUuid: menu?.uuid }, { headers: createHeaders() }),
    {
      initialData: { data: [] },
      select: (response) => response.data,
      enabled: false,
      retry: false,
    },
  );

  return {
    validateCopyMenu: data,
    validateCopyMenuState: { fetching: isFetching, fetched: isFetched, error: isError },
    dataError: { messages: error?.messages, validations: error?.validations },
    getValidateCopyMenu: refetch,
    resetValidateCopyMenu: remove,
  };
}
