import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  rootRow: {
    borderBottom: `1px solid ${theme.palette.darkGrey[15]}`,
    padding: '1.5rem 0',
  },

  headerRow: {
    padding: '1rem 2rem',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },

  labelRow: {
    textAlign: 'start',
    fontSize: '1.5rem',
    fontWeight: theme.typography.fontWeightMedium,
  },

  iconInitialState: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  iconRotate: {
    transform: 'rotate(180deg)',
  },

  contentRightRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: theme.spacing(1.2),
    '& > :last-child': {
      marginLeft: theme.spacing(3.5),
    },
  },

  tdName: {
    width: '40%',
    fontWeight: theme.typography.fontWeightMedium,
  },

  td: {
    width: '30%',
  },

  publishProvider: {
    display: 'flex',
    alignItems: 'center',
    '& > :first-child': {
      marginRight: theme.spacing(2),
    },
  },
}));

export default useStyles;
