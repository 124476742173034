import { forwardRef } from 'react';

import { SnackbarContent, withSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import IconSuccess from '@experimental-components/IconsComponents/Success';
import IconWarning from '@experimental-components/IconsComponents/Warning';
import IconClose from '@experimental-components/IconsComponents/Close';

const defaultIconMapping = {
  success: <IconSuccess style={{ margin: 'auto' }} />,
  warning: <IconWarning style={{ margin: 'auto' }} />,
  error: <IconClose color="#DF130E" style={{ margin: 'auto' }} />,
  info: <IconClose style={{ margin: 'auto' }} />,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    padding: 0,
    borderRadius: theme.shape.borderRadius,
    borderColor: ({ severity }) => `${theme.palette[severity].main}`,
    borderStyle: 'solid',
    borderLeftWidth: 0,
    borderRightWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    minWidth: 536,
    color: theme.palette.text.secondary,
  },

  alertIcon: {
    backgroundColor: ({ severity }) => `${theme.palette[severity].light}`,
    borderColor: ({ severity }) => `${theme.palette[severity].main}`,
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    padding: '24px 16px',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    margin: 0,
    height: '100%',
  },

  alertMessage: {
    padding: '12px 16px',
  },
}));

const Notification = forwardRef(function Notification(props, ref) {
  const { id, severity = 'success', message: messageConfigProp, ...others } = props;
  const classes = useStyles({ severity: messageConfigProp?.options?.variant || severity });

  return (
    <SnackbarContent ref={ref}>
      <Alert
        classes={{
          root: classes.root,
          outlinedSuccess: classes.alertOutlinedSuccess,
          icon: classes.alertIcon,
          message: classes.alertMessage,
        }}
        iconMapping={defaultIconMapping}
        id={id}
        severity={messageConfigProp?.options?.variant || severity}
        variant="outlined"
        {...others}
      >
        {messageConfigProp?.options?.title && <AlertTitle> {messageConfigProp?.options?.title}</AlertTitle>}
        {messageConfigProp?.message}
      </Alert>
    </SnackbarContent>
  );
});

Notification.propTypes = {
  ...Alert.propTypes,
};

export default withSnackbar(Notification);
