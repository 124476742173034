import dayjs from 'dayjs';

import { api } from 'config';

import { Response } from 'types';

import { subscriptionStatusTypes } from 'utils/subscription/statusTypes';

import { ActiveLicense } from './types';

export async function getActiveLicense(options: { storeUuid?: string }) {
  if (!options.storeUuid) return null;
  const result = await api.get<Response<ActiveLicense>>('/v1/subscriptions/active-license/', {
    params: {
      store_uuid: options.storeUuid,
    },
  });

  const responseData = result.data.data;

  const licenseExtesionAllowed =
    (responseData?.status === subscriptionStatusTypes.UNAVAILABLE ||
      responseData?.status === subscriptionStatusTypes.PAYMENT_REQUIRED) &&
    responseData?.extensionConnect > 0 &&
    dayjs(responseData?.webCutoffDate).add(responseData.extensionConnect, 'day') > dayjs(responseData?.serverDate);

  const licenseStatus = licenseExtesionAllowed ? subscriptionStatusTypes.AVAILABLE : responseData?.status;

  const response = { ...responseData, status: licenseStatus, licenseExtesionAllowed };

  return response;
}
