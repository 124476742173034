import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';

import { alignType } from './types';

interface Props {
  children: React.ReactNode;
  paddingCell?: number;
  align?: alignType;
}

const HeadCellRoot = styled(Box)(({ theme }) => ({
  color: theme.palette.darkGrey.main,
  fontSize: '1rem',
  fontWeight: 500,
  paddingBlock: '0.5rem',
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
}));

const HeadCell = ({ children, paddingCell = 2, align = 'center', ...others }: Props) => {
  return (
    <HeadCellRoot aria-label="HeadCell" justifyContent={align} padding={paddingCell} {...others}>
      {children}
    </HeadCellRoot>
  );
};

export default HeadCell;
