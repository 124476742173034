import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';

function Title({ children }) {
  return children;
}

function CategorySkeleton({ isEdition, children }) {
  return (
    <Grid container data-testid="categorySkeleton" direction="column" spacing={3}>
      <Grid item>{children}</Grid>

      {isEdition && (
        <Grid item>
          <Skeleton height={80} variant="rect" width="100%" />
        </Grid>
      )}

      <Grid item>
        <Skeleton height={25} varaint="text" width={150} />
        <Skeleton height={92} variant="text" width="100%" />
      </Grid>

      <Grid item xs={12}>
        <Box mb={3}>
          <Skeleton height={25} varaint="text" width={150} />
          <Skeleton height={15} variant="text" width={150} />
        </Box>

        <Grid container spacing={3}>
          {[...Array(5).keys()].map((item) => (
            <Grid key={item} item xs={6}>
              <Skeleton height={50} variant="rect" width="100%" />
            </Grid>
          ))}
        </Grid>

        <Box mt={3}>
          <Divider />
        </Box>
      </Grid>

      <Grid item>
        <Skeleton height={25} varaint="text" width={150} />
        <Skeleton height={15} variant="text" width={150} />
        <Skeleton height={92} variant="text" width="100%" />

        <Box mt={3}>
          <Divider />
        </Box>
      </Grid>

      <Grid item>
        <Box mb={3}>
          <Skeleton height={25} varaint="text" width={150} />
          <Skeleton height={15} variant="text" width={150} />
        </Box>

        <Skeleton height={50} variant="rect" width={180} />

        <Box mt={3}>
          <Divider />
        </Box>
      </Grid>

      <Grid item>
        <Box mb={3}>
          <Skeleton height={25} varaint="text" width={150} />
          <Skeleton height={15} variant="text" width={150} />
        </Box>

        <Box display="grid" gridAutoFlow="column" justifyContent="space-between">
          {[...Array(7).keys()].map((value) => (
            <Skeleton key={value} height={40} variant="circle" width={40} />
          ))}
        </Box>

        <Box mt={3}>
          <Divider />
        </Box>
      </Grid>

      <Grid item>
        <Skeleton height={25} varaint="text" width={150} />
        <Skeleton height={15} variant="text" width="100%" />
        <Box display="flex" gridColumnGap={8} justifyContent="space-between">
          <Skeleton height={92} variant="text" width="100%" />
          <Skeleton height={92} variant="text" width={170} />
        </Box>
      </Grid>
    </Grid>
  );
}

CategorySkeleton.Title = Title;

CategorySkeleton.propTypes = {
  children: PropTypes.node,
  isEdition: PropTypes.bool,
};

export default CategorySkeleton;
