import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';
import { pageSizeTypes } from 'utils/pagination';
import { cleanObject } from 'utils/objects';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getListReportsOrdersRequest(
  { startDate, endDate, page, status, brands, providers, orderTypes, orderReference },
  options = {},
) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify(
    cleanObject({
      store_uuid: storeUuid,
      should_group_order_states: true,
      start_date: startDate,
      end_date: endDate,
      state: status,
      brand: brands,
      provider: providers,
      orderType: orderTypes,
      order_reference: orderReference,
      page_size: pageSizeTypes.PAGE_SIZE_DEFAULT,
      page,
    }),
  );

  return request(`${API_BASE_URL}/v1/reports/orders-sales/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getDetailsReportsOrdersRequest(orderUuid, isEdited, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const oParams = {
    store_uuid: storeUuid,
    is_edited_order: isEdited,
  };

  const query = queryString.stringify(oParams);

  return request(`${API_BASE_URL}/v1/reports/orders-sales/${orderUuid}/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function exportReportsOrdersToExcelRequest(
  { startDate, endDate, status, brands, providers, orderTypes },
  options = {},
) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
    state: status,
    brand: brands,
    provider: providers,
    orderType: orderTypes,
  });

  return request(`${API_BASE_URL}/v1/reports/orders-sales/export/?${query}`, {
    method: 'GET',
    config: {
      responseType: 'blob',
    },
    ...options,
  });
}
