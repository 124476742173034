import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useLocation } from 'react-router-dom';

import { useSidebarLayout } from 'providers/root/SidebarLayoutProvider';

import Box from '@material-ui/core/Box';

import TopNavigationBarStores from 'components/TopNavigationBarStores';

import useStyles from './styles';

function Layout({ children, ...other }) {
  const location = useLocation();
  const { openMdDrawer, modeDraft } = useSidebarLayout();
  const classes = useStyles({ isModeDraft: modeDraft, openMdMenu: openMdDrawer });

  const isInventoriesLocations = useMemo(() => {
    const isPathInventories = location.pathname.startsWith('/inventories');
    return isPathInventories;
  }, [location]);

  return (
    <Box display="flex" flexDirection="column" width="100%" {...other}>
      {!isInventoriesLocations && (
        <Box className={classes.topNavigation}>
          <TopNavigationBarStores />
        </Box>
      )}
      <Box className={clsx({ [classes.containerWorkspace]: !isInventoriesLocations })}>{children}</Box>
    </Box>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};

export { default as ContentLayout } from './ContentLayout';
export { default as ViewPanelLayout } from './ViewPanelLayout';
export default Layout;
