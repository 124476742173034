import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 4,
  },

  dialogTitle: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(2),

    '& > h2': {
      fontWeight: 500,
      fontSize: '1.4375rem',
    },
  },

  dialogContentText: {
    margin: 0,
    fontWeight: 400,
    fontSize: '1rem',
    color: theme.palette.common.black,
    maxWidth: theme.typography.pxToRem(700),
  },

  actions: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(5),
    paddingInline: theme.spacing(3),
    paddingBlock: theme.spacing(6),
  },

  actionButton: {
    minWidth: theme.typography.pxToRem(242),
  },

  formChanel: {
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default useStyles;
