import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textPrimary: {
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
  },
  textSecondary: {
    fontSize: '0.875rem',
    color: theme.palette.darkGrey.main,
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  divider: {
    width: '80%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(5),
    paddingInline: theme.spacing(3),
    paddingBlock: theme.spacing(6),
  },

  actionButton: {
    minWidth: theme.typography.pxToRem(242),
  },
}));

export default useStyles;
