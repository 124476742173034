import PropTypes from 'prop-types';

import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';

function Header({ children, sticky, ...others }) {
  const theme = useTheme();

  const headerSticky = {
    position: 'sticky',
    top: 0,
    background: theme.palette.background.default,
    zIndex: 1200,
    [theme.breakpoints.down('xs')]: {
      zIndex: 1000,
    },
  };

  return (
    <Box
      alignItems="center"
      borderBottom={`1px solid ${theme.palette.darkGrey[15]}`}
      component="header"
      display="flex"
      height={theme.typography.pxToRem(64)}
      marginBottom={4}
      padding={`${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(24)}`}
      sx={sticky ? headerSticky : null}
      {...others}
    >
      {children}
    </Box>
  );
}

Header.propTypes = {
  children: PropTypes.node,
  sticky: PropTypes.bool,
};

export default Header;
