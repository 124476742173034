import { ContentLayout } from 'Layout';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  useDownloadReportModifiers,
  useGetModifiersByProduct,
  useGetModifiersOptionInfinity,
} from 'containers/ReportModifiers/hooks';

import ExportToExcelButton from 'components/ExportToExcelButton';

import { getReportFileName } from 'utils/getReportFileName';

import useStyles, { NavigationStyled } from './styles';

type ParamType = { type: string };

const Navigation = () => {
  const classes = useStyles();
  const { t } = useTranslation('reports');
  const downloadMutation = useDownloadReportModifiers();
  const { data: productModifiersData } = useGetModifiersByProduct();
  const { data: optionModifiersData } = useGetModifiersOptionInfinity();
  const { type } = useParams<ParamType>();
  const { reportType, disabledButton } = (() => {
    switch (type) {
      case 'modifiersByProduct':
        return { reportType: 'PRODUCT_MODIFIER_SALES', disabledButton: !productModifiersData?.pages[0]?.data?.length };
      case 'modifiersByOption':
        return { reportType: 'MODIFIER_SALES', disabledButton: !optionModifiersData?.pages[0]?.data?.length };
      default:
        return { reportType: 'PRODUCT_MODIFIER_SALES', disabledButton: !productModifiersData?.pages[0]?.data?.length };
    }
  })();

  return (
    <NavigationStyled>
      <ContentLayout.TabsNavigation>
        <ContentLayout.TabLink
          className={classes.tab}
          label={t('modifiersReport.modifiersByProduct')}
          to="/reports/modifiers/modifiersByProduct"
          value={0}
        />
        <ContentLayout.TabLink
          label={t('modifiersReport.modifiersByOption')}
          to="/reports/modifiers/modifiersByOption"
          value={1}
        />
      </ContentLayout.TabsNavigation>

      <ExportToExcelButton
        disabled={disabledButton || downloadMutation.isLoading}
        fileName={getReportFileName({ name: 'Grupos modificadores' })}
        isLoading={downloadMutation.isLoading}
        isVisible
        mutateAsync={() => downloadMutation.mutateAsync(reportType)}
        name="modifiers-report"
      />
    </NavigationStyled>
  );
};

export default Navigation;
