import { makeStyles } from '@material-ui/core/styles';

interface propsStyles {
  selectedCard?: boolean;
}

const useStyles = makeStyles((theme) => ({
  endContentStepper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    paddingBlock: theme.spacing(3),
  },

  contentStepConfig: {
    padding: `0 ${theme.typography.pxToRem(80)}`,
    '& > :not(:first-child)': {
      marginTop: '2rem',
    },
  },

  contentStepStores: {
    maxHeight: theme.typography.pxToRem(570),
    overflow: 'hidden auto',
    marginInline: '5rem',
    scrollbarWidth: 'thin',
    '@media screen and (max-height: 850px)': {
      maxHeight: theme.typography.pxToRem(443),
    },
  },

  configCard: {
    border: ({ selectedCard }: propsStyles) =>
      `1px solid ${selectedCard ? theme.palette.secondary.main : theme.palette.darkGrey[15]}`,
    borderRadius: theme.shape.borderRadius,
    maxWidth: theme.typography.pxToRem(572),
    padding: '3rem 1.5rem',
    '&:hover': {
      cursor: 'pointer',
    },
  },

  contentConfigCard: {
    '& h3, p': {
      margin: 0,
    },

    '& p': {
      color: theme.palette.text.secondary,
    },

    '& strong': {
      fontWeight: 500,
    },

    '& > :not(:first-child)': {
      marginTop: theme.spacing(1),
    },
  },

  riseUp: {
    'z-index': '1500 !important',
  },

  paperFullScreen: {
    display: 'flex',
    justifyContent: 'center',
    paddingBlock: theme.spacing(3),
    paddingInline: theme.spacing(5),
  },

  rootContent: {
    display: 'flex',
    justifyContent: 'center',
    flex: 'none',
    maxHeight: '440px',
  },

  rootActions: {
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
  },

  tableNotification: {
    maxWidth: theme.typography.pxToRem(750),
    '& td, th': {
      padding: '1rem',
    },
  },

  descriptionNotification: {
    '& strong': {
      fontWeight: 500,
    },
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 300,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },

  buttonCloseNot: {
    width: theme.typography.pxToRem(221),
  },

  accordionHeader: {
    background: `${theme.palette.background.default} !important`,
    border: `1.5px solid ${theme.palette.darkGrey[5]}`,
    '&>div': {
      paddingBlock: theme.spacing(2),
    },
    '& span': {
      fontSize: theme.typography.pxToRem(16),
    },
  },

  buttonStep: {
    width: theme.spacing(30),
  },

  warningStep: {
    '& div': {
      color: theme.palette.text.primary,
    },
  },

  warningNumber: {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(20),
    background: '#000',
    borderRadius: '50%',
    color: '#fff',
    height: '32px',
    width: '32px',
  },
}));

export default useStyles;
