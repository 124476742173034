import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function authTokenRequest({ user, password }, options = {}) {
  return request(`${API_BASE_URL}/v1/auth/login/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Product-Auth': 'CONNECT',
    },
    body: JSON.stringify({
      user,
      password,
    }),
    ...options,
  });
}

export function authRefreshTokenRequest(refreshToken, options = {}) {
  return request(`${API_BASE_URL}/auth/token/refresh`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      refresh: refreshToken,
    }),
    ...options,
  });
}

export function getUserRequest({ storeUuid, userUuid }, options = {}) {
  if (!storeUuid || !userUuid) return;
  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/users/${userUuid}/`, {
    method: 'GET',
    ...options,
  });
}

// DEPRECATED: getLicenseStateRequest
export function getLicenseStateRequest(options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  return request(`${API_BASE_URL}/v1/subscriptions/active-license/?store_uuid=${storeUuid}`, {
    method: 'GET',
    ...options,
  });
}
