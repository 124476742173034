import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

import IconUploadOutline from '@experimental-components/IconsComponents/UploadOutline';
import useNotification from '@design-system/Notification/useNotification';

import { createFileFromUrl, inWidthSizeImage, inHeightSizeImage } from 'utils/files';

import useStyles from './styles';

function ImageDropzoneProductList({ initialImageUrl, onImageChange, productUuid, shouldNotifyOnInitialImageLoad }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { closeAllSnackbar, setNotification } = useNotification();

  const [file, setFile] = useState(null);
  const [errorFileDimens, setErrorFileDimens] = useState(false);

  function updateFile(newFile) {
    try {
      setFile({ ...newFile, preview: URL.createObjectURL(newFile) });
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: 'image/jpeg, image/jpg, image/png',
    multiple: false,
    maxSize: 10000000, // 10 MB
    onDrop: (acceptedFiles) => {
      closeAllSnackbar();
      const acceptedFile = acceptedFiles[0];
      const image = new Image();

      image.addEventListener('load', () => {
        setErrorFileDimens(false);

        if (inWidthSizeImage(image.width) && inHeightSizeImage(image.height)) {
          if (onImageChange) {
            onImageChange(productUuid, acceptedFile);
          }
        } else {
          setErrorFileDimens(true);
        }
      });

      if (acceptedFile) image.src = URL.createObjectURL(acceptedFile);
    },
  });

  useEffect(() => {
    async function loadInitialImage() {
      if (initialImageUrl) {
        try {
          const initialImage = await createFileFromUrl(initialImageUrl);
          if (!initialImage) throw new Error('Empty file');
          updateFile(initialImage);
          // eslint-disable-next-line no-empty
        } catch (err) {}
      }
    }
    loadInitialImage();
  }, [initialImageUrl, shouldNotifyOnInitialImageLoad]);

  useEffect(() => {
    if (fileRejections?.length > 0) {
      const latestReason = fileRejections[fileRejections.length - 1].errors[0].code;

      if (latestReason) {
        setNotification(t(`common:errors.image.all`), { variant: 'warning' });
      }
    }

    if (errorFileDimens) {
      setNotification(t(`common:errors.image.all`), { variant: 'warning' });
      setErrorFileDimens(false);
    }
  }, [setNotification, errorFileDimens, fileRejections, t]);

  function getPreview() {
    if (file) {
      return <img alt="Preview" src={file.preview} />;
    }
    return <IconUploadOutline size={40} />;
  }

  return (
    <div className={classes.container}>
      <div {...getRootProps({ className: classes.photoContainer })}>
        <input {...getInputProps()} />
        {getPreview()}
      </div>
    </div>
  );
}

ImageDropzoneProductList.defaultProps = {
  shouldNotifyOnInitialImageLoad: false,
};

ImageDropzoneProductList.propTypes = {
  initialImageUrl: PropTypes.string,
  onImageChange: PropTypes.func,
  shouldNotifyOnInitialImageLoad: PropTypes.bool,
  productUuid: PropTypes.string,
  t: PropTypes.func,
};

export default ImageDropzoneProductList;
