import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
}

const DotRed = forwardRef(function DotRed(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#F04E4A', size = 4 } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 4 4"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="2" cy="2" fill={color} r="2" />
    </svg>
  );
});

export default DotRed;
