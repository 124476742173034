import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  linkCell: {
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'left',
    width: theme.spacing(20),
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  itemSku: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.darkGrey[50],
    marginTop: theme.spacing(1),
  },
}));

export default useStyles;
