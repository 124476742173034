import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing(3),
    alignItems: 'end',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      gridRow: '2/3',
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },

  contentSelected: {
    color: theme.palette.darkGrey.main,
    fontSize: theme.typography.pxToRem(14),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  wrapperMultiSelect: {
    overflow: 'hidden',
  },

  wrapperActions: {
    display: 'flex',
    gap: theme.spacing(3),
    justifyContent: 'space-between',
  },

  middlePrimaryButton: {
    alignSelf: 'baseline',
  },
}));

export default useStyles;
