import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';

interface PropsTitle {
  children: JSX.Element;
}

interface Props {
  children: React.ReactNode;
}

function Title({ children }: PropsTitle) {
  return children;
}

function ProductOverrideSkeleton({ children }: Props) {
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>{children}</Grid>

      <Grid item>
        <Skeleton height={100} variant="rect" width="100%" />
      </Grid>

      <Grid item>
        <Box display="flex" gridColumnGap={24}>
          <Skeleton height={80} variant="rect" width={80} />

          <Box width="80%">
            <Skeleton height={15} variant="rect" width={150} />
            <Skeleton height={75} variant="text" width="100%" />
          </Box>
        </Box>
      </Grid>

      <Grid item>
        <Skeleton height={25} variant="text" width={100} />
        <Skeleton height={25} variant="text" width={100} />
      </Grid>

      <Grid item>
        <Skeleton height={25} variant="text" width={150} />
        <Skeleton height={50} variant="rect" width="100%" />
      </Grid>

      <Grid item>
        <Skeleton height={25} variant="text" width={150} />

        <Box display="grid" gridGap={35} gridTemplateColumns="1.5fr 1fr" mt={1}>
          <Box display="flex" flexDirection="column" gridGap={20}>
            <Skeleton height={80} variant="rect" width="100%" />
            <Skeleton height={40} variant="rect" width="100%" />
          </Box>

          <Skeleton height={150} variant="rect" width="100%" />
        </Box>
      </Grid>

      <Box my={4} px={2}>
        <Divider />
      </Box>

      <Grid item>
        <Skeleton height={25} variant="text" width={150} />
        <Skeleton height={15} variant="text" width={350} />
        <Skeleton height={92} variant="text" width="100%" />
      </Grid>

      <Grid item>
        <Box my={3}>
          <Skeleton height={56} variant="rect" width="100%" />
        </Box>
      </Grid>
    </Grid>
  );
}

ProductOverrideSkeleton.Title = Title;

export default ProductOverrideSkeleton;
