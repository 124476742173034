import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: theme.zIndex.modal,
  },
  paper: {
    fontSize: 16,
    padding: 0,
    overflow: 'hidden',
    width: '100%',
    marginTop: 8,
    marginBottom: 8,
    boxShadow: '0px 8px 40px rgba(133, 133, 133, 0.2)',
  },
  containerListBox: {
    margin: 0,
    paddingTop: 8,
    paddingBottom: 8,
    backgroundColor: theme.palette.background.paper,
    maxHeight: '40vh',
    overflow: 'auto',
    borderRadius: theme.shape.borderRadius,
  },
  listBox: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    backgroundColor: theme.palette.background.paper,
  },
  option: {
    minHeight: 48,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 16,
    boxSizing: 'border-box',
    outline: '0',
    WebkitTapHighlightColor: 'transparent',

    [theme.breakpoints.up('sm')]: {
      minHeight: 'auto',
    },

    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.darkGrey[5],
    },

    '&:active': {
      backgroundColor: theme.palette.darkGrey[5],
    },

    '&:hover': {
      backgroundColor: theme.palette.darkGrey[5],
    },

    '&[aria-disabled="true"]': {
      opacity: theme.palette.action.disabledOpacity,
      pointerEvents: 'none',
    },
  },
  optionLoaded: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  noOptions: {
    color: theme.palette.text.secondary,
    padding: 16,
  },
  inputWrapper: {
    color: theme.palette.text.primary,
    cursor: 'text',
    display: 'flex',
    fontSize: '1rem',
    alignItems: 'center',
    boxSizing: 'border-box',
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '1.1876em',
    letterSpacing: '0.00938em',
    width: '100%',
    border: `1px solid ${theme.palette.darkGrey[50]}`,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: 0,
    flexWrap: 'wrap',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      border: `1px solid ${theme.palette.common.black}`,
    },
  },
  inputWrapperFocus: {
    border: `1px solid ${theme.palette.common.black}`,
    outline: 0,
  },
  inputWrapperEndAdornment: {
    paddingRight: '0.875rem',
  },
  inputBase: {
    font: 'inherit',
    color: 'currentColor',
    border: 0,
    margin: 0,
    display: 'block',
    minWidth: 0,
    background: 'none',
    boxSizing: 'content-box',
    letterSpacing: 'inherit',
    animationDuration: '10ms',
    backgroundColor: theme.palette.background.paper,
    height: '0.875rem',
    padding: '1.25rem 1rem',
    width: 0,
    flexGrow: 1,
    outline: 0,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '16px',
    borderRadius: theme.shape.borderRadius,
    '&:focus': {
      outline: 0,
    },
  },
  inputEndAdornment: {
    paddingRight: 0,
  },
}));

export default useStyles;
