/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import Layout, { ContentLayout } from 'Layout';
import useUserStore from 'hooks/useUserStore';

import useBrands from 'providers/root/BrandsProvider/useBrands';
import { withMenus, useMenus } from 'providers/menuMaker/Menus';
import { withMenuDetails } from 'providers/menuMaker/MenuDetails';
import { withRequiredLicense } from 'providers/root/AuthProvider';
import { withCategories } from 'providers/menuMaker/Categories';
import { withCategoryDetails } from 'providers/menuMaker/CategoryDetails';
import { withKitchenAreas } from 'providers/settings/KitchenAreas';
import { useGetMenusCatalog } from 'providers/menuMaker/Menus/hooks';
import { withProducts } from 'providers/menuMaker/Products';
import { withProductDetails } from 'providers/menuMaker/ProductDetails';
import { withMultiTaxes } from 'providers/settings/MultiTaxes';
import { withModifierGroupDetails } from 'providers/menuMaker/ModifierGroupDetails';
import { useOverrideMenuProduct } from 'providers/menuMaker/MenuProducts/hooks';
import { useGetCategoryCatalog } from 'providers/menuMaker/Categories/hooks';
import { useGetModifierGroupsCatalog } from 'providers/menuMaker/ModifierGroups/hooks';
import useAmplitude from 'providers/root/AmplitudeProvider/useAmplitude';

import MenuMakerTabsNavigation from 'components/MenuMakerTabsNavigation';
import useNotification from '@design-system/Notification/useNotification';
import MenuDrawer from 'components/MenuDrawer';
import CategoryDrawer from 'components/CategoryDrawer';
import ProductDrawer from 'components/ProductDrawer';
import ModifierGroupDrawer from 'components/ModifierGroupDrawer';
import ProductOverrideDrawer from 'components/ProductOverrideDrawer';

import { statusType } from 'utils/products';
import { getEnabledKitchenAreas } from 'utils/kitchenAreas/makeKitchenAreasFields';
import { getErrorCode, errorsCodeTypes } from 'utils/parrotErrorCode';
import {
  drawerActions,
  drawerActionList,
  drawerEntities,
  drawerEntityList,
  getQueryParamsMenuMaker,
} from 'utils/drawerMenuMaker';

import ResumeContent from './ResumeContent';
import { ElementEditedProvider } from './ProviderEdition';
import useStyles from './styles';

export function MenuMakerResume({
  editModifierGroupError,
  editModifierGroupFetching,
  editModifierGroupFetched,
  editModifierGroupObject,
  editModifierGroup,
  resetEditModifierGroup,
  loadModifierGroup,
  modifierGroupObject,
  modifierGroupFetching,
  modifierGroupFetched,
  resetModifierGroupState,
  createProduct,
  createProductObject,
  createProductFetching,
  createProductFetched,
  createProductError,
  resetCreateProduct,
  loadGetProduct,
  productDetailsObject,
  productDetailsState,
  resetProductDetails,
  editProduct,
  productEditObject,
  productEditState,
  resetEditProduct,
  createNewCategory,
  createCategoryObject,
  createCategoryFetched,
  createCategoryFetching,
  createCategoryError,
  categoryDetailObject,
  categoryDetailFetching,
  categoryDetailFetched,
  loadCategoryDetail,
  loadProductsSortCategory,
  resetCreateCategory,
  resetCategoryDetail,
  resetProductsSortCategory,
  resetKitchenAreas,
  productsSortCategory,
  productsSortCategoryState,
  kitchenAreas,
  kitchenAreasState,
  loadKitchenAreas,
  editCategory,
  categoryEditFetched,
  categoryEditFetching,
  categoryEditError,
  categoryEditObject,
  resetEditCategory,
  createMenuError,
  createMenuFetched,
  createMenuFetching,
  createMenuObject,
  createNewMenu,
  editMenu,
  editMenuFetched,
  editMenuFetching,
  editMenuError,
  editMenuObject,
  loadMenu,
  loadGetMenus,
  menu,
  menuFetching,
  menus,
  menusFetching,
  menusFetched,
  menusError,
  paginateMenu,
  menusPagination,
  paginationMenuState,
  resetCreateMenu,
  resetEditMenu,
  resetGetMenu,
  resetGetMenus,
  loadMultiTaxes,
  multiTaxesWithNoOption,
  multiTaxesState,
  resetMultiTaxes,
  updateMenus,
  t,
}) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const {
    storeId,
    storeState: { selected: storeSelected },
  } = useUserStore();
  const { brandSelected, brandsState } = useBrands();
  const { closeAllSnackbar, setNotification } = useNotification();
  const { getMenusDetails, menusDetails, resetGetMenusDetails, syncProductOverride, invalidateMenus } = useMenus();
  const overrideProductMutation = useOverrideMenuProduct();
  const { loadCategoryCatalog, categories, categoriesState, resetCategories } = useGetCategoryCatalog(storeId);
  const { allMenus, getAllMenusFetching, getAllMenusFetched, getAllMenus, resetGetAllMenus } = useGetMenusCatalog({
    storeUuid: storeId,
  });
  const { modifierGroups, modifierGroupsState, loadModifierGroups, resetModifierGroups } = useGetModifierGroupsCatalog(
    storeId,
  );
  const { logEvent } = useAmplitude();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [entitySelected, setEntitySelected] = useState(null);
  const [menuSearched, setMenuSearched] = useState(null);

  const {
    action,
    entity,
    uuid,
    menuOfCategory,
    menuOfProduct,
    menuOfModifier,
    focusInCategories,
    categoryOfProduct,
    focusInProducts,
    isSoldByWeight,
  } = getQueryParamsMenuMaker(location);
  const isEdition = action === drawerActions.EDIT;

  useEffect(() => {
    logEvent('@event.$menuMaker.viewMenuMaker', '@@section.summary');
  }, []);

  useEffect(() => {
    if (brandSelected?.uuid) {
      loadGetMenus({ brand: brandSelected?.uuid, name: menuSearched, version: 2, isResume: true });
    }
  }, [brandSelected]);

  useEffect(() => {
    if (menusFetched) {
      const firstMenu = menus[0];
      if (menus?.length && !isEmpty(menusDetails) && !menusDetails[firstMenu?.uuid]?.isLoaded) {
        getMenusDetails({ menuUuid: firstMenu?.uuid });
      }
    }
  }, [menusFetched]);

  useEffect(() => {
    async function getData() {
      const isValid =
        drawerActionList.includes(action) && drawerEntityList.includes(entity) && (isEdition ? !!uuid : true);

      if (isValid) {
        await setEntitySelectedAsync(entity);
      }

      setOpenDrawer(isValid);
    }
    getData();
  }, [action, entity, uuid, isEdition]);

  useEffect(() => {
    if (createMenuFetched) {
      logEvent('@event.$menuMaker.menuMaker', ['@@section.summary', '@@type.new', '@@item.menu'], {
        itemId: createMenuObject?.uuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });
      loadGetMenus({ brand: brandSelected?.uuid, name: menuSearched, version: 2, isResume: true });
      setNotification(
        t('menuMaker:createMenu.createNewMenuSuccess', {
          menuName: createMenuObject?.name,
          prefix: t('menuMaker:prefixMessages.menu'),
        }),
        {
          variant: 'success',
        },
      );
      history.replace({ search: '' });
      resetCreateMenu();
    }

    if (createMenuError && !getErrorCode(createMenuError, errorsCodeTypes.MENU_NAME_EXISTING)) {
      setNotification(t('menuMaker:createMenu.createNewMenuError'), { variant: 'error' });
      resetCreateMenu();
    }

    if (getErrorCode(createMenuError, errorsCodeTypes.MENU_NAME_EXISTING)) {
      setNotification(t('menuMaker:createMenu.creatMenuErrorDuplicateName'), { variant: 'warning' });
      resetCreateMenu();
    }
  }, [createMenuFetched, createMenuError]);

  useEffect(() => {
    if (editMenuFetched) {
      logEvent('@event.$menuMaker.menuMaker', ['@@section.summary', '@@type.edit', '@@item.menu'], {
        itemId: editMenuObject?.uuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });

      updateMenus(editMenuObject);
      invalidateMenus();

      setNotification(
        t('menuMaker:messages.editMenuSuccess', {
          menuName: editMenuObject?.name,
          prefix: t('menuMaker:prefixMessages.menu'),
        }),
        {
          variant: 'success',
        },
      );
      history.replace({ search: '' });
      resetEditMenu();
    }

    if (editMenuError && !getErrorCode(editMenuError, errorsCodeTypes.MENU_NAME_EXISTING)) {
      setNotification(t('menuMaker:messages.editError'), { variant: 'error' });
      resetEditMenu();
    }

    if (getErrorCode(editMenuError, errorsCodeTypes.MENU_NAME_EXISTING)) {
      setNotification(t('menuMaker:createMenu.creatMenuErrorDuplicateName'), { variant: 'warning' });
      resetEditMenu();
    }
  }, [editMenuFetched, editMenuError]);

  useEffect(() => {
    if (createCategoryFetched) {
      logEvent('@event.$menuMaker.menuMaker', ['@@section.summary', '@@type.new', '@@item.categories'], {
        itemId: createCategoryObject?.uuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });

      invalidateMenus();
      setNotification(
        t('categories:createCategory.createNewCategorySuccess', {
          categoryName: createCategoryObject?.name,
          prefix: t('menuMaker:prefixMessages.category'),
        }),
        { variant: 'success' },
      );
      history.replace({ search: '' });
      resetCreateCategory();
    }

    if (createCategoryError && !getErrorCode(createCategoryError, errorsCodeTypes.MENU_CATEGORY_NAME_EXISTING)) {
      setNotification(t('categories:createCategory.createNewCategoryError'), { variant: 'error' });
      resetCreateCategory();
    }

    if (getErrorCode(createCategoryError, errorsCodeTypes.MENU_CATEGORY_NAME_EXISTING)) {
      setNotification(t('categories:createCategory.createNewCategoryDuplicateNameError'), { variant: 'warning' });
      resetCreateCategory();
    }
  }, [createCategoryFetched, createCategoryError]);

  useEffect(() => {
    if (categoryEditFetched) {
      logEvent('@event.$menuMaker.menuMaker', ['@@section.summary', '@@type.edit', '@@item.categories'], {
        itemId: categoryEditObject?.uuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });

      invalidateMenus();
      setNotification(
        t('categories:editCategory.editCategorySuccess', {
          categoryName: categoryEditObject?.name,
          prefix: t('menuMaker:prefixMessages.category'),
        }),
        { variant: 'success' },
      );
      history.replace({ search: '' });
      resetEditCategory();
    }

    if (categoryEditError && !getErrorCode(categoryEditError, errorsCodeTypes.MENU_CATEGORY_NAME_EXISTING)) {
      setNotification(t('categories:editCategory.editCategoryError'), { variant: 'error' });
      resetEditCategory();
    }

    if (getErrorCode(categoryEditError, errorsCodeTypes.MENU_CATEGORY_NAME_EXISTING)) {
      setNotification(t('categories:createCategory.createNewCategoryDuplicateNameError'), { variant: 'error' });
      resetEditCategory();
    }
  }, [categoryEditFetched, categoryEditError]);

  useEffect(() => {
    if (createProductFetched) {
      logEvent('@event.$menuMaker.menuMaker', ['@@section.summary', '@@type.new', '@@item.products'], {
        itemId: createProductObject?.uuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });

      invalidateMenus();
      setNotification(
        t('menuMaker:productForm.messages.saveSuccess', {
          productName: createProductObject?.name,
          prefix: t('menuMaker:prefixMessages.product'),
        }),
        { variant: 'success' },
      );
      history.replace({ search: '' });
      resetCreateProduct();
    }

    if (createProductError) {
      setNotification(t('menuMaker:productForm.messages.saveError'), { variant: 'error' });
      resetCreateProduct();
    }
  }, [createProductFetched, createProductError]);

  useEffect(() => {
    if (productEditState.fetched) {
      logEvent('@event.$menuMaker.menuMaker', ['@@section.summary', '@@type.edit', '@@item.products'], {
        itemId: productEditObject?.uuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });

      invalidateMenus();
      setNotification(
        t('menuMaker:productForm.messages.editSuccess', {
          productName: productEditObject?.name,
          prefix: t('menuMaker:prefixMessages.product'),
        }),
        { variant: 'success' },
      );
      history.replace({ search: '' });
      resetEditProduct();
    }

    if (productEditState.error) {
      setNotification(t('menuMaker:productForm.messages.editError'), { variant: 'error' });
      resetEditProduct();
    }
  }, [productEditState]);

  useEffect(() => {
    if (editModifierGroupFetched) {
      logEvent('@event.$menuMaker.menuMaker', ['@@section.summary', '@@type.edit', '@@item.modifiers'], {
        itemId: editModifierGroupObject?.uuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });

      invalidateMenus();
      setNotification(
        t('menuMaker:modifierGroupForm.messages.editSuccess', {
          modifierGroupName: editModifierGroupObject?.name,
          prefix: t('menuMaker:prefixMessages.modifierGroup'),
        }),
        { variant: 'success' },
      );
      history.replace({ search: '' });
      resetEditModifierGroup();
    }

    if (editModifierGroupError) {
      setNotification(t('menuMaker:modifierGroupForm.messages.editError'), { variant: 'error' });
      resetEditModifierGroup();
    }
  }, [editModifierGroupFetched, editModifierGroupError]);

  useEffect(() => {
    if (overrideProductMutation.isSuccess) {
      const { data } = overrideProductMutation;

      logEvent('@event.$menuMaker.menuMaker', ['@@section.summary', '@@type.edit', '@@item.products'], {
        itemId: data?.data?.uuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });

      invalidateMenus();
      setNotification(
        t('menuMaker:productForm.messages.editSuccess', {
          productName: data?.data?.name,
          prefix: t('menuMaker:prefixMessages.product'),
        }),
        {
          variant: 'success',
        },
      );
      history.replace({ search: '' });
      overrideProductMutation.reset();
    }

    if (overrideProductMutation.isError) {
      setNotification(t('menuMaker:productForm.messages.editError'), { variant: 'error' });
      overrideProductMutation.reset();
    }
  }, [overrideProductMutation.isSuccess, overrideProductMutation.isError]);

  useEffect(() => {
    return () => {
      resetGetMenus();
      resetGetAllMenus();
      resetCreateMenu();
      resetEditMenu();
      resetGetMenusDetails();
      resetCreateCategory();
      closeAllSnackbar();
      overrideProductMutation.reset();
    };
  }, []);

  const setEntitySelectedAsync = (value) => {
    return new Promise((resolve) => {
      resolve(setEntitySelected(value));
    });
  };

  const handleSearchMenus = ({ filterByText = menuSearched }) => {
    if (filterByText?.length >= 3) {
      setMenuSearched(filterByText);
      loadGetMenus({ brand: brandSelected?.uuid, name: filterByText, version: 2, isResume: true });
    }

    if (!filterByText) {
      setMenuSearched(null);
      loadGetMenus({ brand: brandSelected?.uuid, name: null, version: 2, isResume: true });
    }
  };

  const handlePaginate = () => {
    paginateMenu({
      brand: brandSelected?.uuid,
      name: menuSearched,
      page: menusPagination?.page + 1,
      version: 2,
      isResume: true,
    });
  };

  const handleLoadCategories = () => {
    loadCategoryCatalog();
  };

  const handleLoadMenu = () => {
    loadMenu({ menuUuid: uuid, storeUuid: storeId });
  };

  const handleSubmitMenu = (data) => {
    if (isEdition) {
      editMenu({ menuUuid: menu?.uuid, ...menu, ...data });
    } else {
      createNewMenu({ ...data, availability: statusType.STATUS_AVAILABLE, sortingPosition: 0, store: storeId });
    }
  };

  const handleLoadCategoryDetails = () => {
    loadCategoryDetail(uuid);
    loadProductsSortCategory({ storeUuid: storeId, categoryUuid: uuid });
  };

  const handleResetCategoryDetails = () => {
    resetCategoryDetail();
    resetProductsSortCategory();
  };

  const handleSubmitCategory = (data) => {
    if (isEdition) {
      editCategory({ storeUuid: storeId, categoryUuid: uuid, category: { ...data } });
    } else {
      createNewCategory({ ...data, store: storeId });
    }
  };

  const handleLoadProductDetails = () => {
    loadGetProduct(storeId, uuid);
  };

  function handleLoadModifierGroups() {
    loadModifierGroups();
  }

  const handleSubmitProduct = (data) => {
    if (isEdition) {
      editProduct({ storeUuid: storeId, originMenuUuid: menuOfProduct, product: { uuid, ...data } });
    } else {
      createProduct({ storeUuid: storeId, originMenuUuid: menuOfProduct, product: { ...data } });
    }
  };

  const handleSubmitProductOverride = (data) => {
    overrideProductMutation.mutate({ ...data });
  };

  const handleSubmitModifierGroup = (values) => {
    editModifierGroup(storeId, { uuid, ...values }, menuOfModifier);
  };

  const handleLoadModifierGroupDetails = () => {
    loadModifierGroup(storeId, uuid);
  };

  const renderDrawer = () => {
    switch (entitySelected) {
      case drawerEntities.MENU:
        return (
          <MenuDrawer
            actionState={
              isEdition
                ? { fetched: editMenuFetched, fetching: editMenuFetching }
                : { fetched: createMenuFetched, fetching: createMenuFetching }
            }
            categoriesState={{ list: categories, fetching: categoriesState.fetching, fetched: categoriesState.fetched }}
            focusInCategories={focusInCategories}
            isEdition={isEdition}
            menuState={{ data: menu, fetching: menuFetching }}
            onLoadCategories={handleLoadCategories}
            onLoadMenu={handleLoadMenu}
            onResetCategories={resetCategories}
            onResetGetMenu={resetGetMenu}
            onSubmit={handleSubmitMenu}
            openDrawer={openDrawer}
            uuidSelected={uuid}
          />
        );

      case drawerEntities.CATEGORY:
        return (
          <CategoryDrawer
            actionState={
              isEdition
                ? { fetched: categoryEditFetched, fetching: categoryEditFetching }
                : { fetched: createCategoryFetched, fetching: createCategoryFetching }
            }
            categoryState={{
              data: categoryDetailObject,
              fetching: categoryDetailFetching,
              fetched: categoryDetailFetched,
            }}
            focusInProducts={Boolean(focusInProducts)}
            isEdition={isEdition}
            kitchenAreasState={{ list: getEnabledKitchenAreas(kitchenAreas), ...kitchenAreasState }}
            menuOfCategory={menuOfCategory}
            menusState={{ list: allMenus, fetching: getAllMenusFetching, fetched: getAllMenusFetched }}
            onLoadCategoryDetails={handleLoadCategoryDetails}
            onLoadKitchenAreas={loadKitchenAreas}
            onLoadMenus={getAllMenus}
            onResetCategoryDetails={handleResetCategoryDetails}
            onResetGetKitchenAreas={resetKitchenAreas}
            onResetGetMenus={() => {}}
            onSubmit={handleSubmitCategory}
            openDrawer={openDrawer}
            productsCategoryState={{ list: productsSortCategory, ...productsSortCategoryState }}
            uuidSelected={uuid}
          />
        );

      case drawerEntities.PRODUCT:
        return (
          <ProductDrawer
            actionState={
              isEdition ? { ...productEditState } : { fetching: createProductFetching, fetched: createProductFetched }
            }
            categoriesState={{ list: categories, fetching: categoriesState.fetching, fetched: categoriesState.fetched }}
            categoryOfProduct={categoryOfProduct}
            isEdition={isEdition}
            kitchenAreasState={{ list: getEnabledKitchenAreas(kitchenAreas), ...kitchenAreasState }}
            modifierGroupsState={{
              list: modifierGroups,
              fetched: modifierGroupsState.fetched,
              fetching: modifierGroupsState.fetching,
            }}
            multiTaxesState={{ list: multiTaxesWithNoOption, ...multiTaxesState }}
            onLoadCategories={handleLoadCategories}
            onLoadKitchenAreas={loadKitchenAreas}
            onLoadModifierGroups={handleLoadModifierGroups}
            onLoadMultiTaxes={loadMultiTaxes}
            onLoadProductDetails={handleLoadProductDetails}
            onResetCategories={resetCategories}
            onResetGetKitchenAreas={resetKitchenAreas}
            onResetModifierGroups={resetModifierGroups}
            onResetMultitaxes={resetMultiTaxes}
            onResetProductDetails={resetProductDetails}
            onSubmit={handleSubmitProduct}
            openDrawer={openDrawer}
            productState={{ data: productDetailsObject, ...productDetailsState }}
            uuidSelected={uuid}
          />
        );

      case drawerEntities.MODIFIER_GROUP:
        return (
          <ModifierGroupDrawer
            actionState={{ fetched: editModifierGroupFetched, fetching: editModifierGroupFetching }}
            isEdition={isEdition}
            modifierGroupState={{
              data: modifierGroupObject,
              fetching: modifierGroupFetching,
              fetched: modifierGroupFetched,
            }}
            onLoadModifierGroupDetails={handleLoadModifierGroupDetails}
            onResetModifierGroupDetails={resetModifierGroupState}
            onSubmit={handleSubmitModifierGroup}
            openDrawer={openDrawer}
            uuidSelected={uuid}
          />
        );

      case drawerEntities.PRODUCT_OVERRIDE: {
        return (
          <ProductOverrideDrawer
            actionState={{ fetching: overrideProductMutation.isLoading, fetched: overrideProductMutation.isSuccess }}
            isSoldByWeight={isSoldByWeight}
            menuOfProduct={menuOfProduct}
            modifierGroupsState={{
              list: modifierGroups,
              fetched: modifierGroupsState.fetched,
              fetching: modifierGroupsState.fetching,
              error: modifierGroupsState.error,
            }}
            onLoadModifierGroups={handleLoadModifierGroups}
            onResetModifierGroups={resetModifierGroups}
            onSubmit={handleSubmitProductOverride}
            onSyncProductOverride={syncProductOverride}
            openDrawer={openDrawer}
            uuidSelected={uuid}
          />
        );
      }

      default:
        return null;
    }
  };

  return (
    <Layout>
      <ContentLayout>
        <ContentLayout.Header className={classes.headerLayout} sticky>
          <MenuMakerTabsNavigation />
        </ContentLayout.Header>

        <ContentLayout.Container pb="0" pl="0" pr="0">
          <ElementEditedProvider>
            <ResumeContent
              isSearchFilter={!!menuSearched}
              loading={menusFetching || brandsState.isLoading}
              menus={menus}
              menusError={menusError}
              menusPagination={menusPagination}
              onPaginate={handlePaginate}
              onSearchMenus={handleSearchMenus}
              openDrawer={openDrawer}
              paginationState={paginationMenuState}
            />

            {renderDrawer()}
          </ElementEditedProvider>
        </ContentLayout.Container>
      </ContentLayout>
    </Layout>
  );
}

MenuMakerResume.propTypes = {
  editModifierGroupError: PropTypes.bool,
  editModifierGroupFetching: PropTypes.bool,
  editModifierGroupFetched: PropTypes.bool,
  editModifierGroupObject: PropTypes.object,
  editModifierGroup: PropTypes.func,
  resetEditModifierGroup: PropTypes.func,
  loadModifierGroup: PropTypes.func,
  modifierGroupObject: PropTypes.object,
  modifierGroupFetching: PropTypes.bool,
  modifierGroupFetched: PropTypes.bool,
  resetModifierGroupState: PropTypes.func,
  createProduct: PropTypes.func,
  createProductObject: PropTypes.object,
  createProductFetching: PropTypes.bool,
  createProductFetched: PropTypes.bool,
  resetCreateProduct: PropTypes.func,
  createProductError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  loadGetProduct: PropTypes.func,
  productDetailsObject: PropTypes.object,
  productDetailsState: PropTypes.object,
  resetProductDetails: PropTypes.func,
  editProduct: PropTypes.func,
  productEditObject: PropTypes.object,
  productEditState: PropTypes.object,
  resetEditProduct: PropTypes.func,
  createMenuError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  createMenuFetched: PropTypes.bool,
  createMenuFetching: PropTypes.bool,
  createMenuObject: PropTypes.object,
  createNewMenu: PropTypes.func,
  editMenu: PropTypes.func,
  editMenuFetched: PropTypes.bool,
  editMenuFetching: PropTypes.bool,
  editMenuError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  editMenuObject: PropTypes.object,
  loadGetMenus: PropTypes.func,
  loadMenu: PropTypes.func,
  menu: PropTypes.object,
  menuFetching: PropTypes.bool,
  menus: PropTypes.array,
  menusFetching: PropTypes.bool,
  menusFetched: PropTypes.bool,
  menusError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  paginateMenu: PropTypes.func,
  menusPagination: PropTypes.object,
  paginationMenuState: PropTypes.object,
  resetCreateMenu: PropTypes.func,
  resetEditMenu: PropTypes.func,
  resetGetMenu: PropTypes.func,
  resetGetMenus: PropTypes.func,
  loadMultiTaxes: PropTypes.func,
  multiTaxesWithNoOption: PropTypes.array,
  multiTaxesState: PropTypes.object,
  resetMultiTaxes: PropTypes.func,
  updateMenus: PropTypes.func,
  t: PropTypes.func,
};

export default compose(
  memo,
  withKitchenAreas,
  withCategories,
  withCategoryDetails,
  withMenus,
  withMenuDetails,
  withProducts,
  withProductDetails,
  withModifierGroupDetails,
  withMultiTaxes,
  withRequiredLicense(),
  withTranslation(['menuMaker', 'kitchenAreas', 'categories', 'modifierGroups']),
)(MenuMakerResume);
