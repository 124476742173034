import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const UploadOutline = forwardRef(function UploadOutline(props, ref) {
  const { color = '#000000', size = 32, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 32 32"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        clipRule="evenodd"
        d="M4.5 27.5C4.22386 27.5 4 27.7239 4 28C4 28.2761 4.22386 28.5 4.5 28.5L27.5 28.5C27.7761 28.5 28 28.2761 28 28C28 27.7239 27.7761 27.5 27.5 27.5L4.5 27.5ZM16 3.00001C16.0118 3.00001 16.0235 3.00029 16.0351 3.00084C16.1188 3.00479 16.1985 3.02279 16.2716 3.05236C16.3339 3.07752 16.393 3.11174 16.4465 3.15504C16.4601 3.16599 16.4732 3.17744 16.4858 3.18935L23.3003 9.53203C23.5666 9.77987 23.5666 10.1817 23.3003 10.4295C23.034 10.6773 22.6023 10.6773 22.336 10.4295L16.6818 5.16675L16.6818 18.8654C16.6818 19.2159 16.3766 19.5 16 19.5C15.6234 19.5 15.3182 19.2159 15.3182 18.8654L15.3182 5.16669L9.6639 10.4295C9.39764 10.6773 8.96593 10.6773 8.69966 10.4295C8.4334 10.1817 8.4334 9.77986 8.69966 9.53203L15.5179 3.18588C15.5881 3.12055 15.6698 3.07243 15.7569 3.04154C15.8159 3.02058 15.8788 3.00703 15.9443 3.0021C15.9627 3.00072 15.9813 3.00001 16 3.00001Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

UploadOutline.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default UploadOutline;
