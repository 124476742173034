import { Helmet } from 'react-helmet-async';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import SignIn from 'containers/SignIn/SignIn';
import SignOut from 'containers/SignOut';
import Subscription from 'containers/Subscription/Loadable';
import AccessDeniedAdmin from 'containers/AccessDeniedAdmin/Loadable';

import LoggedOutPage from 'components/LoggedOutPage';
import NotFound from 'components/NotFound';
import WithoutStore from 'components/WithoutStore';

import useEnvironment from 'hooks/useEnvironment';
import { useAuth } from 'hooks/useAuth';

import AppRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { HomeRedirect } from './HomeRedirect';
import { InventoryRoutes } from './AppRoutesModules/InventoriesRoutes';
import { InvoicingRoutes } from './AppRoutesModules/InvoicingRoutes';
import { ReportsRoutes } from './AppRoutesModules/ReportsRoutes';
import { OnlineOrderingRoutes } from './AppRoutesModules/OnlineOrderingRoutes';
import { SettingsRoutes } from './AppRoutesModules/SettingsRoutes';
import { WorkforceRoutes } from './AppRoutesModules/WorkforceRoutes';
import { MenuMakerRoutes } from './AppRoutesModules/MenuMakerRoutes';

function App() {
  const { isAuthenticated, user } = useAuth();
  const { environmentsState } = useEnvironment();
  const { i18n } = useTranslation();
  const environment = environmentsState.VERCEL_ENV === 'production' ? 'production' : 'staging';
  const isInternal = /@parrotsoftware.io/.test(user?.email || '') ? ';internal' : '';

  return (
    <>
      <Helmet defaultTitle="ParrotConnect" htmlAttributes={{ lang: i18n.language }} titleTemplate="%s - ParrotConnect">
        <meta content="ParrotConnect" name="ParrotConnect" />
        {isAuthenticated && (
          <>
            <script>
              {`
                var beamer_config = {
                  product_id: 'bZFFZpks24122',
                  filter: '${environment}${isInternal}',
                  button_position: 'bottom-right',
                  force_button: true,
                  user_firstname: '${user?.firstName}',
                  user_lastname: '${user?.lastName}',
                  user_email: '${user?.email}',
                  user_id: '${user?.uuid}',
                };
              `}
            </script>
            <script defer="defer" src="https://app.getbeamer.com/js/beamer-embed.js" type="text/javascript"></script>
          </>
        )}
      </Helmet>

      <Switch>
        <Route component={HomeRedirect} exact path="/" />
        <Redirect exact from="/workforce" to="/workforce/roles" />
        <Route component={WithoutStore} exact path="/without-store" />
        <PublicRoute component={SignIn} exact path="/signin" />
        <Route component={LoggedOutPage} exact path="/logged-out" />
        <Route component={SignOut} exact path="/signout" />
        <AppRoute component={AccessDeniedAdmin} exact path="/permissions-denied" />

        {/* Menu Maker Module */}
        <Route component={MenuMakerRoutes} path="/menus" />

        {/* Workforce Module */}
        <Route component={WorkforceRoutes} path="/workforce" />

        {/* Invoicing Module */}
        <Route component={InvoicingRoutes} path="/invoicing" />

        {/* Settings Module */}
        <Route component={SettingsRoutes} path="/settings" />

        {/* Reports Module */}
        <Route component={ReportsRoutes} path="/reports" />

        {/* Inventories Module */}
        <Route component={InventoryRoutes} path="/inventories" />

        {/* Online Ordering Module */}
        <Route component={OnlineOrderingRoutes} path="/online-ordering" />

        {/* Suscription Module */}
        <AppRoute component={Subscription} exact path="/subscription/(payment-history|invoice-data)?/(edit)?" />

        {/* Not found page */}
        <Route component={NotFound} />
      </Switch>
    </>
  );
}

export default App;
