import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import * as R from 'ramda';

const EmptyDefault = forwardRef(function EmptyDefault(props, ref) {
  const { size: sizeProp = 120, sizeBase = 22, ...others } = props;

  const sizeWidth = R.add(sizeProp, sizeBase);

  return (
    <svg
      ref={ref}
      fill="none"
      height={sizeProp}
      viewBox="0 0 142 120"
      width={sizeWidth}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M3.61879 119.769C3.61879 119.769 38.5308 116.522 45.954 115.13C53.3771 113.738 58.0143 106.181 60.7991 101.145C60.7991 101.145 63.584 101.907 65.5558 100.167C67.8326 98.1585 68.1017 95.5303 68.1017 95.5303C68.1017 95.5303 76.4319 88.8483 79.4603 84.2436C81.3462 81.3764 79.4603 77.5326 79.4603 77.5326L83.4781 74.8823C85.3884 72.0986 83.238 67.4591 73.7584 66.1833L74.8382 62.9495C74.8382 62.9495 71.7019 55.7444 69.6142 55.7444C67.5264 55.7444 45.6385 56.6225 41.5453 57.2523C40.0375 57.4842 38.6457 58.6441 38.6457 58.6441C38.6457 58.6441 35.398 58.6441 33.6837 60.2993C31.3756 62.5285 28.5548 69.8948 26.3511 74.1864C24.1473 78.4779 20.0878 82.5374 16.9561 83.3493C13.8245 84.1612 0.0115967 84.885 0.0115967 84.885L3.61879 119.769Z"
          fill="white"
        />
        <path
          d="M62.4369 101.664C61.4348 101.664 60.7214 101.362 60.7075 101.356L60.8931 100.931L60.8003 101.143L60.8919 100.931C60.9905 100.974 63.3358 101.95 65.3969 99.9981C67.5101 97.9961 67.8697 95.5233 67.8732 95.4978L68.3325 95.5604C68.3186 95.6683 67.9532 98.2153 65.7158 100.336C64.6 101.392 63.388 101.664 62.4369 101.664Z"
          fill="black"
        />
        <path
          d="M0.0243572 85.117L0 84.6542C0.138024 84.6472 13.8256 83.9211 16.8993 83.1255C19.8767 82.353 23.9385 78.3805 26.1458 74.0808C26.8127 72.7829 27.519 71.2078 28.2683 69.54C30.0128 65.6532 31.8164 61.6355 33.5318 60.1253C34.6835 59.1128 37.2121 58.708 38.6167 58.556L38.6665 59.0177C37.3164 59.1626 34.8935 59.5454 33.8392 60.4744C32.1991 61.9173 30.3364 66.0673 28.6917 69.7302C27.9401 71.4039 27.2314 72.9848 26.5587 74.2931C24.2598 78.7702 20.1574 82.7601 17.0153 83.5743C13.8964 84.3816 0.589213 85.0868 0.0243572 85.117Z"
          fill="black"
        />
        <path
          d="M35.6162 63.598L35.1824 63.4321C35.2044 63.3753 35.7264 62.0252 36.7598 60.5545C38.1412 58.5885 39.7801 57.3683 41.5013 57.0238C45.591 56.2061 67.3769 55.5114 69.6154 55.5114C71.7925 55.5114 74.7247 62.1041 75.0517 62.8557L74.6261 63.0413C73.7701 61.0764 71.2021 55.9765 69.6154 55.9765C67.4395 55.9765 45.5505 56.6886 41.5918 57.4797C37.7051 58.2568 35.6359 63.5446 35.6162 63.598Z"
          fill="black"
        />
        <path
          d="M64.0654 97.5647C62.9588 97.5647 62.3662 97.132 62.3302 97.1042L62.6097 96.7342C62.6109 96.7354 62.9009 96.9441 63.4495 97.0451C63.9598 97.139 64.8042 97.1552 65.9339 96.7041C68.1168 95.8307 77.2403 87.0319 79.2713 84.1102C80.2351 82.7242 80.1829 81.047 79.9707 79.8848C79.7364 78.6043 79.2597 77.6462 79.255 77.6358L79.6691 77.427C79.69 77.4676 80.176 78.4454 80.4242 79.7874C80.7559 81.584 80.4891 83.1695 79.6517 84.3735C78.7122 85.7259 76.0538 88.5722 73.0358 91.4556C71.8098 92.6271 67.6865 96.4999 66.1056 97.1332C65.3006 97.4568 64.6186 97.5647 64.0654 97.5647Z"
          fill="black"
        />
        <path
          d="M55.1192 41.2461C55.0334 41.2461 54.9511 41.1985 54.9105 41.1162C51.7742 34.7265 41.7112 34.6638 41.5558 34.6638C41.5546 34.6638 41.5546 34.6638 41.5546 34.6638C41.427 34.6638 41.3226 34.5606 41.3226 34.433C41.3226 34.3054 41.4259 34.2011 41.5534 34.1999C41.5778 34.1953 44.1968 34.2011 47.1811 35.0199C51.1537 36.1102 53.9698 38.1469 55.3269 40.9121C55.3837 41.0269 55.3361 41.1661 55.2213 41.2229C55.1877 41.238 55.1529 41.2461 55.1192 41.2461Z"
          fill="black"
        />
        <path
          d="M58.9481 31.8512C58.8553 31.8512 58.7683 31.7955 58.7324 31.705L52.2522 15.3508C52.2046 15.2314 52.2638 15.0968 52.3821 15.0493C52.5016 15.0029 52.6361 15.0609 52.6837 15.1792L59.1638 31.5334C59.2114 31.6528 59.1522 31.7874 59.0339 31.8349C59.0049 31.8454 58.9759 31.8512 58.9481 31.8512Z"
          fill="black"
        />
        <path
          d="M68.1016 35.0988C67.9949 35.0988 67.8986 35.0246 67.8754 34.9155C66.2446 27.3683 71.1567 19.7595 71.2066 19.6841C71.2773 19.5774 71.42 19.5473 71.5278 19.6169C71.6346 19.6876 71.6647 19.8314 71.5951 19.9382C71.5464 20.0124 66.7399 27.4634 68.3289 34.8169C68.3556 34.9422 68.2767 35.0652 68.1515 35.093C68.1341 35.0965 68.1178 35.0988 68.1016 35.0988Z"
          fill="black"
        />
        <path
          d="M96.9013 101.672C96.8944 101.672 96.8874 101.672 96.8805 101.671C96.7529 101.659 96.6589 101.547 96.6694 101.419C96.6786 101.319 97.5219 91.4093 95.1592 87.5527C95.0919 87.4437 95.1267 87.301 95.2358 87.2337C95.3448 87.1665 95.4875 87.2013 95.5547 87.3103C96.5835 88.9909 97.1612 91.886 97.2713 95.9165C97.3525 98.9009 97.1333 101.434 97.131 101.46C97.1217 101.58 97.0208 101.672 96.9013 101.672Z"
          fill="black"
        />
        <path
          d="M109.552 95.7623C109.525 95.7623 109.497 95.7576 109.469 95.7472C107.137 94.868 104.162 92.1991 100.625 87.816C98.003 84.566 96.0323 81.5306 96.0126 81.5005C95.943 81.3926 95.9732 81.2488 96.0811 81.1792C96.1889 81.1096 96.3328 81.1409 96.4024 81.2476C96.4221 81.2778 98.3811 84.2958 100.988 87.5272C103.382 90.4941 106.826 94.2544 109.633 95.3122C109.752 95.3575 109.814 95.4909 109.769 95.6115C109.734 95.7043 109.646 95.7623 109.552 95.7623Z"
          fill="black"
        />
        <path
          d="M117.713 87.6211C115.79 87.6211 113.913 87.3346 112.363 86.9936C108.993 86.2536 106.398 85.1088 106.373 85.0973C106.256 85.0451 106.203 84.9082 106.254 84.791C106.307 84.6739 106.443 84.6205 106.561 84.6739C106.662 84.7191 116.779 89.1463 123.216 86.0692C123.332 86.0147 123.47 86.0634 123.526 86.1782C123.582 86.2942 123.533 86.4323 123.417 86.4879C121.666 87.3242 119.666 87.6211 117.713 87.6211Z"
          fill="black"
        />
        <path
          d="M139.416 27.5482C143.831 45.7582 132.299 64.33 114.091 68.7445C95.8806 73.1589 77.8887 61.7435 73.4742 43.5347C69.0597 25.3259 80.2432 6.98492 98.452 2.57046C116.661 -1.844 135.002 9.33945 139.416 27.5482Z"
          fill="black"
        />
        <path
          d="M107.665 68.0982C126.401 68.0982 141.591 52.909 141.591 34.1721C141.591 15.4352 126.401 0.245911 107.665 0.245911C88.9277 0.245911 73.7384 15.4352 73.7384 34.1721C73.7384 52.909 88.9277 68.0982 107.665 68.0982Z"
          fill="#F04E4A"
        />
        <path
          d="M107.602 68.343C101.405 68.343 95.3074 66.6438 89.8815 63.3359C82.0919 58.5862 76.6173 51.0865 74.468 42.2193C72.3188 33.3521 73.7501 24.1787 78.5009 16.389C83.2506 8.59935 90.7503 3.12477 99.6163 0.975535C108.484 -1.17486 117.657 0.257576 125.447 5.0084C133.236 9.75806 138.711 17.2578 140.86 26.125C143.009 34.9922 141.578 44.1656 136.827 51.9552C132.078 59.7449 124.578 65.2195 115.711 67.3687C113.022 68.0206 110.302 68.343 107.602 68.343ZM107.726 0.464033C105.062 0.464033 102.38 0.781837 99.7254 1.42556C90.9799 3.54696 83.5823 8.94615 78.8964 16.6303C74.2105 24.3144 72.7978 33.3637 74.9192 42.1103C77.0395 50.8568 82.4398 58.2545 90.124 62.9403C95.4756 66.2042 101.491 67.8791 107.603 67.8791C110.267 67.8791 112.948 67.5613 115.603 66.9175C124.349 64.7973 131.747 59.397 136.433 51.7128C141.119 44.0287 142.532 34.9794 140.41 26.2328C138.29 17.4863 132.89 10.0886 125.205 5.40275C119.854 2.14004 113.839 0.464033 107.726 0.464033Z"
          fill="black"
        />
        <path
          d="M57.7847 95.528L88.8078 64.9121C86.3175 59.5721 81.0471 57.4681 81.0471 57.4681L69.2651 68.3221"
          fill="#F8B8B7"
        />
        <path
          d="M85.5646 68.2769C85.4695 68.2769 85.3813 68.2189 85.3465 68.1238C84.3339 65.3378 82.5582 63.3509 80.0691 62.2201C78.2063 61.3734 76.6185 61.3502 76.6022 61.3502C76.4746 61.349 76.3714 61.2446 76.3726 61.1159C76.3737 60.9883 76.477 60.8862 76.6045 60.8862H76.6057C76.6741 60.8862 78.2922 60.9083 80.2419 61.7898C82.0409 62.6028 84.4627 64.3357 85.7826 67.9661C85.8267 68.0867 85.7641 68.2201 85.6434 68.263C85.6179 68.2723 85.5912 68.2769 85.5646 68.2769Z"
          fill="black"
        />
        <path
          d="M107.524 61.6332C122.738 61.6332 135.071 49.3001 135.071 34.0864C135.071 18.8726 122.738 6.53949 107.524 6.53949C92.3108 6.53949 79.9777 18.8726 79.9777 34.0864C79.9777 49.3001 92.3108 61.6332 107.524 61.6332Z"
          fill="white"
        />
        <path
          d="M107.473 61.8756C102.434 61.8756 97.4754 60.4942 93.0621 57.8033C86.7269 53.941 82.2753 47.8412 80.5274 40.6303C78.7795 33.4194 79.944 25.9591 83.8064 19.6239C87.6687 13.2887 93.7684 8.83713 100.979 7.08921C108.19 5.34129 115.65 6.5058 121.986 10.3682C128.321 14.2305 132.772 20.3303 134.52 27.5412C136.268 34.7521 135.104 42.2124 131.241 48.5476C127.379 54.8828 121.279 59.3344 114.068 61.0823C111.881 61.6135 109.669 61.8756 107.473 61.8756ZM107.574 6.76097C105.415 6.76097 103.241 7.01846 101.088 7.5404C93.9981 9.25933 88.0004 13.6367 84.203 19.8663C80.4045 26.096 79.2597 33.431 80.9786 40.5224C82.6975 47.6127 87.0749 53.6104 93.3045 57.4078C97.6436 60.0535 102.52 61.4117 107.475 61.4117C109.634 61.4117 111.808 61.1542 113.961 60.6323C121.051 58.9133 127.049 54.536 130.846 48.3063C134.644 42.0767 135.789 34.7417 134.07 27.6502C132.351 20.5599 127.974 14.5623 121.744 10.7637C117.407 8.11917 112.53 6.76097 107.574 6.76097Z"
          fill="black"
        />
        <path
          d="M94.1107 42.4837C93.9784 42.4837 93.8485 42.428 93.7569 42.319C93.591 42.123 93.6154 41.8307 93.8114 41.6649C93.939 41.557 96.9999 38.9902 102.114 37.0648C106.836 35.2879 114.253 33.7685 122.77 36.6287C123.012 36.7099 123.143 36.9732 123.062 37.2167C122.981 37.4592 122.718 37.5902 122.475 37.509C118.836 36.2865 115.099 35.7808 111.368 36.0047C108.381 36.1833 105.388 36.8294 102.474 37.922C97.4998 39.7882 94.4412 42.348 94.4111 42.3735C94.3229 42.4478 94.2162 42.4837 94.1107 42.4837Z"
          fill="#F8B8B7"
        />
        <path
          d="M96.4105 30.5859C97.2298 30.5859 97.894 29.9217 97.894 29.1024C97.894 28.2831 97.2298 27.6189 96.4105 27.6189C95.5912 27.6189 94.927 28.2831 94.927 29.1024C94.927 29.9217 95.5912 30.5859 96.4105 30.5859Z"
          fill="#F8B8B7"
        />
        <path
          d="M114.926 28.9016C115.745 28.9016 116.409 28.2375 116.409 27.4182C116.409 26.5989 115.745 25.9347 114.926 25.9347C114.106 25.9347 113.442 26.5989 113.442 27.4182C113.442 28.2375 114.106 28.9016 114.926 28.9016Z"
          fill="#F8B8B7"
        />
        <path
          d="M134.297 27.5957C130.713 12.8097 115.821 3.73028 101.035 7.31428C93.3915 9.16775 87.2732 14.0427 83.6405 20.3628C87.3358 14.6412 93.1456 10.2719 100.287 8.54026C115.073 4.95626 129.964 14.0357 133.548 28.8217C135.28 35.963 134.055 43.1299 130.662 49.0347C134.617 42.9118 136.149 35.2404 134.297 27.5957Z"
          fill="black"
        />
        <path
          d="M107.791 57.3903C107.346 57.3903 106.891 57.3799 106.426 57.3578C103.354 57.2117 100.501 56.5772 97.9509 55.4707C94.7577 54.087 92.0309 51.9575 89.8457 49.1436L90.2122 48.8595C92.3475 51.6095 95.0106 53.6892 98.1272 55.0416C100.624 56.126 103.42 56.7489 106.433 56.8939C111.578 57.1421 115.631 55.8917 115.671 55.879L115.81 56.3221C115.773 56.3337 112.351 57.3903 107.791 57.3903Z"
          fill="black"
        />
        <path
          d="M121.392 53.5813L121.163 53.1776C121.21 53.151 125.807 50.5007 127.617 45.671L128.052 45.8333C126.181 50.8254 121.439 53.5546 121.392 53.5813Z"
          fill="black"
        />
        <path
          d="M3.61865 119.769C3.61865 119.769 38.5307 116.522 45.9538 115.13C53.377 113.738 58.0142 106.181 60.799 101.145C63.5839 96.1079 64.4712 84.9117 68.8984 82.1013C73.3256 79.2909 81.5676 77.6671 83.4779 74.8834C85.3882 72.0998 83.2378 67.4603 73.7582 66.1844C64.2786 64.9086 53.6507 73.0276 51.5421 74.8834"
          fill="white"
        />
        <path
          d="M3.63959 120L3.59668 119.538C3.9458 119.506 38.5829 116.276 45.911 114.902C52.8493 113.601 57.4041 106.806 60.5961 101.033C61.6435 99.1386 62.4148 96.3758 63.2313 93.4517C64.571 88.6534 65.957 83.6926 68.7743 81.9053C70.6753 80.6978 73.2549 79.7154 75.7486 78.7643C79.0809 77.4931 82.23 76.2927 83.2878 74.7524C83.9767 73.7491 84.073 72.5602 83.5603 71.4038C82.4608 68.9263 78.8768 67.1077 73.7281 66.4141C69.7451 65.8782 65.1242 66.9383 59.993 69.5654C56.1109 71.5534 52.9514 73.9532 51.6964 75.0574L51.3902 74.7094C52.6614 73.5913 55.8592 71.1626 59.7819 69.1537C64.999 66.4825 69.7127 65.4061 73.7908 65.9548C79.103 66.6692 82.818 68.5877 83.9848 71.2159C84.5567 72.5057 84.443 73.8906 83.6705 75.0145C82.5304 76.6754 79.3175 77.9002 75.9145 79.1981C73.4416 80.1411 70.8853 81.1154 69.0237 82.2973C66.3572 83.9895 64.9955 88.8645 63.679 93.577C62.8544 96.5277 62.0761 99.316 61.002 101.258C59.536 103.91 57.7103 106.982 55.2874 109.661C52.4283 112.822 49.3025 114.739 45.9957 115.359C38.6479 116.735 3.98872 119.967 3.63959 120Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect fill="white" height="120" width="141.836" />
        </clipPath>
      </defs>
    </svg>
  );
});

EmptyDefault.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sizeBase: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default EmptyDefault;
