/* eslint-disable no-useless-escape */
import { regexTypes } from 'utils/formatField';
import forEach from 'lodash/forEach';

export const DEFAULT_SIZE_PAGE = 6;
export const DEFAULT_PAGE = 1;

const PROPERTY_LAST_NAME = 'lastName';
const PROPERTY_FIRST_NAME = 'firstName';

const DESC = 'desc';
const ASC = 'asc';

export const sortingOrderTypes = {
  DESC,
  ASC,
};

export const sortingPropertyTypes = {
  LAST_NAME: PROPERTY_LAST_NAME,
  FIRST_NAME: PROPERTY_FIRST_NAME,
};

const FILTER_PROPERTIES = [PROPERTY_LAST_NAME, PROPERTY_FIRST_NAME, 'nameFull'];

export function getFilteredEmployeesByText(employees, sorting) {
  return employees.filter((employee) => {
    employee.nameFull = `${employee.firstName} ${employee.lastName}`;

    return FILTER_PROPERTIES.filter((property) =>
      employee[property].toLowerCase().includes(sorting.filterByText.toLowerCase()),
    ).length;
  });
}

const firstNameField = {
  name: 'firstNameField',
  id: 'firstNameField',
  label: 'employees:createEmployee.fields.firstNameField',
  required: true,
  maxLength: 50,
  minLength: 1,
  register: {
    required: 'common:messages.requireField',
    maxLength: {
      value: 50,
      message: 'common:errors.maxLengthField',
    },
    minLength: {
      value: 1,
      message: 'common:errors.minLengthField',
    },
    pattern: {
      value: regexTypes.REGEX_TEXT_NO_NUMBERS,
      message: 'common:messages.textFieldInvalid',
    },
  },
};

const lastNameField = {
  name: 'lastNameField',
  id: 'lastNameField',
  label: 'employees:createEmployee.fields.lastNameField',
  required: true,
  maxLength: 50,
  minLength: 1,
  register: {
    required: 'common:messages.requireField',
    maxLength: {
      value: 50,
      message: 'common:errors.maxLengthField',
    },
    minLength: {
      value: 1,
      message: 'common:errors.minLengthField',
    },
    pattern: {
      value: regexTypes.REGEX_TEXT_NO_NUMBERS,
      message: 'common:messages.textFieldInvalid',
    },
  },
};

const cellPhoneNumberField = {
  name: 'cellPhoneNumberField',
  id: 'cellPhoneNumberField',
  label: 'employees:createEmployee.fields.cellPhoneNumberField',
  required: false,
  register: {
    maxLength: {
      value: 14,
      message: 'common:errors.maxLengthField',
    },
    minLength: {
      value: 13,
      message: 'common:errors.minLengthField',
    },
  },
};

const adminWebField = {
  name: 'adminWebField',
  id: 'adminWebField',
  label: 'employees:createEmployee.fields.adminWebField',
  required: false,
  register: {},
};

const emailField = {
  name: 'emailField',
  id: 'emailField',
  label: 'employees:createEmployee.fields.emailField',
  required: false,
  register: {
    pattern: {
      value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'employees:createEmployee.errors.emailFieldInvalid',
    },
  },
};

const passwordField = {
  name: 'passwordField',
  id: 'passwordField',
  label: 'employees:createEmployee.fields.passwordField',
  required: false,
  register: {},
};

const repeatPasswordField = {
  name: 'repeatPasswordField',
  id: 'repeatPasswordField',
  label: 'employees:createEmployee.fields.repeatPasswordField',
  required: false,
  register: {},
};

const posParrotPayField = {
  name: 'posParrotPayField',
  id: 'posParrotPayField',
  label: 'employees:createEmployee.fields.posParrotPayField',
  required: false,
  register: {},
};

const pinField = {
  name: 'pinField',
  id: 'pinField',
  label: 'employees:createEmployee.fields.pinField',
  required: false,
  maxLength: 6,
  placeholder: 'employees:createEmployee.fieldsPlaceholder.pinField',
  register: {
    pattern: {
      value: regexTypes.REGEX_ONLY_NUMBERS,
      message: 'employees:createEmployee.errors.validatePinNumber',
    },
    minLength: {
      value: 3,
      message: 'employees:createEmployee.errors.pinFieldMinLength',
    },
    maxLength: {
      value: 6,
      message: 'employees:createEmployee.errors.pinFieldMaxLength',
    },
  },
};

const closeCashSessionField = {
  name: 'closeCashSessionField',
  id: 'closeCashSessionField',
  label: 'employees:createEmployee.fields.closeCashSessionField',
  required: false,
  register: {},
};

const subscriptionPlanField = {
  name: 'subscriptionPlanField',
  id: 'subscriptionPlanField',
  label: 'employees:createEmployee.fields.subscriptionPlanField',
  required: false,
  register: {},
};
const payDepositsField = {
  name: 'payDepositsField',
  id: 'payDepositsField',
  label: 'employees:createEmployee.fields.payDepositsField',
  required: false,
  register: {},
};

export const formDefinition = {
  firstNameField,
  lastNameField,
  cellPhoneNumberField,
  adminWebField,
  emailField,
  passwordField,
  repeatPasswordField,
  posParrotPayField,
  pinField,
  closeCashSessionField,
  subscriptionPlanField,
  payDepositsField,
};

const ADMIN_WEB = 'ADMIN_WEB';
const POS = 'POS';
const PAY = 'PAY';
const CLOSE_CASH_SESSION = 'CLOSE_CASH_SESSION';
const SUBSCRIPTION_PLAN = 'SUBSCRIPTION_PLAN';
const PAY_DEPOSITS = 'PAY_DEPOSITS';

export const userAccessSelect = {
  ADMIN_WEB,
  POS,
  PAY,
  CLOSE_CASH_SESSION,
  SUBSCRIPTION_PLAN,
  PAY_DEPOSITS,
};

export function getSelectedFinalPermissions(permissions) {
  const finalPermissions = [];
  forEach(permissions, (isChecked, slug) => {
    if (isChecked) {
      finalPermissions.push(slug);
    }
  });

  return finalPermissions;
}
