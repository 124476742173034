import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import DisplayMessage from 'components/DisplayMessageV2';

import RowBrand from './RowBrand';

function MenusForBrand({
  brands,
  isLoaded,
  isLoading,
  confirmPublishMenu,
  resetOptions,
  onResetOptions,
  brandPublished,
}) {
  const { t } = useTranslation('menuMaker');
  const branPublishedRef = useRef(null);

  useEffect(() => {
    if (!isLoading && isLoaded && brandPublished) {
      branPublishedRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, [branPublishedRef.current, brandPublished, isLoading, isLoaded]);

  const menusNotDraf = (() => {
    const menusBrands = [];
    brands?.forEach((brand) => {
      if (brand?.menus?.length > 0) {
        menusBrands.push(brand?.menus?.length);
      }
    });
    return menusBrands.length === 0;
  })();

  function handleConfirmMenuPublish(brand, provider) {
    confirmPublishMenu(brand, provider);
  }

  function isRowOpenDefault(brandUuid, index) {
    if (brandPublished) {
      return brandPublished === brandUuid;
    }

    return index === 0;
  }

  if (!isLoading && isLoaded && menusNotDraf) {
    return (
      <DisplayMessage
        data-testid="publishMenusNoData"
        id="publishMenusNoData"
        maxWidth={400}
        message={t('menuMaker:messages.noMenus')}
        sizeIcon={120}
      />
    );
  }

  return (
    <>
      {brands?.map((brand, index) => (
        <RowBrand
          key={brand?.uuid}
          brand={brand}
          onConfirmPublish={handleConfirmMenuPublish}
          onResetOptions={onResetOptions}
          openDefault={isRowOpenDefault(brand?.uuid, index)}
          refScroll={brandPublished === brand?.uuid ? branPublishedRef : null}
          resetOptions={resetOptions}
        />
      ))}
    </>
  );
}

MenusForBrand.propTypes = {
  brands: PropTypes.array,
  isLoaded: PropTypes.bool,
  isLoading: PropTypes.bool,
  confirmPublishMenu: PropTypes.func,
  resetOptions: PropTypes.bool,
  onResetOptions: PropTypes.func,
  brandPublished: PropTypes.string,
};

export default MenusForBrand;
