/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { stringify } from 'query-string';

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import IconButton from '@design-system/IconButton';
import Table from '@experimental-components/Table';
import IconMoreVertical from '@experimental-components/IconsComponents/MoreVerticalV2';
import PlaceholdersTable from 'components/PlaceholdersTable';
import DisplayMessage from 'components/DisplayMessageV2';
import ConfirmDialog from 'components/ConfirmDialog';
import ActionsMenuMaker from 'components/ActionsMenuMaker';

import { IS_ENABLE } from 'utils/kitchenAreas/isEnable';
import { drawerActions, drawerEntities } from 'utils/drawerMenuMaker';

import useStyles from './styles';

export function CategoriesTable({
  categories,
  categoriesPagination,
  deleteError,
  deleteFetched,
  deleteLoading,
  isSearchFilter,
  loading,
  onDeleteCategory,
  onDuplicateCategory,
  onPaginate,
  openDrawer,
  paginationCategoryState,
  t,
}) {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState({});
  const [anchorElActions, setAnchorElActions] = useState(null);
  const [anchorElDialog, setAnchorElDialog] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const history = useHistory();
  const scrollPaginationRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];

        if (isIntersecting && categoriesPagination?.next) {
          if (!paginationCategoryState.fetching) {
            onPaginate();
          }
        }
      },
      { threshold: 1 },
    );

    if (scrollPaginationRef.current) observer.observe(scrollPaginationRef.current);

    return () => {
      observer.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollPaginationRef, categoriesPagination?.next]);

  useEffect(() => {
    if (deleteFetched || deleteError) {
      setAnchorElDialog(null);
      setSelectedItem(null);
      setOpenDialog(false);
    }
  }, [deleteFetched, deleteError]);

  const handleSelectedItem = (category) => (event) => {
    setAnchorElActions(event.currentTarget);
    setSelectedItem(category);
  };

  function handleSetAnchorElClose() {
    setAnchorElActions(null);
    setSelectedItem(null);
  }

  async function handleDeleteCategory() {
    const canRemove = selectedItem.productCounter === 0;
    if (!canRemove) handleCancelDeleteCategory();
    await onDeleteCategory(selectedItem.uuid, canRemove);
  }

  const handleConfirmDeleteCategory = () => {
    setAnchorElDialog(anchorElActions);
    setAnchorElActions(null);
    setOpenDialog(true);
  };

  function handleCancelDeleteCategory() {
    setAnchorElActions(null);
    setAnchorElDialog(null);
    setOpenDialog(false);
    setSelectedItem(null);
  }

  function handleDuplicateCategory() {
    setAnchorElActions(null);
    onDuplicateCategory(selectedItem.uuid);
    setSelectedItem(null);
  }

  const handleEditCategory = (category) => {
    const query = { action: drawerActions.EDIT, entity: drawerEntities.CATEGORY, uuid: category?.uuid };

    history.replace({ search: stringify(query) });
  };

  function renderBody() {
    if (loading) {
      return <PlaceholdersTable placeholderCols={5} />;
    }

    return categories.map((category, index) => (
      <Table.Row key={category.uuid}>
        <Table.Cell align="left" component="th" isBorder={index !== 0} scope="row" width={270}>
          <Link
            className={classes.linkCell}
            color="textPrimary"
            component="button"
            data-testid={category.uuid}
            onClick={() => handleEditCategory(category)}
            underline="none"
            variant="body2"
          >
            {category.name}
          </Link>
        </Table.Cell>

        <Table.BigCell align="left" component="td" isBorder={index !== 0} scope="row">
          {category?.menus.length > 0 ? (
            <Table.BigCell.ExpandableList
              list={category?.menus?.map((menu) => (
                <Table.BigCell.Option key={menu?.uuid}>{menu?.name}</Table.BigCell.Option>
              ))}
            />
          ) : (
            t('common:notAvailableDefault')
          )}
        </Table.BigCell>

        <Table.Cell align="left" isBorder={index !== 0}>
          {category.productCounter}
        </Table.Cell>

        {IS_ENABLE && !openDrawer && (
          <Table.BigCell align="left" component="td" isBorder={index !== 0} scope="row">
            {category?.kitchenAreasAssignation?.length > 0 ? (
              <Table.BigCell.ExpandableList
                list={category?.kitchenAreasAssignation?.map((kitchen) => (
                  <Table.BigCell.Option key={kitchen?.uuid}>{kitchen?.name}</Table.BigCell.Option>
                ))}
              />
            ) : (
              t('common:notAvailableDefault')
            )}
          </Table.BigCell>
        )}

        <Table.Cell align="right" isBorder={index !== 0}>
          <IconButton aria-label="actions" onClick={handleSelectedItem(category)}>
            <IconMoreVertical />
          </IconButton>
        </Table.Cell>
      </Table.Row>
    ));
  }

  if (!categories?.length && isSearchFilter) {
    return (
      <DisplayMessage
        data-testid="categoriesSearchNoData"
        id="categoriesSearchNoData"
        maxWidth={450}
        message={t('common:messages.searchMessageNoResults')}
        sizeIcon={120}
        title={t('common:messages.searchTitleNoResults')}
      />
    );
  }

  if (!categories?.length && !loading && !isSearchFilter) {
    return (
      <DisplayMessage
        data-testid="categoriesNoData"
        id="categoriesNoData"
        message={t('menuMaker:messages.noCategories')}
        sizeIcon={120}
      />
    );
  }

  return (
    <>
      <Table aria-label="categoriesTable">
        <Table.Head>
          <Table.Row>
            <Table.HeadCell align="left">{t('categories:headersTable.name')}</Table.HeadCell>
            <Table.HeadCell align="left">{t('categories:headersTable.menus')}</Table.HeadCell>
            <Table.HeadCell align="left">{t('categories:headersTable.articles')}</Table.HeadCell>
            {IS_ENABLE && !openDrawer && (
              <Table.HeadCell align="left">{t('categories:headersTable.kitchenAreas')}</Table.HeadCell>
            )}
            <Table.HeadCell align="right" />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {renderBody()}
          {paginationCategoryState.fetching && <PlaceholdersTable placeholderCols={openDrawer ? 4 : 5} />}
        </Table.Body>
      </Table>

      <Box ref={scrollPaginationRef} mb={6} name="actionTableScroll" />

      <ActionsMenuMaker
        anchorEl={anchorElActions}
        onClose={handleSetAnchorElClose}
        onDelete={handleConfirmDeleteCategory}
        onDuplicate={handleDuplicateCategory}
        open={Boolean(anchorElActions)}
      />

      <ConfirmDialog
        anchorEl={anchorElDialog}
        data-testid="popperCategoryDelete"
        id="popperCategoryDelete"
        isDisabled={deleteLoading}
        labelAccept={t('common:buttons.delete')}
        labelCancel={t('common:buttons.cancel')}
        loading={deleteLoading}
        onAccept={handleDeleteCategory}
        onCancel={handleCancelDeleteCategory}
        open={openDialog}
        title={t('categories:popper.onDelete.title')}
      />
    </>
  );
}

CategoriesTable.propTypes = {
  categories: PropTypes.array,
  categoriesPagination: PropTypes.object,
  deleteError: PropTypes.bool,
  deleteFetched: PropTypes.bool,
  deleteLoading: PropTypes.bool,
  isSearchFilter: PropTypes.bool,
  loading: PropTypes.bool,
  onDeleteCategory: PropTypes.func,
  onDuplicateCategory: PropTypes.func,
  onPaginate: PropTypes.func,
  openDrawer: PropTypes.bool,
  paginationCategoryState: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation('categories')(CategoriesTable);
