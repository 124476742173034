/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef } from 'react';
import { InfiniteData } from 'react-query';
import Box from '@material-ui/core/Box';

import Divider from '@design-system/Divider';
import Tooltip from '@design-system/Tooltip';
import NestedTable from '@experimental-components/NestedTable';

import { useFilters, useGetModifiersByProduct, useReportFilters } from 'containers/ReportModifiers/hooks';

import DisplayMessage from 'components/DisplayMessageV2';
import PageError from 'components/PageError';
import PlaceholderTable from 'components/ReportModifiers/PlaceholderTable';

import ModifierProductRow from './ModifierProductRow';
import { ModifiersByProductResponse } from './types';
import useStyle from './styles';

interface Props {
  t: (value: string) => string;
}

interface PropsTable {
  data?: InfiniteData<ModifiersByProductResponse>;
  dataStatus: {
    fetched: boolean;
    fetching: boolean;
    error: boolean;
    isFetchingNextPage: boolean;
    hasNextPage: boolean | undefined;
  };
  loadModifiers: () => void;
  paginate: () => void;
  t: (value: string) => string;
}

const ModifiersByProduct = ({ t }: Props) => {
  const { filters } = useFilters();
  const { data, dataStatus, loadModifiers, paginate, removeModifiers } = useGetModifiersByProduct();
  const reportFilters = useReportFilters();

  useEffect(() => {
    if (reportFilters.data) {
      loadModifiers();
    }
  }, [filters]);

  useEffect(() => {
    return () => {
      removeModifiers();
    };
  }, []);

  return (
    <Table
      data={data}
      dataStatus={{ ...dataStatus, fetching: dataStatus.fetching || reportFilters.isFetching }}
      loadModifiers={loadModifiers}
      paginate={paginate}
      t={t}
    />
  );
};

const Table = ({ data, dataStatus, paginate, loadModifiers, t }: PropsTable) => {
  const classes = useStyle();
  const scrollPaginationRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];

        if (isIntersecting && dataStatus.hasNextPage) {
          if (!dataStatus.isFetchingNextPage) {
            paginate();
          }
        }
      },
      { threshold: 1 },
    );

    if (scrollPaginationRef.current) observer.observe(scrollPaginationRef.current);

    return () => {
      observer.disconnect();
    };
  }, [scrollPaginationRef, dataStatus.hasNextPage]);

  if (dataStatus.error) {
    return (
      <Box mt={6}>
        <PageError
          id="modifiersByProductError"
          labelAction={t('common:buttons.retry')}
          message={t('reports:messages.reportError')}
          onAction={loadModifiers}
          title=""
        />
      </Box>
    );
  }

  if (dataStatus.fetched && !data?.pages[0].data.length && !dataStatus.fetching) {
    return (
      <Box mt={6}>
        <DisplayMessage
          height="auto"
          id="modifiersByProductNoData"
          message={t('reports:reportsEmptyMessage')}
          sizeIcon={120}
        />
      </Box>
    );
  }

  const renderBody = () => {
    if (dataStatus.fetching && !dataStatus.isFetchingNextPage) return <PlaceholderTable.Product />;

    return (
      <>
        {data?.pages.map((group, i) => (
          <React.Fragment key={i}>
            {group.data.map((item) => (
              <ModifierProductRow key={item?.sku} row={item} t={t} />
            ))}
          </React.Fragment>
        ))}
        <Divider />
      </>
    );
  };

  return (
    <Box mx={-4}>
      <NestedTable>
        <NestedTable.Head>
          <NestedTable.HeadCell align="left">{t('modifiersReport.tableByProduct.name')}</NestedTable.HeadCell>

          <NestedTable.HeadCell>
            {t('modifiersReport.tableByProduct.sku')}
            <Tooltip
              classes={{ tooltip: classes.tooltipMaxWidth }}
              sizeIcon={24}
              title={t('modifiersReport.tooltips.byProducts.sku')}
            >
              <Tooltip.Help size={24} />
            </Tooltip>
          </NestedTable.HeadCell>

          <NestedTable.HeadCell>{t('modifiersReport.tableByProduct.quantity')}</NestedTable.HeadCell>

          <NestedTable.HeadCell>
            {t('modifiersReport.tableByProduct.averagePrice')}
            <Tooltip sizeIcon={24} title={t('modifiersReport.tooltips.byProducts.averagePrice')}>
              <Tooltip.Help size={24} />
            </Tooltip>
          </NestedTable.HeadCell>

          <NestedTable.HeadCell>
            {t('modifiersReport.tableByProduct.totalSale')}
            <Tooltip sizeIcon={24} title={t('modifiersReport.tooltips.byProducts.totalSale')}>
              <Tooltip.Help size={24} />
            </Tooltip>
          </NestedTable.HeadCell>
        </NestedTable.Head>

        <NestedTable.Body>
          {renderBody()}

          {dataStatus.isFetchingNextPage && <PlaceholderTable.Product />}
        </NestedTable.Body>
      </NestedTable>

      <Box ref={scrollPaginationRef} id="actionTableScroll" />
    </Box>
  );
};

export default ModifiersByProduct;
