/* eslint-disable no-shadow */
import Box, { BoxProps } from '@material-ui/core/Box';

import IconEmptyReports from '@experimental-components/IconsComponents/Empty';
import IconEmptyBrands from '@experimental-components/IconsComponents/EmptyBrands';
import IconEmptyDefault from '@experimental-components/IconsComponents/EmptyDefault';
import { ReactNode } from 'react';

const typeIconComponents = {
  empty: IconEmptyDefault,
  emptyBrands: IconEmptyBrands,
  emptyReports: IconEmptyReports,
};

export enum SeverityType {
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning',
  searchInfo = 'searchInfo',
  empty = 'empty',
  emptyBrands = 'emptyBrands',
  emptyReports = 'emptyReports',
}

interface Props extends BoxProps {
  severity?: SeverityType;
  message?: string | ReactNode;
  maxWidth?: number;
  sizeIcon?: number;
  title?: string;
  sizeTitle?: number;
  sizeMessage?: number;
  height?: number | string;
}

function DisplayMessage({
  severity = SeverityType.empty,
  message,
  maxWidth = 200,
  sizeIcon = 57,
  title,
  sizeTitle = 24,
  sizeMessage = 18,
  height = '100vh',
  ...others
}: Props) {
  const TypeIconComponent = typeIconComponents[severity] ?? null;

  return (
    <Box
      alignItems="center"
      data-cy="displayMessage"
      data-testid="displayMessageV2TestId"
      display="flex"
      flexDirection="column"
      height={height}
      justifyContent="center"
      maxHeight={500}
      {...others}
    >
      <TypeIconComponent size={sizeIcon} />

      <Box display="flex" flexDirection="column" justifyContent="center" mt={title ? 5 : 3} textAlign="center">
        {title && (
          <Box mb={1}>
            <Box fontSize={sizeTitle} fontWeight="fontWeightMedium">
              {title}
            </Box>
          </Box>
        )}

        {message && (
          <Box maxWidth={maxWidth} width="100%">
            <Box fontSize={sizeMessage} textAlign="center">
              {message}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default DisplayMessage;
