import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import TableCellMui, { TableCellProps } from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import useIconParrot from 'hooks/useIconParrot';

interface Props extends TableCellProps {
  active?: boolean;
  disabled?: boolean;
  isSorting?: boolean;
  direction?: 'asc' | 'desc';
  children: React.ReactNode;
}

const TableCell = withStyles((theme) => ({
  root: {
    padding: 32,
    fontWeight: 500,
    fontSize: '1rem',
    color: theme.palette.darkGrey.main,
    borderBottom: 0,
  },
}))(TableCellMui);

function TableHeadCell({
  align = 'center',
  active = false,
  disabled,
  isSorting,
  direction,
  children,
  ...others
}: Props) {
  const { IconArrowDownTable } = useIconParrot({ icon: 'arrowDownTable' });

  return (
    <TableCell align={align} {...others}>
      {isSorting && (
        <TableSortLabel active={active} direction={direction} disabled={disabled} IconComponent={IconArrowDownTable}>
          {children}
        </TableSortLabel>
      )}

      {!isSorting && children}
    </TableCell>
  );
}

export default TableHeadCell;
