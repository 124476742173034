import { api, headers } from 'config';

import { Auth, AuthPayload } from './types';

export async function authSignIn(payload: AuthPayload) {
  const request = await api.post<Auth>('/v1/auth/login/', payload, {
    headers: { ...headers, 'X-Product-Auth': 'CONNECT' },
  });
  const response = request.data;

  return response;
}

export async function authRefreshToken(payload: { refresh?: string }) {
  if (!payload.refresh) return null;
  const request = await api.post<{ access: string; refresh: string }>('/v1/auth/token/refresh', payload);
  const response = request.data;

  return response;
}
