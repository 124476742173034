import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectSignInProviderDomain = (state) => state.signInProvider || initialState;

const selectIsAuthenticated = createSelector(selectSignInProviderDomain, (authState) => authState.authenticated);

const selectProfile = createSelector(selectSignInProviderDomain, (authState) => authState.profile);

const selectSignInFetching = createSelector(selectSignInProviderDomain, (authState) => authState.fetching);

const selectSignInError = createSelector(selectSignInProviderDomain, (authState) => authState.error);

export { selectSignInProviderDomain, selectIsAuthenticated, selectSignInFetching, selectSignInError, selectProfile };
