import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import useTheme from '@material-ui/core/styles/useTheme';

import Title from './Title';
import Row from './Row';
import Item from './Item';

function LinksContainers({ css, children, ...others }) {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        '& > :not(:first-child)': {
          marginTop: theme.spacing(1.5),
        },
        ...css,
      }}
      {...others}
    >
      {children}
    </Box>
  );
}

LinksContainers.Title = Title;
LinksContainers.Row = Row;
LinksContainers.Item = Item;

LinksContainers.propTypes = {
  children: PropTypes.node,
  css: PropTypes.object,
};

export default LinksContainers;
