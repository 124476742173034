import { stringify } from 'querystring';

import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useSidebarLayout } from 'providers/root/SidebarLayoutProvider';

import Box from '@material-ui/core/Box';

import TableMenus from 'components/MenuMakerResumeTable';
import DisplayMessage from 'components/DisplayMessageV2';
import PageError from 'components/PageError';
import HeaderForm from 'components/HeaderForm';

import { drawerActions, drawerEntities } from 'utils/drawerMenuMaker';

import HelperSkeleton from './HelperSkeleton';

import useStyles from './styles';

function ResumeContent({
  isSearchFilter,
  loading,
  menusError,
  menus,
  onPaginate,
  openDrawer,
  menusPagination,
  paginationState,
  onSearchMenus,
}) {
  const history = useHistory();
  const { modeDraft } = useSidebarLayout();
  const classes = useStyles({ openDrawer, isModeDraft: modeDraft });
  const { t } = useTranslation('menuMaker');
  const scrollPaginationRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];
        if (isIntersecting && menusPagination?.next) {
          if (!paginationState.fetching) {
            onPaginate();
          }
        }
      },
      { threshold: 0 },
    );

    if (scrollPaginationRef.current) observer.observe(scrollPaginationRef.current);

    return () => {
      observer.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollPaginationRef, menusPagination?.next]);

  const handleOpenNewMenu = () => {
    const query = { action: drawerActions.CREATE, entity: drawerEntities.MENU };

    history.replace({ search: stringify(query) });
  };

  function renderContent() {
    if (loading) {
      return <HelperSkeleton />;
    }

    if (menusError) {
      return (
        <Box className={classes.containerMessageError}>
          <PageError
            data-testid="resumeMenusLoadError"
            id="resumeMenusLoadError"
            labelAction={t('common:buttons.retry')}
            maxWidth={300}
            message={t('common:errors.loaded.errorConnection')}
            onAction={onSearchMenus}
            title={t('common:errors.sorry')}
          />
        </Box>
      );
    }

    if (!menus?.length && !loading && isSearchFilter) {
      return (
        <DisplayMessage
          data-testid="menusSearchNoData"
          id="menusSearchNoData"
          maxWidth={450}
          message={t('common:messages.searchMessageNoResults')}
          sizeIcon={120}
          title={t('common:messages.searchTitleNoResults')}
        />
      );
    }

    if (!loading && menus?.length === 0 && !isSearchFilter) {
      return (
        <DisplayMessage
          data-testid="resumeMenusNoData"
          id="resumeMenusNoData"
          maxWidth={400}
          message={t('menuMaker:messages.noMenus')}
          sizeIcon={120}
        />
      );
    }

    return (
      <TableMenus
        loadingPagination={paginationState.fetching}
        menus={menus}
        openDrawer={openDrawer}
        scrollPaginationRef={scrollPaginationRef}
      />
    );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.contentResume}>
        <HeaderForm
          addButtonLabel={t('menuMaker:buttons.newMenu')}
          isShowFilterBrands
          onAddButtonClick={handleOpenNewMenu}
          onSearchBoxChange={onSearchMenus}
          openDrawer={openDrawer}
          px={4}
          searchLabel={t('menuMaker:headerForm.searchMenu')}
        />

        {renderContent()}
      </Box>
    </Box>
  );
}

ResumeContent.propTypes = {
  isSearchFilter: PropTypes.bool,
  loading: PropTypes.bool,
  menusError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  menus: PropTypes.array,
  onSearchMenus: PropTypes.func,
  onPaginate: PropTypes.func,
  openDrawer: PropTypes.bool,
  menusPagination: PropTypes.object,
  paginationState: PropTypes.object,
};

export default ResumeContent;
