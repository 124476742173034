import { styled, makeStyles } from '@material-ui/core/styles';

export const NavigationStyled = styled('section')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  background: theme.palette.darkGrey[67],
  paddingBlock: '1rem',
  paddingRight: '2rem',
  paddingLeft: '1.25rem',
  marginInline: '-2rem',
  alignItems: 'center',
}));

const useStyles = makeStyles((theme) => ({
  tab: {
    maxWidth: theme.spacing(34),
  },
}));

export default useStyles;
