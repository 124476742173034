import { useContext } from 'react';
import { useQuery, useInfiniteQuery, useMutation } from 'react-query';

import { getReportsFilters } from 'utils/api/baas/reportsFiltersV2';
import {
  getModifiersByProduct,
  getModifiersOptions,
  getModifiersByProductDetail,
  getModifiersOptionsDetail,
  downloadReportModifiers,
} from 'utils/api/baas/reports/modifiers';
import { getPageFromUrl } from 'utils/getPageFromUrl';

import { FiltersContext } from './context';

export function useFilters() {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error('useFilters must be used within an FiltersProvider');
  }

  return context;
}

export function useReportFilters() {
  return useQuery('reportFilters', getReportsFilters, {
    enabled: false,
  });
}

export function useGetModifiersByProduct() {
  const { filters } = useFilters();

  const {
    data,
    isFetched,
    isFetching,
    isError,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
    remove,
  } = useInfiniteQuery(
    'modifiersByProduct',
    ({ pageParam = 1 }) =>
      getModifiersByProduct({
        startDate: filters.startDate,
        endDate: filters.endDate,
        brands: filters.brands,
        providers: filters.providers,
        page: pageParam,
      }),
    {
      getNextPageParam: (lastPage, pages) => (lastPage.pagination.next ? pages.length + 1 : undefined),
      enabled: false,
      retry: 1,
    },
  );

  return {
    data,
    loadModifiers: refetch,
    paginate: fetchNextPage,
    dataStatus: {
      fetched: isFetched,
      fetching: isFetching,
      error: isError,
      isFetchingNextPage,
      hasNextPage,
    },
    removeModifiers: remove,
  };
}

export function useGetModifiersByProductDetail(skuItem: string) {
  const { filters } = useFilters();

  const { data, isFetching, isFetched, isError, refetch, remove } = useQuery(
    ['modifiersByProductDetail', skuItem],
    () =>
      getModifiersByProductDetail({
        skuItem,
        startDate: filters.startDate,
        endDate: filters.endDate,
        brands: filters.brands,
        providers: filters.providers,
      }),
    { enabled: false, retry: 1 },
  );

  return {
    data,
    loadModifiersDetail: refetch,
    dataStatus: {
      fetched: isFetched,
      fetching: isFetching,
      error: isError,
    },
    removeModifiersDetail: remove,
  };
}

export function useGetModifiersOptionInfinity() {
  const { filters } = useFilters();

  return useInfiniteQuery(
    'modifiersOptionsInfinity',
    ({ pageParam = 1 }) =>
      getModifiersOptions({
        startDate: filters.startDate,
        endDate: filters.endDate,
        page: pageParam,
        pageSize: 5,
        brands: filters.brands,
        providers: filters.providers,
      }),
    {
      getNextPageParam: (lastPage) => getPageFromUrl(lastPage.pagination.next),
      enabled: false,
    },
  );
}

export function useGetModifiersOptionDetail(skuItem: string) {
  const { filters } = useFilters();

  const { data, isFetching, isFetched, isError, refetch, remove } = useQuery(
    ['modifiersOptionsDetails', skuItem],
    () =>
      getModifiersOptionsDetail({
        skuItem,
        startDate: filters.startDate,
        endDate: filters.endDate,
        brands: filters.brands,
        providers: filters.providers,
      }),
    { enabled: false },
  );

  return {
    data: data?.data,
    loadModifiersOptionDetail: refetch,
    dataStatus: {
      fetched: isFetched,
      fetching: isFetching,
      error: isError,
    },
    removeModifiersOptionDetail: remove,
  };
}

export function useDownloadReportModifiers() {
  const { filters } = useFilters();

  return useMutation((reportType?: string) =>
    downloadReportModifiers({
      startDate: filters.startDate,
      endDate: filters.endDate,
      report: reportType ?? 'PRODUCT_MODIFIER_SALES',
      brands: filters.brands,
      providers: filters.providers,
    }),
  );
}
