import PropTypes from 'prop-types';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import clsx from 'clsx';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { List, ListItem, ListItemText } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import InputTextFiled from '@design-system/TextField';
import IconButton from '@design-system/IconButton';
import DragHandleVertical from '@experimental-components/IconsComponents/DragHandleHorizontal';
import IconClose from '@experimental-components/IconsComponents/Close';
import CopyOutline from '@experimental-components/IconsComponents/CopyOutline';

import arrayMove from '../../utils/array';

import Tooltip from './Tooltip';
import useStyles from './styles';

function MultiSelect({
  getOptionLabel,
  getOptionDescription,
  getOptionUniqueKey,
  options,
  selectedOptions,
  inputPlaceholderText,
  titleLabel,
  onChange,
  headerClassName,
  noOptionsText,
  renderOption,
  id,
  isSorteable = true,
  fullWidth = true,
  hasItemOverride = false,
  useItemsOverride = false,
  messageItemsOverride,
  baseItemsOverride,
}) {
  const classes = useStyles({ isSorteable, fullWidth, hasItemOverride });

  function handleRemoveItem(value) {
    onChange(selectedOptions.filter((option) => option !== value));
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    onChange(arrayMove(selectedOptions, oldIndex, newIndex));
  };

  // This ensures we don't show options which are already selected.
  const finalOptions = options.filter((option) => !selectedOptions.includes(option));

  const DraggableHandle = SortableHandle(({ children }) => children);

  const SortableItem = SortableElement(({ value }) => {
    return (
      <Box className={classes.contentItem}>
        <ListItem
          className={clsx(classes.listItem, {
            [classes.listItemError]: value?.isErrorItem,
          })}
        >
          <Box className={classes.itemProduct}>
            {isSorteable && (
              <DraggableHandle>
                <DragHandleVertical color={value?.isErrorItem ? '#F04E4A' : '#000'} />
              </DraggableHandle>
            )}

            <ListItemText
              primary={getOptionLabel(value)}
              secondary={getOptionDescription ? getOptionDescription(value) : null}
              secondaryTypographyProps={{
                className: clsx({
                  [classes.listItemError]: value?.isErrorItem,
                }),
              }}
            />

            <IconButton
              aria-label="delete"
              className={classes.disableHover}
              data-testid={`btnRemoveItem_${getOptionUniqueKey ? getOptionUniqueKey(value) : value}`}
              edge="end"
              onClick={() => handleRemoveItem(value)}
            >
              <IconClose color={value?.isErrorItem ? '#F04E4A' : '#000'} size={11} />
            </IconButton>
          </Box>
        </ListItem>
        {hasItemOverride && (
          <Box height={33} width={33}>
            {value?.hasOverride ? (
              <Tooltip title={messageItemsOverride}>
                <CopyOutline color={value?.isErrorItem ? '#F04E4A' : '#000'} />
              </Tooltip>
            ) : null}
          </Box>
        )}
      </Box>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    if (!items.length) {
      return <Box />;
    }
    return (
      <Box>
        <List className={classes.listContainer}>
          {items.map((value, index) => (
            <SortableItem
              key={getOptionUniqueKey ? getOptionUniqueKey(value) : value}
              distance={1}
              index={index}
              value={value}
            />
          ))}
        </List>
      </Box>
    );
  });

  return (
    <div className={classes.root}>
      {titleLabel && (
        <Typography className={headerClassName} gutterBottom variant="h6">
          {titleLabel}
        </Typography>
      )}
      <Autocomplete
        data-testid={id}
        getOptionLabel={getOptionLabel}
        id={id}
        multiple
        noOptionsText={noOptionsText}
        onChange={(event, newValue) => {
          const newItem = useItemsOverride
            ? { ...newValue[0], hasOverride: !baseItemsOverride.includes(newValue[0]?.uuid) }
            : newValue;

          onChange(selectedOptions.concat(newItem));
        }}
        options={finalOptions}
        renderInput={(params) => (
          <InputTextFiled
            ref={params.InputProps.ref}
            fullWidth
            hideLabel
            hideLabelHelperText
            inputProps={params.inputProps}
            placeholder={inputPlaceholderText}
          />
        )}
        renderOption={renderOption}
        value={[]}
      />
      <SortableList items={selectedOptions} lockAxis="y" onSortEnd={onSortEnd} useDragHandle />
    </div>
  );
}

MultiSelect.propTypes = {
  titleLabel: PropTypes.string,
  inputPlaceholderText: PropTypes.string,
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  getOptionLabel: PropTypes.func,
  getOptionUniqueKey: PropTypes.func,
  onChange: PropTypes.func,
  headerClassName: PropTypes.string,
  noOptionsText: PropTypes.string,
  renderOption: PropTypes.func,
  getOptionDescription: PropTypes.func,
  id: PropTypes.string,
  isSorteable: PropTypes.bool,
  fullWidth: PropTypes.bool,
  messageItemsOverride: PropTypes.string,
  baseItemsOverride: PropTypes.array,
};

export default MultiSelect;
