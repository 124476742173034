export const CalendarIcon = () => (
  <svg
    className="react-daterange-picker-v2__calendar-button__icon react-daterange-picker-v2__button__icon"
    fill="none"
    height={24}
    viewBox="0 0 24 25"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M6.45455 3.72192C6.45455 3.44578 6.23069 3.22192 5.95455 3.22192C5.6784 3.22192 5.45455 3.44578 5.45455 3.72192V5.67647H3.5C3.22386 5.67647 3 5.90033 3 6.17647V9.22192V10.2219V20.9037C3 21.1799 3.22386 21.4037 3.5 21.4037H19.8636C20.1398 21.4037 20.3636 21.1799 20.3636 20.9037V6.17647C20.3636 5.90033 20.1398 5.67647 19.8636 5.67647H17.0909V3.72192C17.0909 3.44578 16.8671 3.22192 16.5909 3.22192C16.3148 3.22192 16.0909 3.44578 16.0909 3.72192V5.67647H6.45455V3.72192ZM4 20.4037V10.2219H19.3636V20.4037H4ZM19.3636 6.67647V9.22192H4V6.67647H19.3636Z"
      fill="#000000"
      fillRule="evenodd"
    />
  </svg>
);
