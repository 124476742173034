import React from 'react';
import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';

const HeadRoot = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridAutoFlow: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  boxShadow: '0px 8px 40px rgba(164, 170, 186, 0.15)',
  paddingInline: '16px',
}));

interface Props {
  children: React.ReactNode;
  columnStructure?: string;
  height?: number;
}

const Head = ({ children, columnStructure = '2fr repeat(4, 1fr)', height = 80, ...others }: Props) => {
  return (
    <HeadRoot aria-label="Head" gridTemplateColumns={columnStructure} height={height} {...others}>
      {children}
    </HeadRoot>
  );
};

export default Head;
