import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonSection: {
    position: 'relative',
    height: '100%',
    borderLeft: `1px solid ${theme.palette.darkGrey[15]}`,
  },
  messageModeDraft: {
    background: theme.palette.error.light,
    display: 'flex',
    height: theme.typography.pxToRem(56),
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1rem',
    paddingInline: '2rem',
  },
}));

export default useStyles;
