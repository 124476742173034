import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);

const TODAY = 'today';
const TODAY_VALUE = [dayjs(), dayjs()];

const TODAY_START_AND_END = 'today';
const TODAY_START_AND_END_VALUE = [dayjs().startOf('day'), dayjs().endOf('day')];

const YESTERDAY = 'yesterDay';
const YESTERDAY_VALUE = [dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')];

const SINCE_YESTERDAY = 'since yesterday';
const SINCE_YESTERDAY_VALUE = [dayjs().subtract(1, 'days'), dayjs()];

const LAST_WEEK = 'lastWeek';
const LAST_WEEK_VALUE = [dayjs().weekday(0), dayjs()];
const FULL_LAST_WEEK_VALUE = [dayjs().weekday(0), dayjs().weekday(6)];

const PAST_WEEK = 'pastWeek';
const PAST_WEEK_VALUE = [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')];

const MONTH = 'month';
const MONTH_VALUE = [dayjs().startOf('month'), dayjs()];
const FULL_MONTH_VALUE = [dayjs().startOf('month'), dayjs().endOf('month')];

const LAST_MONTH = 'lastMonth';
const LAST_MONTH_VALUE = [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')];

const YEAR = 'year';
const YEAR_VALUE = [dayjs().startOf('year'), dayjs()];
const FULL_YEAR_VALUE = [dayjs().startOf('year'), dayjs().endOf('year')];

export const rangesTypes = {
  TODAY,
  TODAY_START_AND_END,
  YESTERDAY,
  LAST_WEEK,
  PAST_WEEK,
  MONTH,
  LAST_MONTH,
  YEAR,
  SINCE_YESTERDAY,
};

export const rangesTypesValues = {
  [rangesTypes.TODAY]: TODAY_VALUE,
  [rangesTypes.TODAY_START_AND_END]: TODAY_START_AND_END_VALUE,
  [rangesTypes.SINCE_YESTERDAY]: SINCE_YESTERDAY_VALUE,
  [rangesTypes.YESTERDAY]: YESTERDAY_VALUE,
  [rangesTypes.LAST_WEEK]: LAST_WEEK_VALUE,
  [rangesTypes.LAST_WEEK]: LAST_WEEK_VALUE,
  [rangesTypes.PAST_WEEK]: PAST_WEEK_VALUE,
  [rangesTypes.MONTH]: MONTH_VALUE,
  [rangesTypes.LAST_MONTH]: LAST_MONTH_VALUE,
  [rangesTypes.YEAR]: YEAR_VALUE,
};

export const rangesLabels = {
  [rangesTypes.TODAY]: `common:forms.rangesDateOptions.${rangesTypes.TODAY}`,
  [rangesTypes.TODAY_START_AND_END]: `common:forms.rangesDateOptions.${rangesTypes.TODAY_START_AND_END}`,
  [rangesTypes.YESTERDAY]: `common:forms.rangesDateOptions.${rangesTypes.YESTERDAY}`,
  [rangesTypes.LAST_WEEK]: `common:forms.rangesDateOptions.${rangesTypes.LAST_WEEK}`,
  [rangesTypes.PAST_WEEK]: `common:forms.rangesDateOptions.${rangesTypes.PAST_WEEK}`,
  [rangesTypes.MONTH]: `common:forms.rangesDateOptions.${rangesTypes.MONTH}`,
  [rangesTypes.LAST_MONTH]: `common:forms.rangesDateOptions.${rangesTypes.LAST_MONTH}`,
  [rangesTypes.YEAR]: `common:forms.rangesDateOptions.${rangesTypes.YEAR}`,
};

const dateOptionsLabels = {
  [rangesTypes.LAST_WEEK]: `common:forms.dateOptionsLabels.${rangesTypes.LAST_WEEK}`,
  [rangesTypes.MONTH]: `common:forms.dateOptionsLabels.${rangesTypes.MONTH}`,
  [rangesTypes.YEAR]: `common:forms.dateOptionsLabels.${rangesTypes.YEAR}`,
};

export const getRangesOptions = (fn) => {
  return {
    [fn(rangesLabels[rangesTypes.TODAY])]: rangesTypesValues[rangesTypes.TODAY],
    [fn(rangesLabels[rangesTypes.TODAY_START_AND_END])]: rangesTypesValues[rangesTypes.TODAY_START_AND_END],
    [fn(rangesLabels[rangesTypes.YESTERDAY])]: rangesTypesValues[rangesTypes.YESTERDAY],
    [fn(rangesLabels[rangesTypes.LAST_WEEK])]: rangesTypesValues[rangesTypes.LAST_WEEK],
    [fn(rangesLabels[rangesTypes.PAST_WEEK])]: rangesTypesValues[rangesTypes.PAST_WEEK],
    [fn(rangesLabels[rangesTypes.MONTH])]: rangesTypesValues[rangesTypes.MONTH],
    [fn(rangesLabels[rangesTypes.LAST_MONTH])]: rangesTypesValues[rangesTypes.LAST_MONTH],
    [fn(rangesLabels[rangesTypes.YEAR])]: rangesTypesValues[rangesTypes.YEAR],
  };
};

export const getDateOptionsToReports = (fn) => {
  return {
    [fn(rangesLabels[rangesTypes.TODAY])]: rangesTypesValues[rangesTypes.TODAY],
    [fn(rangesLabels[rangesTypes.YESTERDAY])]: rangesTypesValues[rangesTypes.YESTERDAY],
    [fn(dateOptionsLabels[rangesTypes.LAST_WEEK])]: FULL_LAST_WEEK_VALUE,
    [fn(dateOptionsLabels[rangesTypes.MONTH])]: FULL_MONTH_VALUE,
    [fn(dateOptionsLabels[rangesTypes.YEAR])]: FULL_YEAR_VALUE,
  };
};

export const getRangeTypeByLabel = (label = '', fn) => {
  if (!label) return '';
  const rangesByLabel = {
    [fn(rangesLabels[rangesTypes.TODAY])]: rangesTypes.TODAY,
    [fn(rangesLabels[rangesTypes.TODAY_START_AND_END])]: rangesTypes.TODAY_START_AND_END,
    [fn(rangesLabels[rangesTypes.YESTERDAY])]: rangesTypes.YESTERDAY,
    [fn(rangesLabels[rangesTypes.LAST_WEEK])]: rangesTypes.LAST_WEEK,
    [fn(rangesLabels[rangesTypes.PAST_WEEK])]: rangesTypes.PAST_WEEK,
    [fn(rangesLabels[rangesTypes.MONTH])]: rangesTypes.MONTH,
    [fn(rangesLabels[rangesTypes.LAST_MONTH])]: rangesTypes.LAST_MONTH,
    [fn(rangesLabels[rangesTypes.YEAR])]: rangesTypes.YEAR,
    [fn(dateOptionsLabels[rangesTypes.LAST_WEEK])]: rangesTypes.LAST_WEEK,
    [fn(dateOptionsLabels[rangesTypes.MONTH])]: rangesTypes.MONTH,
    [fn(dateOptionsLabels[rangesTypes.YEAR])]: rangesTypes.YEAR,
  };
  return rangesByLabel[label] || '';
};

export const getCurrentWeek = () => {
  return [dayjs().startOf('week'), dayjs().endOf('week')];
};
