import { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';

import IconOrderBy from '@experimental-components/IconsComponents/OrderBy';

import { menuProps, optionsOrderBy } from './utils';

const ButtonBase = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(16),
    padding: '1rem',
    border: 'none',
    lineHeight: theme.typography.pxToRem(18),
    height: theme.typography.pxToRem(56),
    minWidth: theme.typography.pxToRem(56),
    backgroundColor: theme.palette.darkGrey[2],
    color: theme.palette.common.black,

    '&:hover': {
      backgroundColor: theme.palette.darkGrey[5],
      boxShadow: 'none',
    },

    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.darkGrey[2],
    },

    '&:focus': {
      border: 'none',
    },

    '&$disabled': {
      color: theme.palette.darkGrey[50],
    },
  },

  disabled: {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.darkGrey[50],
  },

  sizeSmall: {
    padding: 16,
  },
  startIcon: {
    margin: 0,
    paddingRight: (props) => (props.children ? 8 : 0),
  },
}))(Button);

const MenuBase = withStyles((theme) => ({
  paper: {
    boxShadow: '0px 8px 40px rgba(133, 133, 133, 0.2)',
    borderRadius: theme.shape.borderRadius,
  },
}))(Menu);

const MenuItemBase = withStyles((theme) => ({
  root: {
    padding: '12px 16px',
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightRegular,
    '&$focusVisible': {
      backgroundColor: theme.palette.darkGrey[5],
    },
    '&$selected, &$selected:hover': {
      backgroundColor: theme.palette.darkGrey[5],
    },

    '&:hover': {
      backgroundColor: theme.palette.darkGrey[5],
    },
  },
}))(MenuItem);

function SortByProducts({ onSelectedSortBy, ...others }) {
  const { t } = useTranslation('menuMaker');
  const [anchorEl, setAnchorEl] = useState(null);
  const [optionSelected, setOptionSelected] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSelectedItem = (value) => () => {
    onSelectedSortBy(value);
    setOptionSelected(value);
    setAnchorEl(null);
  };

  return (
    <Box>
      <ButtonBase disableRipple onClick={handleOpenMenu} startIcon={<IconOrderBy />} {...others}>
        {t('menuMaker:headerForm.filterProducts.orderBy')}
      </ButtonBase>

      <MenuBase
        anchorEl={anchorEl}
        data-testid="menuOptionsOrderByProducts"
        onClose={handleCloseMenu}
        open={Boolean(anchorEl)}
        {...menuProps}
      >
        {optionsOrderBy.map((option) => (
          <MenuItemBase
            key={option.value}
            data-testid={option.id}
            disableRipple
            onClick={handleSelectedItem(option.value)}
            selected={optionSelected === option.value}
          >
            <ListItemText primary={t(option.label)} />
          </MenuItemBase>
        ))}
      </MenuBase>
    </Box>
  );
}

SortByProducts.propTypes = {
  onSelectedSortBy: PropTypes.func,
};

export default SortByProducts;
